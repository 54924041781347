// node modules
import React, { useContext } from "react";

// styles
import "compiled/css/headerBar.css";
import "compiled/css/eventTypeFilters.css";

// contexts
import { FiltersContext } from "contexts/FiltersContext";

import DropdownMenu from "components/Controls/DropdownMenu";
import Filters from "./Filters";
import ChampionshipFilters from "../championship-filters/ChampionshipFilters";
import { useTranslation } from "react-i18next";

function FiltersDropdown() {
  const {
    state: { isChampionshipView },
  } = useContext(FiltersContext);
  const { t } = useTranslation();

  return (
    <div className="filters-dropdown">
      <DropdownMenu
        theme="dark"
        buttonLabel={t("FILTERS_LABEL")}
        testId="filters_dropdown_menu"
      >
        {isChampionshipView ? <ChampionshipFilters /> : <Filters />}
      </DropdownMenu>
    </div>
  );
}

export default FiltersDropdown;
