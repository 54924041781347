export const eventIds = [
  "d28ded43-2318-4c77-af0f-fc83795ffa0a",
  "ec63d620-682a-4a84-8021-7b7d8a6302ae",
  "98c60c33-72b4-4317-9b25-bebbe62e25db",
  "bd61dd53-1bbf-454a-8f83-f350f4aa088c",
  "8bc0c1ea-4643-4f66-8730-ffd7d122f4d0",
  "b58a26c0-1de6-4af6-95af-f2364fc2a1d1",
  "ce3dcdac-d72a-437f-b0c9-ffd103b61e63",
  "80b813c5-ae12-46bf-8d64-26796275c09b",
  "d28ded43-2318-4c77-af0f-fc83795ffa0b",
];

export const searchableEventsById = {
  "d28ded43-2318-4c77-af0f-fc83795ffa0b": {
    guid: "d28ded43-2318-4c77-af0f-fc83795ffa0b",
    address: {
      name: "Adelaide",
      street_address: "74 Wakefield St",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.928092,138.6034545",
      address: "74 Wakefield St",
      country_code: "AU",
      latitude: -34.928092,
      longitude: 138.6034545,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5000",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "foo@bar.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "tcg_std",
      update_key: "ec0e9c00eef84452ae5f59949a904ef9",
      contact_email: "foo@bar.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "ec0e9c00eef84452ae5f59949a904ef9",
      sanctioned_date: "2022-01-10 23:21:30",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "14:00:00",
      on_site_registration_datetime: "2022-01-21 14:00:00",
      on_site_registration_duration: 1800000,
    },
    name: "testing for events",
    payment_options: "inperson",
    start_datetime: "2022-01-22T14:00:00",
    activity_type: "",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "tcg_std",
    products: ["tcg"],
    status: "sanctioned",
    when: "2022-01-22T14:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000204/",
    local_id: "d28ded43-2318-4c77-af0f-fc83795ffa0a",
    distance: 0.1,
    moment: "2022-01-22T14:00:00.000-08:00",
    end: "2022-01-22T16:00:00.000-08:00",
    id: "d28ded43-2318-4c77-af0f-fc83795ffa0a",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/d28ded43-2318-4c77-af0f-fc83795ffa0a",
    lat: -34.928092,
    lng: 138.6034545,
  },
  "d28ded43-2318-4c77-af0f-fc83795ffa0a": {
    guid: "d28ded43-2318-4c77-af0f-fc83795ffa0a",
    address: {
      name: "Adelaide",
      street_address: "74 Wakefield St",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.928092,138.6034545",
      address: "74 Wakefield St",
      country_code: "AU",
      latitude: -34.928092,
      longitude: 138.6034545,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5000",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "foo@bar.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "tcg_std",
      update_key: "ec0e9c00eef84452ae5f59949a904ef9",
      contact_email: "foo@bar.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "ec0e9c00eef84452ae5f59949a904ef9",
      sanctioned_date: "2022-01-10 23:21:30",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "14:00:00",
      on_site_registration_datetime: "2022-01-21 14:00:00",
      on_site_registration_duration: 1800000,
    },
    name: "testing for events",
    payment_options: "inperson",
    start_datetime: "2022-01-22T14:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "tcg_std",
    products: ["tcg"],
    status: "sanctioned",
    when: "2022-01-22T14:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000204/",
    local_id: "d28ded43-2318-4c77-af0f-fc83795ffa0a",
    distance: 0.1,
    moment: "2022-01-22T14:00:00.000-08:00",
    end: "2022-01-22T16:00:00.000-08:00",
    id: "d28ded43-2318-4c77-af0f-fc83795ffa0a",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/d28ded43-2318-4c77-af0f-fc83795ffa0a",
    lat: -34.928092,
    lng: 138.6034545,
  },
  "ec63d620-682a-4a84-8021-7b7d8a6302ae": {
    guid: "ec63d620-682a-4a84-8021-7b7d8a6302ae",
    address: {
      name: "Adelaide2",
      street_address: "94 Wakefield St",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.9281036,138.6041643",
      address: "94 Wakefield St",
      country_code: "AU",
      latitude: -34.9281036,
      longitude: 138.6041643,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5000",
    },
    contact_information: {
      contact_phone: "5551212",
      contact_email: "foo@bAR.COM",
      website: "",
    },
    metadata: {
      website: "",
      category: "tcg_std",
      update_key: "e614943990264c8fa79165f2ef512093",
      contact_email: "foo@bAR.COM",
      contact_phone: "5551212",
      play_template: "swiss",
      pcom_update_key: "e614943990264c8fa79165f2ef512093",
      sanctioned_date: "2022-01-10 23:13:42",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "14:00:00",
      on_site_registration_datetime: "2022-01-21 14:00:00",
      on_site_registration_duration: 1800000,
    },
    name: "Test event 6",
    payment_options: "inperson",
    start_datetime: "2022-01-22T15:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "tcg_std",
    products: ["tcg"],
    status: "sanctioned",
    when: "2022-01-22T15:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000198/",
    local_id: "ec63d620-682a-4a84-8021-7b7d8a6302ae",
    distance: 0.1,
    moment: "2022-01-22T15:00:00.000-08:00",
    end: "2022-01-22T17:00:00.000-08:00",
    id: "ec63d620-682a-4a84-8021-7b7d8a6302ae",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/ec63d620-682a-4a84-8021-7b7d8a6302ae",
    lat: -34.9281036,
    lng: 138.6041643,
  },
  "98c60c33-72b4-4317-9b25-bebbe62e25db": {
    guid: "98c60c33-72b4-4317-9b25-bebbe62e25db",
    address: {
      name: "address2",
      street_address: "2/152 Melbourne St",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.9075762,138.6061521",
      address: "2/152 Melbourne St",
      country_code: "AU",
      latitude: -34.9075762,
      longitude: 138.6061521,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5006",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "r.mcmillion@pokemon.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "vg_mod",
      update_key: "2842cfd0b9e8461b936eb7d97ed2e14c",
      contact_email: "r.mcmillion@pokemon.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "2842cfd0b9e8461b936eb7d97ed2e14c",
      sanctioned_date: "2022-01-13 15:52:22",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "15:52:18",
      on_site_registration_datetime: "2022-01-19 15:52:18",
    },
    name: "Mnuhipctnuq379",
    payment_options: "inperson",
    start_datetime: "2022-01-25T20:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "vg_mod",
    products: ["vg"],
    status: "sanctioned",
    when: "2022-01-25T20:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000349/",
    local_id: "98c60c33-72b4-4317-9b25-bebbe62e25db",
    distance: 1.4,
    moment: "2022-01-25T20:00:00.000-08:00",
    end: "2022-01-25T22:00:00.000-08:00",
    id: "98c60c33-72b4-4317-9b25-bebbe62e25db",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/98c60c33-72b4-4317-9b25-bebbe62e25db",
    lat: -34.9075762,
    lng: 138.6061521,
  },
  "bd61dd53-1bbf-454a-8f83-f350f4aa088c": {
    guid: "bd61dd53-1bbf-454a-8f83-f350f4aa088c",
    address: {
      name: "address2",
      street_address: "2/152 Melbourne St",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.9075762,138.6061521",
      address: "2/152 Melbourne St",
      country_code: "AU",
      latitude: -34.9075762,
      longitude: 138.6061521,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5006",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "r.mcmillion@pokemon.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "vg_mod",
      update_key: "b7f50e6c9e4a4ab1b0631a127bae10c3",
      contact_email: "r.mcmillion@pokemon.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "b7f50e6c9e4a4ab1b0631a127bae10c3",
      sanctioned_date: "2022-01-07 12:02:22",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "12:02:18",
      on_site_registration_datetime: "2022-01-13 12:02:18",
    },
    name: "Wamsojmcfmr027",
    payment_options: "inperson",
    start_datetime: "2022-01-21T20:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "vg_mod",
    products: ["vg"],
    status: "sanctioned",
    when: "2022-01-21T20:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000154/",
    local_id: "bd61dd53-1bbf-454a-8f83-f350f4aa088c",
    distance: 1.4,
    moment: "2022-01-21T20:00:00.000-08:00",
    end: "2022-01-21T22:00:00.000-08:00",
    id: "bd61dd53-1bbf-454a-8f83-f350f4aa088c",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/bd61dd53-1bbf-454a-8f83-f350f4aa088c",
    lat: -34.9075762,
    lng: 138.6061521,
  },
  "8bc0c1ea-4643-4f66-8730-ffd7d122f4d0": {
    guid: "8bc0c1ea-4643-4f66-8730-ffd7d122f4d0",
    address: {
      name: "address2",
      street_address: "2/152 Melbourne St",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.9075762,138.6061521",
      address: "2/152 Melbourne St",
      country_code: "AU",
      latitude: -34.9075762,
      longitude: 138.6061521,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5006",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "r.mcmillion@pokemon.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "vg_mod",
      update_key: "a8ae70d0e5bf4f189cfc13d326055dad",
      contact_email: "r.mcmillion@pokemon.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "a8ae70d0e5bf4f189cfc13d326055dad",
      sanctioned_date: "2022-01-13 15:51:51",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "15:51:45",
      on_site_registration_datetime: "2022-01-19 15:51:45",
    },
    name: "Sujvvnjusin632",
    payment_options: "inperson",
    start_datetime: "2022-01-22T20:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "vg_mod",
    products: ["vg"],
    status: "sanctioned",
    when: "2022-01-22T20:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000347/",
    local_id: "8bc0c1ea-4643-4f66-8730-ffd7d122f4d0",
    distance: 1.4,
    moment: "2022-01-22T20:00:00.000-08:00",
    end: "2022-01-22T22:00:00.000-08:00",
    id: "8bc0c1ea-4643-4f66-8730-ffd7d122f4d0",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/8bc0c1ea-4643-4f66-8730-ffd7d122f4d0",
    lat: -34.9075762,
    lng: 138.6061521,
  },
  "b58a26c0-1de6-4af6-95af-f2364fc2a1d1": {
    guid: "b58a26c0-1de6-4af6-95af-f2364fc2a1d1",
    address: {
      name: "address4",
      street_address: "470 Kensington Rd",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.9247518,138.6763865",
      address: "470 Kensington Rd",
      country_code: "AU",
      latitude: -34.9247518,
      longitude: 138.6763865,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5066",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "r.mcmillion@pokemon.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "vg_mod",
      update_key: "9166eb0d08bb424bb349b4b0e480a89c",
      contact_email: "r.mcmillion@pokemon.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "9166eb0d08bb424bb349b4b0e480a89c",
      sanctioned_date: "2022-01-13 15:52:04",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "15:51:59",
      on_site_registration_datetime: "2022-01-19 15:51:59",
    },
    name: "Nurqzbsmtzm250",
    payment_options: "inperson",
    start_datetime: "2022-01-23T20:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "vg_mod",
    products: ["vg"],
    status: "sanctioned",
    when: "2022-01-23T20:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000348/",
    local_id: "b58a26c0-1de6-4af6-95af-f2364fc2a1d1",
    distance: 4.3,
    moment: "2022-01-23T20:00:00.000-08:00",
    end: "2022-01-23T22:00:00.000-08:00",
    id: "b58a26c0-1de6-4af6-95af-f2364fc2a1d1",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/b58a26c0-1de6-4af6-95af-f2364fc2a1d1",
    lat: -34.9247518,
    lng: 138.6763865,
  },
  "ce3dcdac-d72a-437f-b0c9-ffd103b61e63": {
    guid: "ce3dcdac-d72a-437f-b0c9-ffd103b61e63",
    address: {
      name: "address1",
      street_address: "1/58 Woodville Rd",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.8799593,138.5361493",
      address: "1/58 Woodville Rd",
      country_code: "AU",
      latitude: -34.8799593,
      longitude: 138.5361493,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5011",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "r.mcmillion@pokemon.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "tcg_std",
      update_key: "09b0174df63549d59aeb4885fad61221",
      contact_email: "r.mcmillion@pokemon.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "09b0174df63549d59aeb4885fad61221",
      sanctioned_date: "2022-01-13 15:51:31",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "15:51:24",
      on_site_registration_datetime: "2022-01-19 15:51:24",
    },
    name: "Qelsufbhjbk574",
    payment_options: "inperson",
    start_datetime: "2022-01-20T20:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "tcg_std",
    products: ["tcg"],
    status: "sanctioned",
    when: "2022-01-20T20:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000346/",
    local_id: "ce3dcdac-d72a-437f-b0c9-ffd103b61e63",
    distance: 4.9,
    moment: "2022-01-20T20:00:00.000-08:00",
    end: "2022-01-20T22:00:00.000-08:00",
    id: "ce3dcdac-d72a-437f-b0c9-ffd103b61e63",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/ce3dcdac-d72a-437f-b0c9-ffd103b61e63",
    lat: -34.8799593,
    lng: 138.5361493,
  },
  "80b813c5-ae12-46bf-8d64-26796275c09b": {
    guid: "80b813c5-ae12-46bf-8d64-26796275c09b",
    address: {
      name: "address1",
      street_address: "1/58 Woodville Rd",
      secondary_address: "",
      location_map_link:
        "https://maps.google.com/maps?q=-34.8799593,138.5361493",
      address: "1/58 Woodville Rd",
      country_code: "AU",
      latitude: -34.8799593,
      longitude: 138.5361493,
      state: "South Australia",
      city: "Adelaide",
      country: "AU",
      postal_code: "5011",
    },
    contact_information: {
      contact_phone: "4255551212",
      contact_email: "r.mcmillion@pokemon.com",
      website: "",
    },
    metadata: {
      website: "",
      category: "tcg_std",
      update_key: "c2d170a6d08549a3afc05bffe6d30614",
      contact_email: "r.mcmillion@pokemon.com",
      contact_phone: "4255551212",
      play_template: "swiss",
      pcom_update_key: "c2d170a6d08549a3afc05bffe6d30614",
      sanctioned_date: "2022-01-07 12:01:35",
      pem_player_count: 0,
      pcom_sync_success: true,
      sanction_validation_data: {},
      on_site_registration_time: "12:01:29",
      on_site_registration_datetime: "2022-01-13 12:01:29",
    },
    name: "Qxzrjfvqcri690",
    payment_options: "inperson",
    start_datetime: "2022-01-18T20:00:00",
    activity_type: "tournament",
    large_event_guid: null,
    has_registration_options: false,
    has_registration_skus: false,
    league_guid: null,
    tags: ["tournament"],
    activity_format: "tcg_std",
    products: ["tcg"],
    status: "sanctioned",
    when: "2022-01-18T20:00:00",
    premier_event_series_guid: null,
    pokemon_url:
      "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-01-000151/",
    local_id: "80b813c5-ae12-46bf-8d64-26796275c09b",
    distance: 4.9,
    moment: "2022-01-18T20:00:00.000-08:00",
    end: "2022-01-18T22:00:00.000-08:00",
    id: "80b813c5-ae12-46bf-8d64-26796275c09b",
    filter_type: "tournament",
    resource_parameter:
      "activity/tournament/80b813c5-ae12-46bf-8d64-26796275c09b",
    lat: -34.8799593,
    lng: 138.5361493,
  },
};

export const addMockEvents = (events) => {
  // copy pasta an event if you need another
  const mockEvents = [
    {
      retail_info: {
        name: "Yadeko Spielwaren",
      },
      tags: ["deliveryshipping", "in-person", "in-store-purchase", "online"],
      guid: "5acae01ec6d8c9b5f71c9672e6b88933",
      address: {
        location_map_link:
          "https://maps.google.com/maps?q=-34.9075762,138.6061521",
        country_code: "AU",
        latitude: -34.9075762,
        longitude: 138.6061521,
        state: "South Australia",
        city: "Adelaide",
        country: "AU",
        postal_code: "5006",
        name: "Yadeko Spielwaren",
        address: "13470 Washington Blvd suite 100",
        street_address: "13470 Washington Blvd",
        secondary_address: "suite 100",
        address1: "13470 Washington Blvd",
        address2: "suite 100",
      },
      contact_information: {
        contact_phone: 123456789,
        contact_email: "r.mcmillion@pokemon.com",
        website: null,
      },
      metadata: {
        category: "pgo_std",
        sanctioned_date: "2022-02-14 22:41:50",
        sanction_validation_data: {},
      },
      name: "Really long event name goes here. its large, really, large, big, giant, a truly, large, big, giant, a truly",
      payment_options: "nopayment",
      start_datetime: "2022-03-19T14:30:00+00:00",
      activity_type: "tournament",
      large_event_guid: null,
      has_registration_options: false,
      has_registration_skus: false,
      league_guid: null,
      activity_format: "pgo_std",
      products: ["pgo", "vg", "tcg"],
      status: "sanctioned",
      when: "2022-03-18T14:30:00Z",
      premier_event_series_guid: null,
      pokemon_url: null,
      local_id: "5acae01ec6d8c9b5f71c9672e6b88933",
      distance: 1.4,
      moment: new Date("2022-04-18T07:30:00.000-07:00"),
      end: new Date("2022-04-18T09:30:00.000-07:00"),
      id: "5acae01ec6d8c9b5f71c9672e6b88933",
      filter_type: "tournament",
      resource_parameter: null,
    },
    {
      guid: "1234567",
      address: {
        name: "React Dev Regionals 2021 React Dev Regionals 2021",
        street_address: "10400 NE 4th St",
        secondary_address: null,
        location_map_link:
          "https://maps.google.com/maps?q=47.6142019,-122.2008525",
        address: "10400 NE 4th St",
        country_code: "US",
        state: "Washington",
        city: "Bellevue",
        country: "US",
        postal_code: "98004",
      },
      contact_information: {
        contact_phone: "5555551234",
        contact_email: "brad@brad.com",
        website: "www.rai.com",
        owner_display: "Rai chu",
      },
      metadata: {
        website: "",
        category: "tcg_std",
        update_key: "e24fed7824be4c6bba804ade0b3ae824",
        contact_email: "brad@brad.com",
        contact_phone: "5555551234",
        play_template: "TCG2DAY",
        pcom_update_key: "91302eaf90104387b699b4490a1e1343",
        sanctioned_date: "2022-02-23 16:59:35",
        third_party_url: "http://www.test.com",
        pem_player_count: 0,
        group_transferred: true,
        pcom_sync_success: true,
        sanction_validation_data: {},
        third_party_registration_website: "https://www.pokemon.com/tcgl",
      },
      // Bradley Bailey's Organization (Default)'s Primary Activity Group
      name: "React Dev Regionals 2021 React Dev Regionals 2021",
      payment_options: "thirdParty",
      start_datetime: "2022-02-25T13:00:00+00:00",
      activity_type: "tournament",
      // comment back in to see the event
      large_event_guid: "84958694586958",
      has_registration_options: false,
      has_registration_skus: false,
      league_guid: null,
      tags: ["regionals", "tournament", "championship_series"],
      activity_format: "tcg_std",
      products: ["tcg", "vg", "pgo"],
      status: "sanctioned",
      when: "2022-02-25T13:00:00Z",
      premier_event_series_guid: "987655432",
      pokemon_url:
        "https://beta2.pokemon.com/us/pokemon-trainer-club/play-pokemon-tournaments/22-02-001414/",
      local_id: "1234567",
      // comment back in to see the event
      distance: 16.1,
      moment: "2022-03-25T05:00:00.000-08:00",
      end: "2022-03-25T07:00:00.000-08:00",
      id: "84958694586958",
      filter_type: "tournament",
      resource_parameter: "activity/tournament/1234567",
      isLargeEvent: true,
      division_details: {
        junior: {
          start_datetime: "2022-02-25T13:00:00+00:00",
          end_datetime: "2022-03-25T07:00:00+00:00",
          on_site_admission:
            "Loremdsfkjsdalfkjhdslkafjlksdafhkljsdahfkjdshlkjfhskdjfhksdahfjdshkjfhdsjklfhsdkjafjklsdhfkjsdhfjkldshakjflksjadhfhkjdsahfsadhfkjlhsdhakufhdsauhnfidsuhfviuwahvnrhnaeptoiyt857n89n79tvp8remtuoremhjitovermio ",
        },
        senior: {
          start_datetime: "2022-02-25T13:00:00+00:00",
          end_datetime: "2022-03-25T07:00:00+00:00",
        },
        master: {
          start_datetime: "2022-02-25T13:00:00+00:00",
          end_datetime: "2022-03-25T07:00:00+00:00",
        },
      },
    },
  ];

  return [...mockEvents, ...events];
};
