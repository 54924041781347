import React, { useContext } from "react";
import { SettingsContext } from "contexts/SettingsContext";
import "compiled/css/leavingSiteModal.css";
import { useTranslation } from "react-i18next";

function LeavingSiteModal() {
  const { t } = useTranslation();
  const {
    state: { intendedExitUrl },
    setModal,
  } = useContext(SettingsContext);
  const eatClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      id="leaving-site-modal"
      data-testid="leaving-site-modal"
      className="modal"
      onClick={eatClick}
    >
      <div className="graphic">
        <img src="/pikachu.svg" alt="waving pikachu" />
      </div>
      <div className="content">
        <div className="big-words">{t("LEAVING_POKEMON_HEADER")}</div>
        <div className="small-words">{t("LEAVING_POKEMON_BLURB")}</div>
        <div className="buttons">
          <div
            className="button cancel"
            onClick={() => setModal(null)}
            onKeyPress={() => setModal(null)}
            tabIndex="1"
          >
            {t("CANCEL")}
          </div>
          <a
            tabIndex="2"
            className="button"
            href={intendedExitUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setModal(null)}
          >
            {t("CONTINUE")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default LeavingSiteModal;
