import React from "react";
import { CSSTransition } from "react-transition-group";

import "compiled/css/messageBox.css";

import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { useTranslation } from "react-i18next";

function MessageBox({ visible, errorMessage, isError, initialShow, elUrl }) {
  const { t } = useTranslation();
  const renderSuccess = () => {
    const handleElClick = () => window.location.assign(elUrl);

    return (
      <div className="message-box success" data-testid="success_message">
        <div className="message">
          <span className="bold-label">{t("POGO_SUCCESS_1")}</span>
          {t("POGO_SUCCESS_2")}
        </div>
        <div className="message-box-button">
          <div onClick={handleElClick} className="pgo-button">
            {t("EVENT_LOCATOR_LABEL")}
          </div>
        </div>
      </div>
    );
  };

  const renderError = () => (
    <div className="message-box error" data-testid="error_message">
      <div className="message">{errorMessage}</div>
    </div>
  );

  return (
    <CSSTransition
      in={visible || initialShow}
      timeout={200}
      unmountOnExit
      classNames="message-box"
      data-testid="message_box"
    >
      <div className="message-box-container">
        {isError ? renderError() : renderSuccess()}
      </div>
    </CSSTransition>
  );
}

MessageBox.propTypes = exact({
  visible: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  initialShow: PropTypes.bool,
  elUrl: PropTypes.string,
});

export default MessageBox;
