import React, { useReducer, useEffect, useContext, useMemo } from "react";
import { EnvironmentContext } from "contexts/EnvironmentContext";
import { useLocation } from "react-router-dom";
import exact from "prop-types-exact";
import PropTypes from "prop-types";

const LocalizationContext = React.createContext();

const LocalizationConstants = {
  Actions: {
    SetLocale: "SET_LOCALE",
    SetGoogle: "SET_GOOGLE",
  },
  Locales: ["US", "UK", "FR", "IT", "DE", "ESP", "ESM", "PT-BR", "debug"],
  LanguageCodes: [
    "en-us",
    "en-gb",
    "fr-fr",
    "it-it",
    "de-de",
    "es-es",
    "es-mx",
    "pt-br",
  ],
  MapLanguages: ["en", "en", "fr", "it", "de", "es", "es", "pt"],
};

const LocaleKeyByCode = {
  "en-us": "US",
  "en-gb": "UK",
  "♥": "debug",
  "fr-fr": "FR",
  "it-it": "IT",
  "de-de": "DE",
  "es-es": "ESP",
  "es-mx": "ESM",
  "pt-br": "PT-BR",
};

const DistanceUnitByCode = {
  "en-us": "mi",
  "en-gb": "mi",
  "♥": "ht",
  "fr-fr": "km",
  "it-it": "km",
  "de-de": "km",
  "es-es": "km",
  "es-mx": "km",
  "pt-br": "km",
};

const TagCodeByLocale = {
  "fr-fr": "fr",
  "en-us": "en",
  "en-gb": "en",
  "it-it": "it",
  "de-de": "de",
  "es-es": "es",
  "es-mx": "es-xl",
  "pt-br": "pt-br",
};

const MapLanguageByLocale = {
  US: "en",
  UK: "en",
  FR: "fr",
  IT: "it",
  DE: "de",
  ESP: "es",
  ESM: "es",
  "PT-BR": "pt",
};

const LanguageCodeByLocale = {
  US: "en-us",
  UK: "en-gb",
  FR: "fr-fr",
  IT: "it-it",
  DE: "de-de",
  ESP: "es-es",
  ESM: "es-mx",
  "PT-BR": "pt-br",
};

const { Actions } = LocalizationConstants;

const initialLocalizationState = {
  currentLocale: null,
  currentLanguageCode: null,
  mapLanguage: "en",
  google: null,
};

const LocalizationReducer = (state, { type, value }) => {
  switch (type) {
    case Actions.SetLocale:
      return {
        ...state,
        currentLocale: value,
        currentLanguageCode: LanguageCodeByLocale[value],
      };
    case Actions.SetGoogle:
      return {
        ...state,
        google: value,
      };
    default:
      return { ...state };
  }
};

function LocalizationProvider({ children, defaultValues }) {
  const environment = useContext(EnvironmentContext);
  const defaultProps = defaultValues || {};
  const [state, dispatch] = useReducer(LocalizationReducer, {
    ...initialLocalizationState,
    ...defaultProps,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.google) {
        dispatch({ type: Actions.SetGoogle, value: window.google });
        clearInterval(intervalId);
      }
    }, 200);
  }, [state.google]);

  useEffect(() => {
    if (!state.currentLocale) {
      return;
    }
    if (window.google) {
      delete window.google;
      dispatch({ type: Actions.SetGoogle, value: null });
    }
    const mapsScript = document.createElement("script");
    mapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${
      environment.state.mapsKey
    }&libraries=places&language=${MapLanguageByLocale[state.currentLocale]}`;
    mapsScript.async = true;
    document.body.appendChild(mapsScript);
    return () => {
      document.body.removeChild(mapsScript);
    }; // eslint-disable-next-line
  }, [state.currentLocale]);

  const location = useLocation();
  useEffect(() => {
    const [, newCode] = location.pathname.split("/");
    const localeKey = LocaleKeyByCode[newCode];
    if (localeKey) {
      dispatch({ type: Actions.SetLocale, value: localeKey });
    }
  }, [location]);

  const contextValue = useMemo(() => {
    const getCurrentDistanceUnits = () => {
      const unit = DistanceUnitByCode[state.currentLanguageCode] || "mi";
      const unitsPerMile = unit === "km" ? 1.60934 : 1;
      return { unit, unitsPerMile };
    };

    return {
      state,
      dispatch,
      getCurrentDistanceUnits,
    };
  }, [state, dispatch]);

  return (
    <LocalizationContext.Provider value={contextValue}>
      {children}
    </LocalizationContext.Provider>
  );
}

LocalizationContext.Provider.propTypes = exact({
  value: PropTypes.exact({
    state: PropTypes.exact({
      currentLocale: PropTypes.oneOf([
        ...LocalizationConstants.Locales,
        "TEST",
      ]),
      currentLanguageCode: PropTypes.oneOf(LocalizationConstants.LanguageCodes),
      mapLanguage: PropTypes.oneOf(LocalizationConstants.MapLanguages)
        .isRequired,
      // type is window.google which is a part of the google maps api
      google: PropTypes.object,
    }),
    getCurrentDistanceUnits: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.node,
});

export {
  LocalizationContext,
  LanguageCodeByLocale,
  LocalizationProvider,
  LocalizationConstants,
  TagCodeByLocale,
  MapLanguageByLocale,
  LocaleKeyByCode,
  DistanceUnitByCode,
};
