import { DateService } from "utils/date-service";
import { FiltersConstants } from "../FiltersContext";
import { Applications } from "contexts/EnvironmentContext";

export const filterEvents = ({
  dispatch,
  searchableEventsById,
  eventIds,
  activeFilters,
  application,
  sortAttr,
  maxDistance,
  maxDistanceSet,
  maxDate,
  eventSearchLocation,
  page,
  location,
  isChampionshipView,
  updateUrlParams,
}) => {
  const { Actions } = FiltersConstants;

  // updating url based on filter changes
  const pages = ["tournaments", "sessions", "retail", "group"];
  const isDetailView = pages.find((basePage) => page === basePage);
  if (!isDetailView && !isChampionshipView) {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const filters = Object.keys(activeFilters).filter((x) => activeFilters[x]);
    const search = {};

    const locationParam =
      eventSearchLocation?.formatted_address || params?.near;
    if (locationParam) search.near = locationParam;
    if (filters.length) {
      search.filters = filters.join(",");
    }

    updateUrlParams({
      ...search,
    });
  }
  // end url updating
  const closestDistance = maxDistance + 1;

  const filteredIds = getFilteredEvents({
    activeFilters,
    maxDate,
    maxDistance,
    application,
    eventIds,
    searchableEventsById,
    closestDistance,
  });

  const sortedIds = getSortedEvents({
    filteredIds,
    sortAttr,
    searchableEventsById,
  });

  if (filteredIds) {
    dispatch({
      type: Actions.SetFilteredEvents,
      value: sortedIds,
    });
  } else if (
    closestDistance < FiltersConstants.maxMaxDistance &&
    !maxDistanceSet
  ) {
    dispatch({ type: Actions.setMaxDistance, value: closestDistance });
  }
};

export function getFilteredEvents({
  activeFilters,
  maxDistance,
  maxDate,
  application,
  eventIds,
  searchableEventsById,
}) {
  const isRetail = application === Applications.RetailLocator;

  const { EventsFilterGroupOrder, FilterGroups } = FiltersConstants;

  const tests = [];
  EventsFilterGroupOrder.map(({ key, type }) => {
    const { tags } = FilterGroups[key];
    const testTags = tags.filter((tag) => activeFilters[tag] === true);
    if (testTags.length > 0) {
      tests.push({ key, type, testTags });
    }
    return null;
  });

  let filteredIds = [];
  if (tests.length === 0) {
    filteredIds = eventIds;
  } else {
    eventIds.forEach((id) => {
      const event = searchableEventsById[id];

      let totalTests = tests.length;
      let testsPass = false;
      let testCount = 0;

      tests.forEach((test) => {
        const { key, type, testTags } = test;

        if (activeFilters.league && !activeFilters.tournament) {
          if (type === "string") {
            const eventTestStr = event[key];
            if (eventTestStr === "league") {
              totalTests = 1;
              testsPass = true;
              testCount += 1;
            }
          }
        } else if (activeFilters.league && activeFilters.tournament) {
          if (type === "array") {
            const eventTestTags = event[key];
            eventTestTags.forEach((tag) => {
              if (testTags.includes(tag)) {
                testsPass = true;
                testCount += 1;
              }
            });
          }
          if (type === "string") {
            const eventTestStr = event[key];
            if (eventTestStr === "league") {
              totalTests = 1;
              testsPass = true;
              testCount += 1;
            } else if (testTags.includes(eventTestStr)) {
              testsPass = true;
              testCount += 1;
            }
          }
        } else {
          if (type === "array") {
            const eventTestTags = event[key];
            eventTestTags.forEach((tag) => {
              if (testTags.includes(tag)) {
                testsPass = true;
                testCount += 1;
              }
            });
          }
          if (type === "string") {
            const eventTestStr = event[key];
            if (testTags.includes(eventTestStr)) {
              testsPass = true;
              testCount += 1;
            }
          }
        }
      });

      const testCasesMatched = testCount >= totalTests;
      if (testsPass && testCasesMatched) filteredIds.push(id);
    });
  }

  if (!isRetail) {
    // capture events that happen before a given date
    if (typeof maxDate !== "undefined") {
      filteredIds = filteredIds.filter((id) => {
        const event = searchableEventsById[id];
        // condition for jest tests
        if (typeof event.moment === "string") {
          const eventMoment = new DateService().setDate({
            format: "utc",
            date: event.moment,
          });
          return eventMoment.getDate() < maxDate.getDate();
        }
        return event.moment.getDate() < maxDate.getDate();
      });
    }
    // capture events that are within a given distance
  }

  if (typeof maxDistance !== "undefined") {
    filteredIds = filteredIds.filter((id) => {
      const event = searchableEventsById[id];
      return event.distance < maxDistance;
    });
  }

  return filteredIds;
}

export function getSortedEvents({
  filteredIds = [],
  sortAttr,
  searchableEventsById,
}) {
  return filteredIds
    .sort((a, b) => {
      let A = searchableEventsById[a][sortAttr];
      let B = searchableEventsById[b][sortAttr];
      // We can't subtract dates form each other unless they're ACTUALLY dates.
      if (sortAttr === "when") {
        A = new Date(A);
        B = new Date(B);
      }
      return A - B;
    })
    .slice(0, 250);
}
