const EventTypes = ["tournament", "league"];
const SubEventTypes = [
  {
    type: "tcg",
    label: "Trading Card Game",
    tags: ["prerelease", "league_challenge", "league_cup"],
  },
  {
    type: "vg",
    label: "Video Game",
    tags: ["premier_challenge", "midseason_showdown"],
  },
];

const PremierChampionships = {
  label: "Premier Championships",
  tags: ["regionals", "international_championship", "worlds"],
};

export { EventTypes, SubEventTypes, PremierChampionships };
