import React from "react";
import EventFinder from "components/EventFinder";
import "compiled/css/pageHolder.css";

function PageHolder() {
  return (
    <div id="page-holder">
      <EventFinder />
    </div>
  );
}

export default PageHolder;
