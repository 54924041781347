import React, { useContext } from "react";
// utils
import exact from "prop-types-exact";
import PropTypes from "prop-types";
// context
import { EnvironmentContext } from "contexts/EnvironmentContext";
import { useTranslation } from "react-i18next";

function EventPlaytimes({ eventId }) {
  const { t } = useTranslation();
  const {
    state: { endpoints },
  } = useContext(EnvironmentContext);

  const url = `${endpoints.LEAGUE_PLAYTIME_REDIRECT}${eventId.replaceAll(
    "-",
    ""
  )}`;

  return (
    <div className="event_playtimes">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="event-playtimes"
      >
        {t("VIEW_PLAYTIMES")}
      </a>
    </div>
  );
}

EventPlaytimes.propTypes = exact({
  eventId: PropTypes.string,
});

export default EventPlaytimes;
