import React, { useContext, useState } from "react";
import { FiltersContext, FiltersConstants } from "contexts/FiltersContext";
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";

import "compiled/css/controls/dateControl.css";
import { DateService } from "utils/date-service";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { CalendarIcon } from "./CalenderIcon";
import { useTranslation } from "react-i18next";

export const DATE_FORMAT = "MM/dd/yyyy";
const nextWeek = new DateService()
  .setDate({
    format: "utc",
    date: DateService.nowISO(),
  })
  .addTime({ duration: 7, unit: "days" });
function DateControl() {
  const { t } = useTranslation();

  const {
    state: { maxDate },
    updateMaxDate,
  } = useContext(FiltersContext);
  const {
    state: { application },
  } = useContext(EnvironmentContext);

  const { maxMaxDate } = FiltersConstants;
  const calenderDate = maxDate.getDate().toJSDate();
  const minCalenderDate = nextWeek.getDate().toJSDate();
  const maxCalenderDate = maxMaxDate.getDate().toJSDate();

  const [selectedDate, setSelectedDate] = useState(calenderDate);

  if (application !== Applications.EventLocator) {
    return null;
  }

  const dateChange = (date) => {
    setSelectedDate(date);
  };

  const handleUpdateMaxDate = () => {
    const proposedMaxDate = new DateService(
      DateTime.fromJSDate(selectedDate).toUTC()
    );

    if (calenderDate.getDate() === selectedDate.getDate()) {
      return;
    }

    const endOfProposedMaxDate = proposedMaxDate.getDate().endOf("day");

    if (endOfProposedMaxDate < nextWeek.getDate()) {
      updateMaxDate(nextWeek);
      return;
    } else if (endOfProposedMaxDate > maxMaxDate.getDate()) {
      updateMaxDate(maxMaxDate);
      return;
    }

    updateMaxDate(proposedMaxDate);
  };

  return (
    <div className="date-control" data-testid="date_control">
      <div className="date-control-label">{t("MAX_DATE")}</div>
      <label>
        <ReactDatePicker
          id="date-input"
          title="date-input"
          selected={selectedDate}
          dateFormat={DATE_FORMAT}
          onChange={dateChange}
          onBlur={handleUpdateMaxDate}
          minDate={minCalenderDate}
          maxDate={maxCalenderDate}
        />
        <div className="icon-wrapper" data-testid="calendar_icon">
          <CalendarIcon />
        </div>
      </label>
    </div>
  );
}

export default DateControl;
