import React, { useContext, useMemo } from "react";
import "compiled/css/eventFinder.css";
import { useWindowSize } from "hooks";
import MapContainer from "components/Controls/MapContainer";
import Slider from "components/Controls/Slider";
import Modal from "components/Controls/Modal";
import EventList from "components/EventList/EventList";
import EventDetailView from "components/EventDetail/DetailView";
import { UrlContext } from "contexts/UrlContext";
import { SettingsContext } from "contexts/SettingsContext";
import { withRouter } from "react-router-dom";
import exact from "prop-types-exact";
import { REACT_ROUTER } from "../utils/propTypes";

function EventFinder() {
  const { getSelectedEvent } = useContext(UrlContext);
  const windowSize = useWindowSize();
  const focusedEvent = getSelectedEvent();
  const selectedSlide = focusedEvent ? "detail" : "list";
  const {
    state: { showMap },
  } = useContext(SettingsContext);

  // Release day mobile fix PLEASE do not let this live forever.
  const smallScreen = windowSize.width <= 1000;
  let height = "100%";
  if (smallScreen) {
    const adjustSize = 80 + (showMap ? 50 : 0);
    height = windowSize.height - adjustSize;
  }

  return useMemo(() => {
    const sliderClass = showMap
      ? "event-list-slider"
      : "event-list-slider mapless";

    return (
      <div id="event-finder">
        <Modal />
        <div className="content-holder">
          <Slider
            selectedSlide={selectedSlide}
            height={height}
            className={sliderClass}
          >
            <EventList key="list" />
            <EventDetailView key="detail" />
          </Slider>
          <MapContainer />
        </div>
      </div>
    );
  }, [selectedSlide, showMap, height]);
}

EventFinder.propTypes = exact({
  ...REACT_ROUTER,
});

export default withRouter(EventFinder);
