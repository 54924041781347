import React, { useContext } from "react";
import exact from "prop-types-exact";
import "compiled/css/optInModal.css";
import { SettingsContext } from "contexts/SettingsContext";
import { EnvironmentContext } from "contexts/EnvironmentContext";
import { MODAL_SECTIONS } from "utils/propTypes";
import { useTranslation } from "react-i18next";

function OptInModal({ sections }) {
  const { setModal } = useContext(SettingsContext);
  const {
    state: { environment },
  } = useContext(EnvironmentContext);
  const { t } = useTranslation();

  const eatClick = (e) => {
    e.stopPropagation();
  };

  const handleBackClick = () => setModal(null);

  const testingEnvs = ["staging", "skull"];
  const localEnvs = ["dev", "local"];
  let basePath = "club";
  if (testingEnvs.includes(environment)) basePath += "-beta2";
  if (localEnvs.includes(environment)) basePath += ".skull";
  const handleConfirmClick = () =>
    window.open(
      `https://${basePath}.pokemon.com/us/pokemon-trainer-club/play-pokemon/setup/`,
      "_blank"
    );

  return (
    <div
      id="optin-modal"
      className="modal"
      data-testid="optin_modal"
      onClick={eatClick}
    >
      <div className="content">
        <div className="optin-content">
          <div className="optin-header">{t("STAGE_2_HEADER")}</div>
          {sections?.map((section, idx) => (
            <div className="optin-row" key={section}>
              <div className="step">{idx + 1}</div>
              <div className="directions">
                <div className="header">{section.header}</div>
                <div className="description">{section.description}</div>
              </div>
            </div>
          ))}
          <div className="footer">
            <div onClick={handleConfirmClick} className="optin-button confirm">
              {t("CONFIRM_BUTTON_LABEL")}
            </div>
            <div onClick={handleBackClick} className="optin-button">
              {t("BACK_BUTTON_LABEL")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

OptInModal.propTypes = exact({
  sections: MODAL_SECTIONS,
});

export default OptInModal;
