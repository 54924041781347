import { useState, useRef, useEffect } from "react";

export function useTooltip({ mini, smallScreen }) {
  const textDisplayEl = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const isToolTipDisplayed = !mini && !smallScreen && isOverflowed;

  useEffect(() => {
    const element = textDisplayEl.current.parentElement;
    const { scrollHeight, clientHeight } = element;
    setIsOverflowed(scrollHeight > clientHeight);
  }, []);

  return { isToolTipDisplayed, textDisplayEl };
}
