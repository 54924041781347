import React, { useContext } from "react";
import { SettingsContext } from "contexts/SettingsContext";
import "compiled/css/footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  const {
    state: { modal },
    intendToLeave,
  } = useContext(SettingsContext);
  const tabIndex = modal ? -1 : 0;

  const currentYear = new Date().getFullYear();
  const copyrightText = `©${currentYear} Pokémon. ©1995 - ${currentYear} Nintendo/Creatures Inc./GAME FREAK inc. TM,
  ®Nintendo.`;

  return (
    <div className="legal-footer" data-testid="footer">
      <div className="links-and-caru">
        <div
          tabIndex={tabIndex}
          className="exit-link"
          onKeyPress={() => {
            intendToLeave(t("CARU_LINK"));
          }}
          onClick={() => {
            intendToLeave(t("CARU_LINK"));
          }}
        >
          <img alt="caru logo" src="/caru.svg" />
        </div>
        <div className="links">
          <a
            tabIndex={tabIndex}
            target="_blank"
            rel="noopener noreferrer"
            href={t("CUSTOMER_SERVICE_LINK")}
          >
            {t("CUSTOMER_SERVICE")}
          </a>
          <a
            tabIndex={tabIndex}
            target="_blank"
            rel="noopener noreferrer"
            href={t("TERMS_OF_USE_LINK")}
          >
            {t("TERMS_OF_USE")}
          </a>
          <a
            tabIndex={tabIndex}
            target="_blank"
            rel="noopener noreferrer"
            href={t("PRIVACY_NOTICE_LINK")}
          >
            {t("PRIVACY_NOTICE")}
          </a>
          <a tabIndex={tabIndex} id="cookieLink" href={t("COOKIE_PAGE_LINK")}>
            {t("COOKIE_PAGE")}
          </a>
          <a tabIndex={tabIndex} href={t("LEGAL_INFO_LINK")}>
            {t("LEGAL_INFO")}
          </a>
        </div>
      </div>
      <div className="copyright">{copyrightText}</div>
    </div>
  );
}

export default Footer;
