import React, { useContext } from "react";

// contexts
import { LocalizationContext } from "contexts/LocalizationContext";
import { EnvironmentContext } from "contexts/EnvironmentContext";

import { replaceLocale } from "./utils/pGoHelpers";
import { isLowerEnv } from "utils/envHelpers";

import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { STAGE } from "utils/propTypes";
import { useTranslation } from "react-i18next";

function StageCard({ cardStep, stage }) {
  const {
    isComplete,
    buttonLabel,
    details,
    custom = null,
    imageSrc,
    url,
    disabled,
    handleStageClick,
    delinkComponent = null,
  } = stage;
  const { t } = useTranslation();

  const buttonText = isComplete ? t("POGO_DONE_BUTTON_LABEL") : t(buttonLabel);

  // contexts
  const {
    state: { currentLanguageCode },
  } = useContext(LocalizationContext);
  const {
    state: { environment },
  } = useContext(EnvironmentContext);

  const CustomComponent = custom?.component;
  const stageUrl = replaceLocale(url, currentLanguageCode);

  const handleClick = () => {
    if (disabled || isComplete) return;
    if (handleStageClick) handleStageClick();
    if (stageUrl) window.open(stageUrl);
  };

  let buttonClass = "pgo-button";
  if (isComplete) buttonClass += " complete";
  if (disabled) buttonClass += " disabled";

  const renderDelink = isLowerEnv(environment) && isComplete;

  const buttonTestId = `stage_${cardStep}_test_button`;
  const cardTestId = `stage_${cardStep}_card`;

  return (
    <div className="stage-card" data-testid={cardTestId}>
      <div className="order">{cardStep}</div>
      <div className="content">
        <div className="stage-image-container">
          <img className="stage-image" src={imageSrc} alt="" />
        </div>
        <div>
          <div className="stage-description">{t(details)}</div>
          {renderDelink && delinkComponent ? delinkComponent() : null}
          {custom ? (
            <CustomComponent {...custom.props} />
          ) : (
            <div
              onClick={handleClick}
              className={buttonClass}
              data-testid={buttonTestId}
            >
              {buttonText}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

StageCard.propTypes = exact({
  cardStep: PropTypes.number.isRequired,
  stage: STAGE.isRequired,
});

export default StageCard;
