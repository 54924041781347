import React from "react";
import "compiled/css/teamChallengeBanner.css";
import { useTranslation } from "react-i18next";

function TeamChallengeBanner() {
  const { t } = useTranslation();

  return (
    <div className="message-holder">
      <a
        href={t("TEAM_CHALLENGE_LINK")}
        id="team-challenge-banner"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="bg-holder">
          <div className="left-bg" />
          <div className="right-bg" />
        </div>
        <div className="fg-holder">
          <div className="badge-holder">
            <img alt="team challenger badge" src="/teamChallengeBadge.png" />
          </div>
          <div className="headline">{t("TEAM_CHALLENGE_BANNER")}</div>
          <div className="pikachu-holder">
            <img alt="pikachu" src="/pikachu-jump.png" />
          </div>
        </div>
      </a>
    </div>
  );
}

export default TeamChallengeBanner;
