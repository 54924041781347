import React from "react";
import { isLowerEnv } from "utils/envHelpers";

const replaceLocale = (url, locale) => {
  if (url && locale) {
    return url.replace(`$LOCALE`, locale);
  }
  return url;
};

const generateNianticLinkUrl = ({ environment, currentLanguageCode }) => {
  const isDev = isLowerEnv(environment);
  const service = isDev ? "epbdev" : "epb";
  let redirectEnv = "skull";
  if (environment === "prod") redirectEnv = "prod";

  const continueUrl = isDev
    ? `https://nalsredirect.${redirectEnv}.pokemon.com/v1/redirect/niantic&state=$LOCALE`
    : `https://nalsredirect.pokemon.com/v1/redirect/niantic&state=$LOCALE`;

  const envUrl = `https://signin.nianticlabs.com/signin?service=${service}&continue=${continueUrl}`;
  return replaceLocale(envUrl, currentLanguageCode);
};

const getNalsRedirectToken = () => {
  const splitCookie = document?.cookie && document?.cookie.split("; ");
  const tokenStr =
    splitCookie.length && splitCookie.find((row) => row.startsWith("token="));
  const splitToken = tokenStr && tokenStr.split("=");
  return splitToken ? splitToken[1] : null;
};

const generateELUrl = ({ environment, currentLanguageCode }) => {
  const isDev = isLowerEnv(environment);
  const devUrl =
    environment === "dev"
      ? "https://events.pokemon.localhost/$LOCALE"
      : `https://events.${environment}.pokemon.com/$LOCALE`;
  const url = isDev ? devUrl : "https://events.pokemon.com/$LOCALE";
  return replaceLocale(url, currentLanguageCode);
};

const linkUrl = (text, supportLink) => {
  const splitLinkText = text.split(supportLink);
  const linkUrlArr = [splitLinkText[0], "", splitLinkText[1]];

  return React.Children.toArray(
    linkUrlArr.map((part, idx) =>
      idx === 1 ? (
        <a
          href={supportLink}
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {supportLink}
        </a>
      ) : (
        part
      )
    )
  );
};

const linkPolicy = (text, policyLinkStr, privacyNoticeLink) => {
  const splitStr = text.split(policyLinkStr);

  return React.Children.toArray(
    splitStr.map((part, idx) => {
      if (idx === 1) {
        return (
          <span>
            <a
              className="link"
              rel="noopener noreferrer"
              href={privacyNoticeLink}
              target="_blank"
            >
              {policyLinkStr}
            </a>
            .
          </span>
        );
      }
      return part;
    })
  );
};

const formatPolicy = (
  disclaimerText,
  policyLinkStr,
  privacyNoticeLink,
  supportLink
) => {
  let formattedPolicy = [];
  const splitPolicy = disclaimerText
    .split(/\r?\n/)
    .filter((part) => part !== "");

  if (splitPolicy.length > 2) {
    formattedPolicy = splitPolicy.map((part, idx) => {
      let content = part;
      if (idx === 2) content = linkUrl(part, supportLink);
      if (idx === 4) {
        content = linkPolicy(part, policyLinkStr, privacyNoticeLink);
      }
      return (
        <div className="confirm-part" key={part.toString()}>
          {content}
        </div>
      );
    });
  }

  return formattedPolicy;
};

export {
  replaceLocale,
  generateNianticLinkUrl,
  getNalsRedirectToken,
  generateELUrl,
  formatPolicy,
};
