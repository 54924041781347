// node modules
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// styles
import "compiled/css/headerBar.css";

// components
import ToggleRow from "components/HeaderBar/ToggleRow";
import SearchInput from "components/SearchInput";
import ChampionshipToggle from "components/HeaderBar/ChampionshipToggle";
import DistanceDropdown from "./filters-dropdown/distance-dropdown/DistanceDropdown";

// context
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";
import { FiltersContext } from "contexts/FiltersContext";
import FiltersDropdown from "./filters-dropdown/FiltersDropdown";

const searchFilterClass = "search-and-filter";

function HeaderBar({ hideElements }) {
  const {
    state: { isChampionshipView },
  } = useContext(FiltersContext);

  const {
    state: { application },
  } = useContext(EnvironmentContext);

  const renderEventFilters = () => {
    if (hideElements) return null;
    return (
      <div className="search-and-filter">
        <div className="left">
          {!isChampionshipView && (
            <div className="hidden-sm">
              <SearchInput />
            </div>
          )}
          <div className="filters-and-controls hidden-sm group-label">
            <FiltersDropdown />
            <ChampionshipToggle />
          </div>
        </div>
        <ToggleRow />
      </div>
    );
  };

  const renderRetailFilters = () => (
    <div className={`${searchFilterClass} retail`}>
      <SearchInput />
      <div className="filters-and-controls group-label">
        <DistanceDropdown />
      </div>
      <ToggleRow />
    </div>
  );

  return (
    <div className="header-bar">
      {application === Applications.EventLocator
        ? renderEventFilters()
        : renderRetailFilters()}
    </div>
  );
}

HeaderBar.propTypes = {
  // used mainly for testing - rework of global router needed in future
  hideElements: PropTypes.bool,
};

export default withRouter(HeaderBar);
