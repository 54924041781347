import React from "react";

export function CalendarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3359 1.9974H12.6693V0.664062H11.3359V1.9974H4.66927V0.664062H3.33594V1.9974H2.66927C1.93594 1.9974 1.33594 2.5974 1.33594 3.33073V13.9974C1.33594 14.7307 1.93594 15.3307 2.66927 15.3307H13.3359C14.0693 15.3307 14.6693 14.7307 14.6693 13.9974V3.33073C14.6693 2.5974 14.0693 1.9974 13.3359 1.9974ZM13.3359 13.9974H2.66927V5.33073H13.3359V13.9974Z"
        fill="black"
        fillOpacity="0.87"
      />
    </svg>
  );
}
