import React from "react";
import Filters from "components/HeaderBar/filters-dropdown/Filters";

function FiltersModal() {
  const eatClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      id="filters-modal"
      className="modal"
      data-testid="filters_modal"
      onClick={eatClick}
    >
      <div className="content">
        <Filters isExpanded />
      </div>
    </div>
  );
}

export default FiltersModal;
