import React from "react";

export function EventCardAddress({
  addressData,
  isChildEvent,
  isRetail,
  mini,
}) {
  const {
    address,
    address2,
    address1,
    secondary_address,
    city,
    state,
    country_code,
    street_address,
    formatted_address,
  } = addressData;

  if (mini || isChildEvent) {
    return null;
  }
  let printedAddress = "";
  const add2 = secondary_address || address2;

  if (address) {
    printedAddress = address;
  } else {
    printedAddress = street_address || address1;
    if (add2) {
      printedAddress = `${printedAddress}, ${add2}`;
    }
  }

  const location = [city, state, country_code]
    .filter((text) => Boolean(text))
    .join(", ");

  const renderAddress = formatted_address ? (
    <div
      className="address"
      data-testid="address"
      style={isRetail ? { inlineSize: "100%" } : null}
    >
      {formatted_address}
    </div>
  ) : (
    <>
      <div
        className="address"
        data-testid="address"
        style={isRetail ? { inlineSize: "100%" } : null}
      >
        {printedAddress}
      </div>
      <div className="location" data-testid="location">
        {location}
      </div>
    </>
  );

  return <div className="address-block">{renderAddress}</div>;
}
