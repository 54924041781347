import { SvgByTag } from "components/constants/icon";
import { Applications, EnvironmentContext } from "contexts/EnvironmentContext";
import { EventsContext } from "contexts/EventsContext";
import { FiltersContext } from "contexts/FiltersContext";
import { memo, useContext } from "react";
import { flatify } from "utils/badBrowserHelpers";

function Tags({ eventId, childEvent }) {
  const { state: filterState } = useContext(FiltersContext);
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const {
    state: { searchableEventsById, childEventsById },
  } = useContext(EventsContext);
  const { products } = childEvent ||
    searchableEventsById[eventId] || { address: {} };

  if (application !== Applications.EventLocator) {
    return null;
  }
  const largeEventTags = Array.from(
    new Set(
      childEventsById?.[eventId]?.children
        .map((childEvent) => childEvent.products)
        .flat()
    )
  );

  const targetTags = Array.from(
    new Set([...largeEventTags, ...flatify([products])])
  );

  const tagIcons = targetTags
    .filter((tag) => SvgByTag[tag])
    .map((tag) => {
      let tagClass = "tag-holder";
      const active = filterState.activeFilters[tag];
      const iconSrc = `/icons/${SvgByTag[tag]}-blank.svg`;
      if (active) {
        tagClass += " active";
      }
      return (
        <div className={tagClass} key={tag} data-testid="tag-holder">
          <img className="icon" src={iconSrc} alt={tag} />
        </div>
      );
    });
  return (
    <div data-testid="icons" className="tags">
      {tagIcons}
    </div>
  );
}

export default memo(Tags);
