import SortControl from "components/Controls/SortControl";
import { FiltersContext } from "contexts/FiltersContext";
import { useContext, useRef } from "react";
import { Row } from "../Row";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export function PrimaryContent() {
  const {
    state: { filteredIds },
  } = useContext(FiltersContext);

  const listRef = useRef({});

  const rowHeights = useRef({});

  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 80;
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  return (
    <div className="cards-holder">
      <SortControl />
      <div className="cards">
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={filteredIds.length}
              itemSize={getRowHeight}
              width={width}
              ref={listRef}
            >
              {({ index, style }) => (
                <Row style={style} index={index} setRowHeight={setRowHeight} />
              )}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}
