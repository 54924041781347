// node modules
import React, { useContext } from "react";

// styles
import "compiled/css/distanceDropdown.css";

// contexts
import { FiltersContext } from "contexts/FiltersContext";
import { LocalizationContext } from "contexts/LocalizationContext";
import { EventsContext } from "contexts/EventsContext";

import DropdownMenu from "components/Controls/DropdownMenu";
import { unitOptions, unitMap, miToKmMap } from "./constants";
import { useTranslation } from "react-i18next";

function DistanceButton({ opt, currDistanceValue }) {
  const {
    state: { sortAttr },
    updateMaxDistance,
  } = useContext(FiltersContext);
  const { getCurrentDistanceUnits } = useContext(LocalizationContext);
  const { unit } = getCurrentDistanceUnits();
  const {
    state: { eventSearchLocation },
    setLocation,
  } = useContext(EventsContext);
  const distanceMap = unitMap[unit];

  let btnClass = `distance-dropdown-button`;

  const handleFilterClick = () => {
    let distVal = opt;
    if (unit === "km") {
      distVal = distanceMap[opt];
    }
    updateMaxDistance(distVal);
    if (eventSearchLocation) {
      const { lat, lng } = eventSearchLocation;
      setLocation({
        location: { lat, lng },
        sortAttr,
        distance: distVal,
      });
    }
  };

  const testId = `${opt}_distance_dropdown_button`;
  const label = `${opt} ${unit}`;

  if (opt === currDistanceValue) btnClass += " active";

  return (
    <div className={btnClass} onClick={handleFilterClick} data-testid={testId}>
      <span className="label">{label}</span>
    </div>
  );
}

function DistanceDropdown() {
  const { t } = useTranslation();
  const {
    state: { maxDistance },
  } = useContext(FiltersContext);
  const { getCurrentDistanceUnits } = useContext(LocalizationContext);
  const { unit } = getCurrentDistanceUnits();

  const distanceOptions = unitOptions[unit];

  const currDistanceValue =
    unit === "km" ? miToKmMap[maxDistance] : maxDistance;

  const renderMenuItems = () =>
    distanceOptions.map((opt) => (
      <DistanceButton
        key={opt}
        opt={opt}
        currDistanceValue={currDistanceValue}
      />
    ));

  const buttonLabel = `${currDistanceValue} ${unit}`;

  return (
    <div className="distance-dropdown-container">
      <div className="distance-label">{t("MAX_DISTANCE")}</div>
      <DropdownMenu
        closeOnChangeVal={maxDistance}
        buttonLabel={buttonLabel}
        testId="distance_dropdown_menu"
      >
        <div className="distance-filter-container">{renderMenuItems()}</div>
      </DropdownMenu>
    </div>
  );
}

export default DistanceDropdown;
