import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export class MonitoringService {
  static init() {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "event-locator",
      env: process.env.REACT_APP_ENV,
      version: "1.0.0",
      sessionSampleRate: 5,
      sessionReplaySampleRate: 5,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "mask",
    });

    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "event-locator",
      env: process.env.REACT_APP_ENV,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  }

  static startSession() {
    datadogRum.startSessionReplayRecording();
  }
}
