import { request } from "contexts/EnvironmentContext";

export const fetchNianticUser = ({ endpoints, signal }) => {
  const endpoint = endpoints.LINK_NIANTIC;

  return new Promise((resolve, reject) => {
    const bearer = `Bearer ${window.localStorage.getItem("accessToken")}`;

    request({
      endpoint,
      args: {
        headers: {
          Authorization: bearer,
        },
        signal,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const linkAccount = ({
  token,
  endpoints,
  setComplete,
  setNianticUsername,
}) => {
  const bearer = `Bearer ${window.localStorage.getItem("accessToken")}`;
  request({
    endpoint: endpoints.LINK_NIANTIC,
    args: {
      method: "POST",
      headers: {
        Authorization: bearer,
      },
      body: JSON.stringify({ token }),
    },
  })
    .then((res) => {
      if (res) {
        setNianticUsername(res.name);
        setComplete(true);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
