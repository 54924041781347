import React, { useContext } from "react";
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";
import exact from "prop-types-exact";
import { EVENT } from "../../utils/propTypes";
import { useTranslation } from "react-i18next";

function Tag({ tag }) {
  const { t } = useTranslation();
  const localizedTag = t(tag);
  return <div className="tag">{`• ${localizedTag}`}</div>;
}

function TagCategory({ tags }) {
  return (
    <div className="category">
      <div className="tags">
        {tags.map((key) => (
          <Tag tag={key} key={key} />
        ))}
      </div>
    </div>
  );
}

function Tags({ tags }) {
  return (
    <div className="categories">
      <TagCategory tags={tags} />
    </div>
  );
}

function LeagueDetails({ event }) {
  const { t } = useTranslation();

  const {
    state: { application },
  } = useContext(EnvironmentContext);

  if (application === Applications.RetailLocator && event.tags.length === 0) {
    return null;
  }

  const header =
    application === Applications.EventLocator
      ? t("DESCRIPTION")
      : t("RETAIL_OPTIONS");

  const prompt =
    application === Applications.EventLocator
      ? t("WHAT_IS_A_LEAGUE")
      : t("RETAIL_OPTIONS_BLURB");

  return (
    <div className="league-details">
      <div className="section-header">{header}</div>
      <div className="blurb">{prompt}</div>
      <Tags tags={event.tags} />
    </div>
  );
}

LeagueDetails.propTypes = exact({
  event: EVENT.isRequired,
});

export default LeagueDetails;
