const flatify = (arr, digit = Infinity) =>
  digit > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatify(val, digit - 1) : val),
        []
      )
    : arr.slice();

export { flatify };
