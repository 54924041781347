import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
// components
import NoEventNotifier from "components/EventList/NoEventNotifier";
import Footer from "components/Footer";
import FetchFailedNotifier from "components/EventList/FetchFailedNotifier";
import EventFetchPlaceholder from "components/EventList/EventFetchPlaceholder";
import LocationNotifier from "components/EventList/LocationNotifier";
// contexts
import { FiltersContext } from "contexts/FiltersContext";
import { SettingsContext } from "contexts/SettingsContext";
import { EventsContext, EventsConstants } from "contexts/EventsContext";
import "compiled/css/eventList.css";
import { LocalizationContext } from "contexts/LocalizationContext";
// helpers
import { convertDistance } from "contexts/helpers/converters";
import { PrimaryContent } from "./PrimaryContent";

function EventList() {
  const {
    state: { eventsFetchStatus },
  } = useContext(EventsContext);
  const {
    updateMaxDistance,
    state: { filteredIds, maxDistance },
  } = useContext(FiltersContext);
  const {
    state: { hideFooter },
  } = useContext(SettingsContext);
  const { getCurrentDistanceUnits } = useContext(LocalizationContext);
  const { unit } = getCurrentDistanceUnits();

  const {
    FetchStatuses: { NotStarted, Complete, Failed, InProgress },
  } = EventsConstants;

  const location = useLocation();

  // this was put here to circumvent edge case distance values that are typed into the url
  useEffect(() => {
    const convertedDistance = convertDistance(maxDistance, unit);

    if (maxDistance !== convertedDistance) {
      updateMaxDistance(convertedDistance);
    }
  }, [maxDistance, updateMaxDistance, unit]);

  const notStarted =
    eventsFetchStatus === NotStarted && !location.search.includes("near=");
  const isComplete = eventsFetchStatus === Complete;
  const hasResults = filteredIds.length > 0;
  const isFailed = eventsFetchStatus === Failed;
  const isInProgress = eventsFetchStatus === InProgress;

  return (
    <div
      id="event-list"
      data-testid="event-list"
      className={
        notStarted || (isComplete && !hasResults) || isInProgress ? "empty" : ""
      }
    >
      <div>
        {notStarted ? <LocationNotifier /> : null}
        {isInProgress ? <EventFetchPlaceholder /> : null}
        {isComplete && hasResults ? <PrimaryContent /> : null}
        {isComplete && !hasResults ? <NoEventNotifier /> : null}
        {isFailed ? <FetchFailedNotifier /> : null}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
}

export default EventList;
