import React, { forwardRef, useContext } from "react";
// contexts
import { useWindowSize, useTooltip } from "hooks";
import { EventsContext } from "contexts/EventsContext";
import {
  LocalizationContext,
  MapLanguageByLocale,
} from "contexts/LocalizationContext";
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";
import { UrlContext, PageByType } from "contexts/UrlContext";
import { SettingsContext } from "contexts/SettingsContext";
import Tags from "./Tag";
// external imports
import { ImgByTag } from "components/constants/icon";
import ReactTooltip from "react-tooltip";
import exact from "prop-types-exact";
import PropTypes from "prop-types";
import { EVENT } from "../../utils/propTypes";
// styles
import "compiled/css/eventCard.css";
import { dateTimeFormat } from "components/constants";
import { DateService } from "utils/date-service";
import { EventCardAddress } from "./EventCardAddress";
import { useTranslation } from "react-i18next";

function When({ when }) {
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const {
    state: { currentLocale },
  } = useContext(LocalizationContext);
  if (application !== Applications.EventLocator) {
    return null;
  }
  const locale = MapLanguageByLocale[currentLocale];
  DateService.locale(locale);
  const dateTime = new DateService()
    .setDate({ date: when, format: "utc" })
    .toFormat(dateTimeFormat);

  return (
    <div data-testid="when" className="when">
      {dateTime}
    </div>
  );
}

function ActivityCount({ numActivities }) {
  const { t } = useTranslation();
  const eventText = numActivities > 1 ? t("EVENTS") : t("EVENT");
  const displayText = `${numActivities} ${eventText}`;
  return (
    numActivities > 0 && (
      <div className="event-pillbox" data-testid="event-count">
        {displayText}
      </div>
    )
  );
}

function DateAndDistance({ distance, when }) {
  const { getCurrentDistanceUnits } = useContext(LocalizationContext);
  const { unit, unitsPerMile } = getCurrentDistanceUnits();
  return (
    <div className="date-and-distance">
      <When when={when} />
      <div data-testid="distance" className="distance">
        {Math.floor(distance * unitsPerMile)} {unit}
      </div>
    </div>
  );
}

const EventCard = forwardRef(({ eventId, mini, childEvent }, ref) => {
  // add childEventsById to the state destructure below to access the child events
  const {
    state: { searchableEventsById, childEventsById },
  } = useContext(EventsContext);
  const { seeListView, pushTo, getSelectedEvent } = useContext(UrlContext);
  const { tabBlock } = useContext(SettingsContext);

  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const focusedEvent = getSelectedEvent();
  const windowSize = useWindowSize();

  const smallScreen = windowSize.width <= 1000;
  const cardTabIndex = tabBlock || focusedEvent ? -1 : 0;
  const numChildEvents = childEventsById?.[eventId]?.children.length || 0;
  const event = childEvent || searchableEventsById[eventId] || { address: {} };
  const isChildEvent = !!childEvent;
  const isRetail = event.retail_info || event.retail_information;

  const { name, activity_type, distance, retail_info, when, tags, address } =
    event;

  let displayName = name;
  if (
    application === Applications.RetailLocator &&
    !!(retail_info || {}).name
  ) {
    displayName = retail_info.name;
  }

  const { isToolTipDisplayed, textDisplayEl } = useTooltip({
    mini,
    smallScreen,
  });

  // Generate event logo string
  const targetTags = Object.keys(ImgByTag);
  const matchingTag = tags?.filter((tag) => targetTags.includes(tag));
  const logo = `/images/${ImgByTag[matchingTag?.[0]]}.png`;
  const isTaggedEvent = matchingTag?.length > 0 && !isChildEvent;

  const goToEventDetail = ({ key } = {}) => {
    if (key && key !== "Enter") {
      return;
    }
    if (mini) {
      return null;
    }
    seeListView();

    let pushToObj = null;
    if (event?.isLargeEvent && !isChildEvent) {
      pushToObj = {
        page: `group/${event.large_event_guid}`,
        search: null,
      };
    } else {
      const type =
        application === Applications.RetailLocator
          ? "retail"
          : PageByType[activity_type];

      pushToObj = {
        page: `${type}/${childEvent?.guid || eventId}`,
        search: null,
      };
    }

    pushTo(pushToObj);
  };

  const renderTournamentTag = () => {
    if (isRetail) return;
    let className = "logo-holder add-shadow";
    if (
      matchingTag?.[0] === "regionals" ||
      matchingTag?.[0] === "international" ||
      matchingTag?.[0] === "prerelease"
    ) {
      className += " newer-logo";
    }
    return (
      isTaggedEvent && (
        <div className={className}>
          <img data-testid="event-logo" src={logo} alt={matchingTag} />
        </div>
      )
    );
  };

  const showTag = isTaggedEvent && !isRetail;
  const cardClass = `event-card${mini || isChildEvent ? " mini" : ""}${
    application === Applications.EventLocator ? "" : " non-event"
  } ${application === Applications.EventLocator ? activity_type : "retail"}
  ${showTag ? " tagged-event tagged-event-card" : " add-shadow"}`;

  return (
    <div className={`card-holder ${showTag ? "card-holder-grid" : ""}`}>
      <div
        ref={ref}
        className={cardClass}
        onClick={goToEventDetail}
        onKeyPress={goToEventDetail}
        tabIndex={cardTabIndex}
      >
        <DateAndDistance distance={distance} when={when} />
        <div className="mobile-container">
          <div className="mobile-event-info">
            <ActivityCount numActivities={numChildEvents} />
            <div>
              <div className="name-tag-holder">
                <div
                  data-testid="event-name"
                  className="event-header"
                  ref={textDisplayEl}
                  data-tip={isToolTipDisplayed ? displayName : ""}
                  style={{
                    overflow:
                      !smallScreen && !isToolTipDisplayed
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {displayName}
                </div>
                <ReactTooltip place="right" />
              </div>
              <EventCardAddress
                addressData={address}
                isChildEvent={isChildEvent}
                isRetail={isRetail}
                mini={mini}
              />
              {smallScreen ? (
                <Tags eventId={eventId} childEvent={childEvent} />
              ) : null}
            </div>
          </div>
          {!smallScreen && <Tags eventId={eventId} childEvent={childEvent} />}
        </div>
      </div>
      {renderTournamentTag()}
    </div>
  );
});

EventCard.propTypes = exact({
  eventId: PropTypes.string,
  mini: PropTypes.bool,
  childEvent: EVENT,
});

export default EventCard;
