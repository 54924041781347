// node modules
import React, { useContext, useEffect, useRef } from "react";
import { useWindowSize } from "hooks";
import { Map, Marker } from "google-maps-react";
import { withRouter, useHistory } from "react-router-dom";

// external imports
import { getPins, mapSettings } from "utils/mapHelpers";
import { EventsContext } from "contexts/EventsContext";
import { FiltersContext } from "contexts/FiltersContext";
import { LocalizationContext } from "contexts/LocalizationContext";
import { SettingsContext } from "contexts/SettingsContext";
import { SkinContext } from "contexts/SkinContext";
import { UrlContext } from "contexts/UrlContext";
import "compiled/css/mapContainer.css";

function MapContainer() {
  // The map will resize based off of window size. Let's make a hook about that.
  const windowSize = useWindowSize();
  const smallScreen = windowSize.width <= 1000;
  const { getSelectedEvent, pushTo } = useContext(UrlContext);
  const {
    state: { mapPinImage },
  } = useContext(SkinContext);

  const { location } = useHistory();
  const isSearchView = location.pathname.includes("events");

  // Sadly we can't pull this ID out cleanly with params.
  const focusedEventId = getSelectedEvent();

  const {
    state: { searchableEventsById, eventSearchLocation, childEventsById },
  } = useContext(EventsContext);
  const {
    state: { google },
  } = useContext(LocalizationContext);

  const {
    state: { filteredIds, filterStatus },
  } = useContext(FiltersContext);
  const {
    state: { showMap },
    toggleMap,
  } = useContext(SettingsContext);
  // Now we load all the events that are showing up in the event list.

  const events = filteredIds
    .map((id) => searchableEventsById[id])
    .filter((x) => !!x);

  const { lat, lng } = eventSearchLocation || { lat: 0, lng: 0 };
  const currFocusedEvent = searchableEventsById[focusedEventId];

  const noEventsResults = events.length === 0;

  const map = useRef(null);

  const resizeMap = () => {
    if (!eventSearchLocation && noEventsResults) {
      return;
    }
    if (!google) {
      return;
    }
    if (!smallScreen && !showMap) {
      toggleMap(true);
    }

    const boundsFocusObj = isSearchView
      ? eventSearchLocation
      : currFocusedEvent;
    const bounds = new google.maps.LatLngBounds(boundsFocusObj);

    if (!!eventSearchLocation && noEventsResults) {
      const { lat, lng } = eventSearchLocation;
      bounds.extend({ lat: lat + 0.2, lng: lng + 0.2 });
      bounds.extend({ lat: lat - 0.2, lng: lng - 0.2 });
    }
    if (currFocusedEvent) {
      const { lat, lng } = currFocusedEvent;
      bounds.extend({ lat, lng });
    } else {
      events.map(({ lat, lng }) =>
        // lat/lng NaN for dev event
        bounds.extend({ lat: lat || 0, lng: lng || 0 })
      );
    }
    map.current.map.setRestriction({
      latLngBounds: { north: 80, south: -80, west: -180, east: 180 },
    });

    map.current.map.fitBounds(bounds, 0); // eslint-disable-next-line
  };

  useEffect(() => {
    setTimeout(resizeMap, 500); // eslint-disable-next-line
  }, [showMap]);

  // EFFECT: Re-center and re-size the map every time something important changes
  useEffect(resizeMap, [
    eventSearchLocation,
    map,
    events,
    smallScreen,
    filterStatus,
    currFocusedEvent,
    google,
    isSearchView,
    noEventsResults,
    showMap,
    toggleMap,
  ]);

  const goToEventDetail = ({ page }) => {
    pushTo({
      page,
      search: null,
    });
  };

  const mapClass = showMap ? "show" : "hidden";
  if (!google) {
    return null;
  }

  const renderPins = () =>
    getPins({
      events,
      google,
      currFocusedEvent,
      childEventsById,
      focusedEventId,
      goToEventDetail,
      mapPinImage,
    });

  return (
    <div id="map-container" data-testid="map-container" className={mapClass}>
      <div className="map-holder">
        <Map {...mapSettings({ map, google })}>
          <Marker visible={!!eventSearchLocation} position={{ lat, lng }} />
          {renderPins()}
        </Map>
      </div>
    </div>
  );
}

export default withRouter(MapContainer);
