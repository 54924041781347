import React, { useEffect, useReducer, useContext, useMemo } from "react";
import { LocalizationContext } from "contexts/LocalizationContext";
import exact from "prop-types-exact";
import PropTypes from "prop-types";
const SkinContext = React.createContext();

const Actions = {
  SetSkin: "SET_SKIN",
};

const SkinComponents = {
  WelcomeImage: "welcomeImage",
  WelcomeImageAlt: "welcomeImageAlt",
  ErrorImage: "errorImage",
  ErrorImageAlt: "errorImageAlt",
  MapPinImage: "mapPinImage",
};

const orderedSkinComponents = [
  SkinComponents.WelcomeImage,
  SkinComponents.WelcomeImageAlt,
  SkinComponents.ErrorImage,
  SkinComponents.ErrorImageAlt,
  SkinComponents.MapPinImage,
];

const initialSkinState = {
  [SkinComponents.WelcomeImage]: "/pikachu.svg",
  [SkinComponents.WelcomeImageAlt]: "Pikachu",
  [SkinComponents.MapPinImage]: "/pin.svg",
  [SkinComponents.ErrorImage]: "/psyduck.svg",
  [SkinComponents.ErrorImageAlt]: "psyduck",
  shouldApply: () => true,
};

const bidoofSkinState = {
  [SkinComponents.WelcomeImage]: "/skins/bidoof/welcome.svg",
  [SkinComponents.WelcomeImageAlt]: "Bidoof",
  [SkinComponents.MapPinImage]: "/skins/bidoof/pin.svg",
  [SkinComponents.ErrorImage]: "/skins/bidoof/error.svg",
  [SkinComponents.ErrorImageAlt]: "ɟoopᴉq",
  localized: {
    [SkinComponents.WelcomeImage]: (locale) =>
      `/skins/bidoof/welcome_${locale}.svg`,
  },
  shouldApply: () => {
    const bidoofMonth = 6; // July
    const bidoofDay = 1; // first
    const today = new Date();
    const rightMonth = today.getMonth() === bidoofMonth;
    const rightDay = today.getDate() === bidoofDay;
    return rightMonth && rightDay;
  },
};

const skinPreference = [bidoofSkinState, initialSkinState];

const SkinReducer = (state, { type, value }) => {
  switch (type) {
    case Actions.SetSkin:
      return {
        ...state,
        ...value,
      };
    default:
      return { ...state };
  }
};

function SkinProvider({ children, defaultValues }) {
  const {
    state: { currentLocale },
  } = useContext(LocalizationContext);
  const defaultState = defaultValues || {};
  const [state, dispatch] = useReducer(SkinReducer, {
    ...initialSkinState,
    ...defaultState,
  });

  const applySkin = (skin) => {
    const appliedSkin = {};
    orderedSkinComponents.forEach((component) => {
      if (skin.localized && skin.localized[component] && currentLocale) {
        appliedSkin[component] = skin.localized[component](currentLocale);
      } else if (skin[component]) {
        appliedSkin[component] = skin[component];
      }
    });
    dispatch({ type: Actions.SetSkin, value: appliedSkin });
  };

  useEffect(() => {
    const bestSkin = skinPreference.find((skin) => skin.shouldApply());
    applySkin(bestSkin);
    /* eslint-disable-next-line */
  }, [currentLocale]);

  useEffect(() => {
    window.skinApp = (skin) => {
      if (skin === "bidoof") {
        applySkin(bidoofSkinState);
        return "Bidoof! Bidoof!";
      }
    }; // eslint-disable-next-line
  }, []);

  const contextValue = useMemo(
    () => ({
      state,
    }),
    [state]
  );

  return (
    <SkinContext.Provider value={contextValue}>{children}</SkinContext.Provider>
  );
}

SkinContext.Provider.propTypes = exact({
  value: PropTypes.exact({
    state: PropTypes.exact({
      [SkinComponents.WelcomeImage]: PropTypes.string.isRequired,
      [SkinComponents.WelcomeImageAlt]: PropTypes.string.isRequired,
      [SkinComponents.MapPinImage]: PropTypes.string.isRequired,
      [SkinComponents.ErrorImage]: PropTypes.string.isRequired,
      [SkinComponents.ErrorImageAlt]: PropTypes.string.isRequired,
      shouldApply: PropTypes.func.isRequired,
    }),
  }),
  children: PropTypes.node,
});

export { SkinContext, SkinProvider, SkinComponents };
