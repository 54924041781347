import React, { useContext } from "react";
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";
import { SettingsContext } from "contexts/SettingsContext";
import Spinner from "components/Controls/Spinner";

import exact from "prop-types-exact";
import { EVENT } from "../../utils/propTypes";
import { useTranslation } from "react-i18next";

function Name({ name }) {
  const { t } = useTranslation();
  if (!name) {
    return null;
  }
  return (
    <div className="data-row">
      <div className="data-label">{t("NAME")}</div>
      <div className="data-value" data-testid="owner-name">
        {name}
      </div>
    </div>
  );
}

function Phone({ phone }) {
  const { t } = useTranslation();
  if (!phone) {
    return null;
  }
  return (
    <div className="data-row">
      <div className="data-label">{t("PHONE")}</div>
      <a href={`tel:${phone}`} className="data-value" data-testid="owner-phone">
        {phone}
      </a>
    </div>
  );
}

function Email({ email }) {
  const { t } = useTranslation();
  if (!email) {
    return null;
  }
  return (
    <div className="data-row">
      <div className="data-label">{t("EMAIL")}</div>
      <a
        href={`mailto:${email}`}
        className="data-value"
        data-testid="owner-email"
      >
        {email}
      </a>
    </div>
  );
}

function Website({ website }) {
  const { t } = useTranslation();
  const { intendToLeave } = useContext(SettingsContext);
  if (!website) {
    return null;
  }
  return (
    <div className="data-row">
      <div className="data-label">{t("RETAIL_WEBSITE")}</div>
      <div
        className="fake-link data-value"
        onClick={() => {
          intendToLeave(website);
        }}
        data-testid="owner-website"
      >
        {website}
      </div>
    </div>
  );
}

function Header() {
  const { t } = useTranslation();
  return (
    <div className="section-header">{t("LEAGUE_CONTACT_INFORMATION")}</div>
  );
}

function Owner({ event }) {
  const {
    state: { application },
  } = useContext(EnvironmentContext);

  const { t } = useTranslation();

  const isLeagueEvent = event.activity_type === "league";
  let { phone, email, website } = event.retail_info || {};
  let name = null;

  if (application === Applications.EventLocator) {
    const contact_info = {
      ...(event.contact_information || {}),
      ...(event.contact_info || {}),
    };
    name = contact_info.owner_display;
    email = contact_info.email || contact_info.contact_email;
    phone = contact_info.phone_number || contact_info.contact_phone;
    // eslint-disable-next-line prefer-destructuring
    website = contact_info.website;

    if (event.fetchFailed) {
      return (
        <div className="error-message">{t("EVENT_DETAIL_FETCH_ERROR")}</div>
      );
    } else if (!event.complete && !event.isLargeEvent) {
      return <Spinner />;
    }
  }

  return (
    <div className="owner">
      {isLeagueEvent ? <Header /> : null}
      {!isLeagueEvent && <Name name={name} />}
      <Phone phone={phone} />
      <Email email={email} />
      <Website website={website} />
    </div>
  );
}

Owner.propTypes = exact({
  event: EVENT.isRequired,
});

export default Owner;
