import React, { useContext } from "react";

import "compiled/css/confirmModal.css";
import { SettingsContext } from "contexts/SettingsContext";

import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { CONFIRM_TEXT } from "utils/propTypes";

function ConfirmationModal({
  headerLabel = "Confirm",
  confirmText = "Confirm",
  confirmButtonLabel = "Confirm",
  confirmClick,
  cancelButtonLabel,
}) {
  const { setModal } = useContext(SettingsContext);

  const eatClick = (e) => {
    e.stopPropagation();
  };

  const handleConfirmClick = () =>
    confirmClick ? confirmClick() : setModal(null);
  const handleCancelClick = () => {
    setModal(null);
  };

  const renderConfirmText = () =>
    Array.isArray(confirmText)
      ? React.Children.toArray(confirmText)
      : confirmText;

  return (
    <div
      id="confirm-modal"
      className="modal"
      data-testid="confirm_modal"
      onClick={eatClick}
    >
      <div className="content">
        <div className="confirm-content">
          <div className="confirm-header" data-testid="confirm_header_label">
            {headerLabel}
          </div>
          <div className="confirm-text" data-testid="confirm_text">
            {renderConfirmText()}
          </div>
          <div className="footer">
            {cancelButtonLabel ? (
              <div onClick={handleCancelClick} className="cancel-button">
                {cancelButtonLabel}
              </div>
            ) : null}
            <div
              onClick={handleConfirmClick}
              className="confirm-button confirm"
            >
              {confirmButtonLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationModal.propTypes = exact({
  headerLabel: PropTypes.string,
  confirmText: CONFIRM_TEXT,
  confirmButtonLabel: PropTypes.string,
  confirmClick: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
});

export default ConfirmationModal;
