import "compiled/css/infoNotifier.css";
import { Applications, EnvironmentContext } from "contexts/EnvironmentContext";
import { SkinContext } from "contexts/SkinContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

function NoEventNotifier() {
  const { t } = useTranslation();
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const {
    state: { errorImage, errorImageAlt },
  } = useContext(SkinContext);

  const header =
    application === Applications.EventLocator
      ? t("NO_EVENTS_HEADER")
      : t("NO_RETAILS_FOUND_HEADER");

  const prompt =
    application === Applications.EventLocator
      ? t("NO_EVENTS_SUGGESTION")
      : t("NO_RETAILS_FOUND_BLURB");

  return (
    <div>
      <div id="info-notifier" data-testid="info-notifier">
        <img
          className="icon"
          src="/icons/event-location.svg"
          alt="event location"
        />
        <div className="title-and-body">
          <div className="title">{header}</div>

          <div className="body">
            <div className="content">{prompt}</div>
            <div className="art-row">
              <div className="art-holder">
                <img src={errorImage} alt={errorImageAlt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoEventNotifier;
