import React, {
  useReducer,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { flatify } from "utils/badBrowserHelpers";
import { EventsContext } from "contexts/EventsContext";
import { EnvironmentContext } from "contexts/EnvironmentContext";
import {
  DistanceUnitByCode,
  LocalizationContext,
} from "contexts/LocalizationContext";
import { UrlContext } from "contexts/UrlContext";
import { useLocation } from "react-router-dom";
import PropTypes, { bool } from "prop-types";
import exact from "prop-types-exact";
import { filterEvents } from "./helpers/filters";
import { DateService } from "../utils/date-service";

const FiltersContext = React.createContext();

const initialMaxDate = new DateService()
  .setDate({
    format: "utc",
    date: DateService.nowISO(),
  })
  .addTime({ duration: 2, unit: "months" });

const maxMaxDate = new DateService()
  .setDate({
    format: "utc",
    date: DateService.nowISO(),
  })
  .addTime({ duration: 1, unit: "years" });

const FiltersConstants = {
  initialMaxDistance: 0,
  initialKm: 6.21371,
  initialMi: 10,
  initialEventTypeFilters: {},
  maxMaxDistance: 0,
  maxMaxDate,
  Actions: {
    ToggleFilter: "TOGGLE_FILTER",
    ToggleChampionshipView: "TOGGLE_CHAMPIONSHIP_VIEW",
    SetFilteredEvents: "SET_FILTERED_EVENTS",
    setSortAttr: "SET_SORT_ATTR",
    setMaxDistance: "SET_MAX_DISTANCE",
    SetStarterFilters: "SET_STARTER_FILTERS",
    setMaxDate: "SET_MAX_DATE",
    ResetActiveFilters: "RESET_ACTIVE_FILTERS",
    ResetEventTypeFilters: "RESET_EVENT_TYPE_FILTERS",
  },
  SortAttrLabels: {
    distance: "DISTANCE",
    when: "DATE",
  },
  Filters: {
    tcg: {
      group: "products",
      label: "CARDS_LABEL",
    },
    vg: {
      group: "products",
      label: "VG_LABEL",
    },
    pgo: {
      group: "products",
      label: "PGO_LABEL",
    },
    tournament: {
      group: "activity_type",
      label: "TOURNAMENT_LABEL",
    },
    league: {
      group: "activity_type",
      label: "LEAGUE_EVENTS_LABEL",
    },
    regionals: {
      group: "activity_type",
      label: "REGIONAL_CHAMPIONSHIP_LABEL",
    },
    international_championship: {
      group: "activity_type",
      label: "INTERNATIONAL_CHAMPIONSHIP_LABEL",
    },
    worlds: {
      group: "activity_type",
      label: "WORLD_CHAMPIONSHIP_LABEL",
    },
    prerelease: {
      group: "activity_type",
      label: "PRERELEASE_LABEL",
    },
    league_challenge: {
      group: "activity_type",
      label: "LEAGUE_CHALLENGE_LABEL",
    },
    league_cup: {
      group: "activity_type",
      label: "LEAGUE_CUP_LABEL",
    },
    premier_challenge: {
      group: "activity_type",
      label: "PREMIER_CHALLENGE_LABEL",
    },
    midseason_showdown: {
      group: "activity_type",
      label: "MIDSEASON_SHOWDOWN_LABEL",
    },
    special_event: {
      group: "activity_type",
      label: "SPECIAL_EVENT",
    },
  },
  FilterGroups: {
    products: {
      label: "PRODUCTS",
      tags: ["tcg", "vg", "pgo"],
    },
    activity_type: {
      label: "EVENT_TYPES",
      tags: ["tournament", "league"], // , "international_championship"]
    },
    tags: {
      tags: [
        "international_championship",
        "regionals",
        "worlds",
        "prerelease",
        "league_challenge",
        "premier_challenge",
        "midseason_showdown",
        "league_cup",
      ],
    },
  },
  PesFilterTags: [
    "international_championship",
    "regionals",
    "worlds",
    "prerelease",
    "league_challenge",
    "premier_challenge",
    "midseason_showdown",
    "league_cup",
  ],
  FiltersSolo: {
    championships: {
      label: "PREMIER_CHAMPIONSHIPS",
      tag: "championships",
    },
  },
  ChampionshipTags: [
    "regionals",
    "international_championship",
    "worlds",
    "special_event",
  ],
  FilterGroupOrder: ["activity_type"],
  EventsFilterGroupOrder: [
    { key: "products", type: "array" },
    { key: "activity_type", type: "string" },
    { key: "tags", type: "array" },
  ],
  VgFilters: {
    premier_challenge: true,
    midseason_showdown: true,
  },
  TcgFilters: {
    prerelease: true,
    league_challenge: true,
    league_cup: true,
  },
  ProductFilters: {
    vg: true,
    tcg: true,
  },
  SubEventFilters: {
    prerelease: true,
    league_challenge: true,
    league_cup: true,
    premier_challenge: true,
    midseason_showdown: true,
    regionals: true,
    international_championship: true,
    worlds: true,
  },
};

const { Actions, initialMaxDistance, initialMi, initialKm } = FiltersConstants;

const initialFiltersState = {
  activeFilters: {},
  eventTypeFilters: {
    prerelease: false,
    league_challenge: false,
    league_cup: false,
    premier_challenge: false,
    midseason_showdown: false,
    regionals: false,
    international_championship: false,
    worlds: false,
    league: true,
    tournament: true,
  },
  filteredIds: [],
  maxDate: initialMaxDate,
  maxDistance: initialMaxDistance,
  isChampionshipView: false,
  sortAttr: "distance",
};

const FiltersReducer = (state, { type, value }) => {
  switch (type) {
    case Actions.ToggleFilter:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [value]: !state.activeFilters[value],
        },
      };
    case Actions.ToggleChampionshipView:
      return {
        ...state,
        isChampionshipView: !state.isChampionshipView,
      };
    case Actions.SetFilteredEvents:
      return {
        ...state,
        filteredIds: value,
      };
    case Actions.SelectEvent:
      return {
        ...state,
        SelectEvent: value,
      };
    case Actions.setSortAttr:
      return {
        ...state,
        sortAttr: value,
      };
    case Actions.setMaxDistance:
      return {
        ...state,
        maxDistance: value,
        maxDistanceSet: true,
      };
    case Actions.setMaxDate:
      return {
        ...state,
        maxDate: value,
      };
    case Actions.SetStarterFilters:
      return {
        ...state,
        ...value,
      };
    case Actions.ResetActiveFilters:
      return {
        ...state,
        activeFilters: initialFiltersState.activeFilters,
        maxDistance: value === "km" ? initialKm : initialMi,
        maxDate: initialMaxDate,
      };
    case Actions.ResetEventTypeFilters:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...initialFiltersState.eventTypeFilters,
        },
      };
    default:
      return { ...state };
  }
};

function FiltersProvider({ children }) {
  const [state, dispatch] = useReducer(FiltersReducer, initialFiltersState);
  const { getCurrentDistanceUnits } = useContext(LocalizationContext);
  const { unitsPerMile } = getCurrentDistanceUnits();
  const { getSelectedEvent } = useContext(UrlContext);
  const location = useLocation();

  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const {
    state: { eventIds, searchableEventsById, eventSearchLocation },
  } = useContext(EventsContext);

  const { replaceTo, page } = useContext(UrlContext);
  const {
    activeFilters,
    sortAttr,
    maxDistance,
    maxDate,
    maxDistanceSet,
    isChampionshipView,
  } = state;

  const focusedEvent = getSelectedEvent();
  const getCurrentUnit = () => {
    const [, newCode] = location.pathname.split("/");
    const unit = DistanceUnitByCode[newCode] || "mi";
    return unit;
  };
  const unit = getCurrentUnit();

  useEffect(() => {
    dispatch({
      type: Actions.SetStarterFilters,
      value: {
        maxDistance:
          unit === "km"
            ? FiltersConstants.initialKm
            : FiltersConstants.initialMi,
      },
    });
  }, [unit]);

  useEffect(
    () => {
      const splitLocation = location.search.split("&");
      const starterFilters = {};

      splitLocation.map((section) => {
        const [baseKey, baseValues] = section.split("=");
        const key = baseKey.replace("?", "");
        const hasComma = (baseValues || "").includes(",");
        const values = hasComma ? baseValues.split(",") : baseValues;
        if (key && values) {
          starterFilters[key] = values;
        }
        if (starterFilters.filters) {
          starterFilters.activeFilters = {};
          flatify([starterFilters.filters] || []).map((key) => {
            starterFilters.activeFilters[key] = true;
            return null;
          });
          delete starterFilters.filters;
        }

        if (starterFilters.maxDistance) {
          starterFilters.maxDistance = Number(starterFilters.maxDistance);
        }
        if (starterFilters.maxDate) {
          try {
            const date = new Date(starterFilters.maxDate);
            const maxDate = new DateService().setDate({
              format: "js",
              date,
            });
            starterFilters.maxDate = maxDate;
          } catch (err) {
            console.error(err);
          }
        }
        return null;
      });

      dispatch({ type: Actions.SetStarterFilters, value: starterFilters });
    }, // eslint-disable-next-line
    []
  );

  const updateUrlParams = useCallback(
    (newParams) => {
      const urlSearchParams = new URLSearchParams(location.search);
      const currParams = Object.fromEntries(urlSearchParams.entries());

      replaceTo({
        page,
        search: {
          ...currParams,
          ...newParams,
        },
      });
    },
    [location.search, page, replaceTo]
  );

  useEffect(() => {
    // prevent filtering for niantic link page
    if (page === "nianticlink") return;

    filterEvents({
      page,
      application,
      focusedEvent,
      dispatch,
      activeFilters,
      eventSearchLocation,
      eventIds,
      searchableEventsById,
      sortAttr,
      unitsPerMile,
      maxDistance,
      maxDate,
      maxDistanceSet,
      location,
      isChampionshipView,
      updateUrlParams,
    }); // eslint-disable-next-line
  }, [
    activeFilters,
    application,
    focusedEvent,
    unitsPerMile,
    eventIds,
    searchableEventsById,
    sortAttr,
    unitsPerMile,
    page,
    maxDistance,
    maxDate,
  ]);

  const toggleFilter = (filter) => {
    dispatch({ type: Actions.ToggleFilter, value: filter });
  };

  const resetActiveFilters = (distanceUnit) =>
    dispatch({ type: Actions.ResetActiveFilters, value: distanceUnit });

  const resetEventTypeFilters = () =>
    dispatch({ type: Actions.ResetEventTypeFilters });

  const contextValue = useMemo(() => {
    const updateMaxDistance = (distance) => {
      updateUrlParams({
        maxDistance: distance,
      });
      dispatch({ type: Actions.setMaxDistance, value: distance });
    };

    const updateMaxDate = (when) => {
      updateUrlParams({
        maxDate: when.toFormat("MM/dd/yyyy"),
      });
      dispatch({ type: Actions.setMaxDate, value: when });
    };

    const toggleSort = () => {
      const nextAttr = state.sortAttr === "distance" ? "when" : "distance";
      dispatch({ type: Actions.setSortAttr, value: nextAttr });
      replaceTo({ search: { sort: nextAttr } });
    };

    const toggleChampionshipView = (visible) => {
      const replaceObj = {
        page,
        search: {
          isChampionshipView: true,
        },
      };
      if (!visible) {
        replaceObj.search = {};
      }
      replaceTo(replaceObj);
      dispatch({ type: Actions.ToggleChampionshipView });
    };
    return {
      state,
      dispatch,
      toggleSort,
      updateMaxDistance,
      updateMaxDate,
      toggleFilter,
      toggleChampionshipView,
      resetActiveFilters,
      resetEventTypeFilters,
    };
  }, [state, dispatch, replaceTo, page, updateUrlParams]);

  return (
    <FiltersContext.Provider value={contextValue}>
      {children}
    </FiltersContext.Provider>
  );
}

FiltersContext.Provider.propTypes = exact({
  children: PropTypes.node,
  value: PropTypes.exact({
    state: PropTypes.exact({
      activeFilters: PropTypes.shape({
        [PropTypes.oneOf(FiltersConstants.FilterGroups.tags.tags)]: bool,
      }),
      eventTypeFilters: PropTypes.exact({
        international_championship: PropTypes.bool.isRequired,
        league: PropTypes.bool.isRequired,
        league_challenge: PropTypes.bool.isRequired,
        league_cup: PropTypes.bool.isRequired,
        midseason_showdown: PropTypes.bool.isRequired,
        premier_challenge: PropTypes.bool.isRequired,
        prerelease: PropTypes.bool.isRequired,
        regionals: PropTypes.bool.isRequired,
        tournament: PropTypes.bool.isRequired,
        worlds: PropTypes.bool.isRequired,
      }),
      filteredIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      isChampionshipView: PropTypes.bool.isRequired,
      maxDate: PropTypes.instanceOf(DateService),
      maxDistance: PropTypes.number.isRequired,
      maxDistanceSet: PropTypes.bool,
      near: PropTypes.arrayOf(PropTypes.string),
      sortAttr: PropTypes.oneOf(["distance", "when"]),
      code: PropTypes.string,
      scope: PropTypes.string,
      state: PropTypes.string,
    }),
    dispatch: PropTypes.func,
    toggleSort: PropTypes.func,
    updateMaxDistance: PropTypes.func,
    updateMaxDate: PropTypes.func,
    toggleFilter: PropTypes.func,
    toggleChampionshipView: PropTypes.func,
    resetActiveFilters: PropTypes.func,
    resetEventTypeFilters: PropTypes.func,
  }),
});

export {
  FiltersContext,
  FiltersProvider,
  FiltersConstants,
  filterEvents,
  initialMaxDate,
};
