import { DateTime, Settings } from "luxon";

export class DateService {
  constructor(initialDate) {
    this.date = initialDate || DateTime.now();
  }

  static now() {
    return DateTime.now();
  }
  static nowISO() {
    return this.now().toISO();
  }

  #jsDateFormat(date) {
    if (!date) return;
    this.date = DateTime.fromJSDate(date);
  }

  #utcFormat(date) {
    if (!date) return;
    this.date = DateTime.fromISO(date, { zone: "utc" });
  }

  static locale(locale) {
    Settings.defaultLocale = locale;
  }

  toFormat(format) {
    if (!format) return;
    return this.getDate().toFormat(format);
  }

  addTime({ duration, unit }) {
    this.date = this.date.plus({ [unit]: duration });

    return this;
  }

  getDate() {
    return this.date;
  }

  /**
   * @param {{format: 'js'|'utc', date: string}}
   */
  setDate({ format, date }) {
    const action = {
      utc: () => this.#utcFormat(date),
      js: () => this.#jsDateFormat(date),
    };

    action[format]?.();

    return this;
  }
}
