import {
  unitOptions,
  unitMap,
  miToKmMap,
} from "../../components/HeaderBar/filters-dropdown/distance-dropdown/constants";

export const convertDistance = (distance, unit) => {
  // check if value is in range based on unit
  const allowedDistances = unitOptions[unit];
  const convertedDistance = (unit === "km" && miToKmMap[distance]) || distance;

  if (
    typeof convertedDistance === "undefined" ||
    convertedDistance < allowedDistances[0] ||
    convertedDistance > allowedDistances[allowedDistances.length - 1]
  ) {
    return unit === "mi" ? 50 : 100;
  }

  const nearestAllowedValue = findNearestAllowedValue(
    convertedDistance,
    allowedDistances
  );
  const distanceMap = unitMap[unit];

  if (unit === "km") {
    return distanceMap[nearestAllowedValue];
  }

  return nearestAllowedValue;
};

// distance will always be within our unitOptions
function findNearestAllowedValue(distance, allowedDistances) {
  for (let i = 0; i < allowedDistances.length; i++) {
    const value = allowedDistances[i];

    if (distance <= value) {
      const prevValue = allowedDistances[i - 1];
      const diff1 = distance - prevValue;
      const diff2 = value - distance;
      return diff1 < diff2 ? prevValue : value;
    }
  }
}
