import React from "react";
import "compiled/css/slider.css";
import { flatify } from "utils/badBrowserHelpers";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { REACT_ROUTER } from "utils/propTypes";

function Slide({ children, slideWidth }) {
  return (
    <div className="slide" style={{ width: slideWidth }}>
      {children}
    </div>
  );
}

function Slider({ children, selectedSlide, className, height }) {
  const sliderClass = className ? `slider ${className}` : "slider";
  const slideWidth = `${100 / children.length}%`;
  const slidesWidth = `${100 * children.length}%`;

  const newKids = flatify([children]).map((child) => (
    <Slide key={child.key} slideWidth={slideWidth}>
      {child}
    </Slide>
  ));

  const selectedIndex = newKids.findIndex((x) => x.key === selectedSlide) || 0;
  const offset = `${-100 * selectedIndex}%`;

  return (
    <div className={sliderClass} style={{ height }}>
      <div
        className="slides"
        data-testid="slider"
        style={{ width: slidesWidth, marginLeft: offset }}
      >
        {newKids}
      </div>
    </div>
  );
}

Slider.propTypes = exact({
  children: PropTypes.node,
  selectedSlide: PropTypes.oneOf(["detail", "list"]).isRequired,
  className: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  ...REACT_ROUTER,
});

export default withRouter(Slider);
