import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { FiltersConstants } from "../contexts/FiltersContext";

const { FilterGroups } = FiltersConstants;

export const REACT_ROUTER = exact({
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  staticContext: PropTypes.object,
});

export const DIRECTION = PropTypes.exact({
  south: PropTypes.number,
  west: PropTypes.number,
  north: PropTypes.number,
  east: PropTypes.number,
  wb: PropTypes.object,
  Sa: PropTypes.object,
  zb: PropTypes.object,
  Ua: PropTypes.object,
});

export const CONFIRM_TEXT = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.node),
]);

export const MODAL_SECTIONS = PropTypes.arrayOf(
  PropTypes.exact({
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })
);

export const STAGE = PropTypes.exact({
  buttonLabel: PropTypes.string.isRequired,
  custom: PropTypes.exact({
    component: PropTypes.func.isRequired,
    props: PropTypes.exact({
      type: PropTypes.string.isRequired,
      logoutText: PropTypes.string.isRequired,
    }),
  }),
  renderDelink: PropTypes.bool,
  delinkComponent: PropTypes.func,
  details: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  elUrl: PropTypes.string,
  handleStageClick: PropTypes.func,
  imageSrc: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  url: PropTypes.string,
});

export const EVENT = PropTypes.exact({
  activity_format: PropTypes.string,
  activity_group: PropTypes.string,
  activity_type: PropTypes.oneOf(FilterGroups.activity_type.tags),
  address: PropTypes.exact({
    guid: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    full_address: PropTypes.string,
    formatted_address: PropTypes.string,
    street_address: PropTypes.string,
    secondary_address: PropTypes.string,
    location_map_link: PropTypes.string,
    address: PropTypes.string.isRequired,
    country_code: PropTypes.string.isRequired,
    country_code_alpha_3: PropTypes.string,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string,
    country: PropTypes.string.isRequired,
    postal_code: PropTypes.string.isRequired,
    address1: PropTypes.string,
    address2: PropTypes.string,
  }),
  category: PropTypes.string,
  check_in_ends: PropTypes.string,
  check_in_starts: PropTypes.string,
  complete: PropTypes.bool,
  contact_info: PropTypes.exact({
    email: PropTypes.string,
    owner_display: PropTypes.string,
    phone: PropTypes.string,
    phone_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    website: PropTypes.string,
  }),
  contact_information: PropTypes.exact({
    contact_email: PropTypes.string,
    contact_phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    website: PropTypes.string,
  }),
  details: PropTypes.string,
  distance: PropTypes.number.isRequired,
  division_details: PropTypes.shape({
    master: PropTypes.exact({
      admission: PropTypes.string,
      check_in_ends: PropTypes.string,
      check_in_starts: PropTypes.string,
      checkin_time: PropTypes.string,
      details: PropTypes.string,
      on_site_admission: PropTypes.string,
      on_site_registration_datetime: PropTypes.string,
      on_site_registration_duration: PropTypes.number,
      on_site_registration_time: PropTypes.string,
      registration_time: PropTypes.string,
      start_time: PropTypes.string.isRequired,
    }),
    senior: PropTypes.exact({
      admission: PropTypes.string,
      check_in_ends: PropTypes.string,
      check_in_starts: PropTypes.string,
      checkin_time: PropTypes.string,
      details: PropTypes.string,
      on_site_admission: PropTypes.string,
      on_site_registration_datetime: PropTypes.string,
      on_site_registration_duration: PropTypes.number,
      on_site_registration_time: PropTypes.string,
      registration_time: PropTypes.string,
      start_time: PropTypes.string.isRequired,
    }),
  }),
  division_info: PropTypes.shape({
    masters: PropTypes.number,
    seniors: PropTypes.number,
  }),
  division_year: PropTypes.number,
  division_year_info: PropTypes.shape({
    masters: PropTypes.number,
    seniors: PropTypes.number,
  }),
  division_years_for_birth_year: PropTypes.shape({
    masters: PropTypes.number,
    seniors: PropTypes.number,
  }),
  end: PropTypes.object.isRequired,
  event_website: PropTypes.string,
  fetchFailed: PropTypes.bool,
  filter_type: PropTypes.oneOf(FilterGroups.activity_type.tags),
  group_type: PropTypes.string,
  guid: PropTypes.string.isRequired,
  has_registration_options: PropTypes.bool,
  has_registration_skus: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isLargeEvent: PropTypes.bool,
  large_event_guid: PropTypes.string,
  lat: PropTypes.number.isRequired,
  league_guid: PropTypes.string,
  lng: PropTypes.number.isRequired,
  local_id: PropTypes.string,
  metadata: PropTypes.exact({
    category: PropTypes.string,
    contact_email: PropTypes.string,
    contact_phone: PropTypes.string,
    event_website: PropTypes.string,
    details: PropTypes.string,
    on_site_admission: PropTypes.string,
    on_site_registration_datetime: PropTypes.string,
    on_site_registration_duration: PropTypes.number,
    on_site_registration_time: PropTypes.string,
    pcom_sync_success: PropTypes.bool,
    pcom_update_key: PropTypes.string,
    pem_player_count: PropTypes.number,
    play_template: PropTypes.string,
    sanction_validation_data: PropTypes.object,
    sanctioned_date: PropTypes.string,
    series_transferred: PropTypes.bool,
    third_party_registration_website: PropTypes.string,
    third_party_url: PropTypes.string,
    update_key: PropTypes.string,
    website: PropTypes.string,
    eligibility_criteria: PropTypes.object,
    version_sync_history: PropTypes.object,
  }),
  moment: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  on_site_admission: PropTypes.string,
  on_site_registration_datetime: PropTypes.string,
  on_site_registration_duration: PropTypes.number,
  on_site_registration_time: PropTypes.string,
  payment_options: PropTypes.string,
  play_template: PropTypes.string,
  pokemon_url: PropTypes.string,
  premier_event_series_guid: PropTypes.string,
  product: PropTypes.oneOf(FilterGroups.products.tags),
  products: PropTypes.arrayOf(PropTypes.oneOf(FilterGroups.products.tags)),
  registration_options: PropTypes.array,
  registration_site: PropTypes.string,
  registration_skus: PropTypes.array,
  resource_parameter: PropTypes.string,
  retail_info: PropTypes.shape({
    name: PropTypes.string,
  }),
  retail_information: PropTypes.exact({
    retail_email: PropTypes.string,
    retail_name: PropTypes.string,
    retail_owner_display: PropTypes.string,
    retail_phone: PropTypes.string,
    retail_website: PropTypes.string,
  }),
  start_date: PropTypes.string,
  start_datetime: PropTypes.string,
  status: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  third_party_registration_website: PropTypes.string,
  total_player_capacity: PropTypes.number,
  tournament_id: PropTypes.string,
  website: PropTypes.string,
  when: PropTypes.string,
});

export const USER = PropTypes.exact({
  guid: PropTypes.string.isRequired,
  birth_year: PropTypes.number.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  country_code: PropTypes.string.isRequired,
  is_age_of_consent: PropTypes.bool.isRequired,
  legacy_aliases: PropTypes.arrayOf(PropTypes.string),
  accepted_terms: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
  contact_information: PropTypes.object.isRequired,
  opt_ins: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
  screen_name: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  encryption_keys: PropTypes.exact({
    active_key: PropTypes.exact({
      key: PropTypes.string,
      key_format: PropTypes.string,
    }),
    inactive_keys: PropTypes.arrayOf(PropTypes.string),
  }),
  has_stripe_account: PropTypes.bool.isRequired,
  primary_org_info: PropTypes.object.isRequired,
  professor: PropTypes.exact({
    professor_assessments: PropTypes.object,
    is_organizer: PropTypes.bool,
    can_sanction: PropTypes.bool,
    sanction_limit: PropTypes.number,
  }).isRequired,
});
