import React from "react";
import ReactDOM from "react-dom";
import EventLocator from "EventLocator";
import ProviderProvider from "ProviderProvider";
import "./i18n/config";
import * as serviceWorker from "serviceWorker";

ReactDOM.render(
  <ProviderProvider>
    <EventLocator />
  </ProviderProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();
