import React, { useContext } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { CONFIRM_TEXT, MODAL_SECTIONS } from "utils/propTypes";
// context
import { SettingsContext, Modals } from "contexts/SettingsContext";

// components
import LeavingSiteModal from "components/Modals/LeavingSiteModal";
import FiltersModal from "components/Modals/FiltersModal";
import OptInModal from "components/Modals/OptInModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";

// style
import "compiled/css/modal.css";

function Modal(props) {
  const {
    state: { modal },
    setModal,
  } = useContext(SettingsContext);

  const modalClass = modal ? "active" : "inactive";

  const modals = {
    [Modals.LeavingSite]: LeavingSiteModal,
    [Modals.Filters]: FiltersModal,
    [Modals.OptIn]: <OptInModal {...props} />,
    [Modals.Confirmation]: <ConfirmationModal {...props} />,
  };

  const Content = modals[modal] || (() => null);

  const handleCloseModal = () => setModal(null);

  const renderClose = () => (
    <div className="close-button" onClick={handleCloseModal}>
      <img src="/icons/close.svg" alt="" />
    </div>
  );

  return (
    <div
      id="modal-holder"
      className={modalClass}
      onClick={() => setModal(null)}
    >
      <Content />
      {modal === Modals.Filters && renderClose()}
    </div>
  );
}

Modal.propTypes = exact({
  sections: MODAL_SECTIONS,
  headerLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  confirmText: CONFIRM_TEXT,
  confirmClick: PropTypes.func,
});

export default Modal;
