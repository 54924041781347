import React, { useState } from "react";
import exact from "prop-types-exact";
import { EVENT } from "../../utils/propTypes";
import { useTranslation } from "react-i18next";

function Address({ event }) {
  const { t } = useTranslation();
  const [copiedClip, setCopiedClip] = useState(false);
  const {
    address: {
      address,
      address1,
      secondary_address,
      address2,
      city,
      state,
      street_address,
      country_code,
      formatted_address,
    },
  } = event;
  const copyText = copiedClip ? t("COPIED") : t("COPY_ADDRESS");

  const location = [city, state, country_code]
    .filter((text) => Boolean(text))
    .join(", ");

  const copyAddress = ({ key } = {}) => {
    if (key && key !== "Enter") {
      return;
    }
    const copiedText = formatted_address
      ? formatted_address
      : `${address || address1 || ""} ${
          address2 || secondary_address || ""
        } ${city}, ${state}`
          .trim()
          .replace(/\s\s+/g, " ");
    navigator.clipboard.writeText(copiedText);
    setCopiedClip(true);
    setTimeout(() => {
      setCopiedClip(false);
    }, 2000);
  };

  const renderAddress = formatted_address ? (
    <div className="address">{formatted_address}</div>
  ) : (
    <>
      {address ? (
        <div className="address">{address}</div>
      ) : (
        <>
          <div className="address">{address1 || street_address}</div>
          <div className="address">{address2}</div>
        </>
      )}
      <div className="locality" data-testid="location-detail">
        {location}
      </div>
    </>
  );

  return (
    <div className="location">
      <div className="data-row">
        <div className="data-label">{t("ADDRESS")}</div>
        <div className="data-value">
          <div data-testid="address">{renderAddress}</div>
          <span
            className={copiedClip ? "copy-link success" : "copy-link"}
            tabIndex="0"
            onKeyDown={copyAddress}
            onClick={copyAddress}
          >
            {copyText}
          </span>
        </div>
      </div>
    </div>
  );
}

Address.propTypes = exact({
  event: EVENT.isRequired,
});

export default Address;
