import React from "react";
import "compiled/css/spinner.css";

function Spinner() {
  const spinnerBall = "/icons/spinner-ball.svg";
  return (
    <div className="spinner">
      <div className="left-spacer" />
      <img src={spinnerBall} alt="Ultra Ball Spinner" />
      <div className="right-spacer" />
    </div>
  );
}

export default Spinner;
