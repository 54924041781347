import React, { useContext, useState } from "react";

import { useWindowSize } from "hooks";
import MapToggleButton from "components/Controls/MapToggleButton";
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";
import { SettingsContext, Modals } from "contexts/SettingsContext";

import DistanceDropdown from "./filters-dropdown/distance-dropdown/DistanceDropdown";
import SearchInput from "components/SearchInput";
import { useTranslation } from "react-i18next";

function LeftControl() {
  const { t } = useTranslation();
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const {
    state: { showMenu },
    setModal,
  } = useContext(SettingsContext);
  const menuToggleClass = `toggle${showMenu ? " active" : ""}`;
  const isEventLocator = application === Applications.EventLocator;
  if (!isEventLocator) {
    return <DistanceDropdown />;
  }
  return (
    <div
      onClick={() => {
        setModal(Modals.Filters);
      }}
      className={menuToggleClass}
    >
      {t("FILTERS")}
    </div>
  );
}

function ToggleRow() {
  const [isSearch, setIsSearch] = useState(false);

  const windowSize = useWindowSize();
  const smallScreen = windowSize.width < 1000;

  const {
    state: { application },
  } = useContext(EnvironmentContext);

  const isEventLocator = application === Applications.EventLocator;

  if (!smallScreen) {
    return null;
  }

  const toggleSearch = () => setIsSearch((value) => !value);

  return (
    <div className="toggle-row">
      {isSearch ? (
        <>
          <SearchInput />
          <div className="search-exit">
            <img
              onClick={toggleSearch}
              style={{ height: 20, width: 20, marginLeft: 15 }}
              src="/icons/close.svg"
              alt=""
            />
          </div>
        </>
      ) : (
        <>
          {isEventLocator ? <LeftControl /> : null}
          <MapToggleButton />
        </>
      )}
      {!isSearch && isEventLocator ? (
        <>
          <div className="search-icon">
            <img
              onClick={toggleSearch}
              style={{ height: 20, paddingLeft: 10 }}
              src="/icons/search.svg"
              alt="search-icon"
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ToggleRow;
