import React, { useContext } from "react";
import Spinner from "components/Controls/Spinner";
import { EventsContext } from "contexts/EventsContext";
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";

import "compiled/css/eventFetchPlaceholder.css";
import { useTranslation } from "react-i18next";

function Info() {
  const { state } = useContext(EventsContext);
  const { t } = useTranslation();
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const { formatted_address } = state.eventSearchLocation || {};

  const header =
    application === Applications.EventLocator
      ? t("FETCHING_EVENTS")
      : t("FINDING_LOCATIONS");

  if (!formatted_address) {
    return (
      <div className="info">
        <div className="header">{header}</div>
      </div>
    );
  }
  return (
    <div className="info">
      <div className="header">{header}</div>
      <div className="specs">
        {t("NEAR_ADDRESS", { address: formatted_address })}
      </div>
      <div className="bottom" />
    </div>
  );
}

function EventFetchPlaceholder() {
  return (
    <div id="event-fetch-placeholder" data-testid="event-fetch-placeholder">
      <Spinner />
      <Info />
    </div>
  );
}

export default EventFetchPlaceholder;
