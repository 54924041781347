// nodes modules
import React, { useContext, useState } from "react";

// internal imports
import MessageBox from "./MessageBox";
import Stages from "./Stages";
import { getNalsRedirectToken, formatPolicy } from "./utils/pGoHelpers";
import {
  useCheckLink,
  useCheckStageOneAndTwo,
  useCheckStageThree,
} from "./utils/pGoHooks";
import { ConfirmViews } from "./utils/const";
// external imports
import Footer from "components/Footer";
import { SettingsContext, Modals } from "contexts/SettingsContext";
import { EnvironmentContext } from "contexts/EnvironmentContext";
import Modal from "components/Controls/Modal";

// styles
import "compiled/css/pgo.css";
import { useTranslation } from "react-i18next";

function PGo() {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmView, setConfirmView] = useState(ConfirmViews.POGO_DISCLAIMER);
  const [nianticUsername, setNianticUsername] = useState(null);
  const [linkingComplete, setLinkingComplete] = useState(false);
  const [stages, setStages] = useState({
    1: { complete: false },
    2: { complete: false },
    3: { complete: false },
  });

  const {
    state: { user, access_token },
    setModal,
  } = useContext(SettingsContext);
  const {
    state: { endpoints },
  } = useContext(EnvironmentContext);

  const stageOneComplete = stages[1]?.complete;
  const stageTwoComplete = stages[2]?.complete;

  const accessToken = localStorage.getItem("accessToken") || access_token;
  useCheckLink({
    endpoints,
    accessToken,
    setLinkingComplete,
    setNianticUsername,
  });

  useCheckStageOneAndTwo({
    user,
    setStages,
    setErrorMessage,
    setLinkingComplete,
    optInErrorText: t("OPT_IN_ERROR"),
  });

  const token = getNalsRedirectToken();
  const nianticLinkEnabled = stageOneComplete && stageTwoComplete;
  useCheckStageThree({
    token,
    endpoints,
    setLinkingComplete,
    setNianticUsername,
    nianticLinkEnabled,
  });

  const handleMoreInfoClick = () => {
    setConfirmView(ConfirmViews.POGO_DISCLAIMER);
    setModal(Modals.Confirmation);
  };

  const playerId = user?.legacy_aliases?.length && user.legacy_aliases[0];
  const optedIn = user?.opt_ins?.op;
  let step2Directions = t("OPT_IN_STAGE_2_DEFAULT_MSG");
  if (playerId && !optedIn) step2Directions = t("OPT_IN_STAGE_2_SECONDARY_MSG");
  const stage2Sections = [
    {
      header: t("STAGE_2_SECTION_1_HEADER"),
      description: t("STAGE_2_SECTION_1_DESCRIPTION"),
    },
    {
      header: t("STAGE_2_SECTION_2_HEADER"),
      description: `${t("STAGE_2_SECTION_2_DESCRIPTION")} ${step2Directions}`,
    },
    {
      header: t("STAGE_2_SECTION_3_HEADER"),
      description: t("STAGE_2_SECTION_3_DESCRIPTION"),
    },
  ];

  const getModalProps = () => {
    const optInProps = {
      sections: stage2Sections,
    };

    const disclaimerProps = {
      headerLabel: t("LINKING_YOUR_ACCOUNT_LABEL"),
      confirmText: formatPolicy(
        t("POGO_DISCLAIMER"),
        t("PRIVACY_NOTICE"),
        t("PRIVACY_NOTICE_LINK"),
        t("SUPPORT_LINK")
      ),
      confirmButtonLabel: t("CLOSE_MODAL"),
    };

    const delinkProps = {
      headerLabel: t("DELINK_MODAL_HEADER"),
      confirmText: t("DELINK_MODAL_BODY"),
      confirmButtonLabel: t("CONFIRM_BUTTON_LABEL"),
      cancelButtonLabel: t("CANCEL"),
      confirmClick: () => {},
    };

    let modalProps = optInProps;
    if (confirmView === ConfirmViews.POGO_DISCLAIMER) {
      modalProps = disclaimerProps;
    }

    if (confirmView === ConfirmViews.DELINK) {
      modalProps = delinkProps;
    }

    return modalProps;
  };

  const modalProps = getModalProps();

  return (
    <>
      <Modal {...modalProps} />
      <div className="pgo-outer-container">
        <div className="pgo-header" />
        <div className="pgo-inner-container">
          <div className="pgo-banner-mobile">
            <img
              className="banner-image"
              src="/images/pgo/banner-icons-mobile.png"
              alt="pgo banner"
            />
          </div>
          <div className="pgo-banner">
            <div className="banner-content">
              <img
                className="banner-image"
                src="/images/pgo/banner-icons.png"
                alt="pgo banner"
              />
            </div>
          </div>
          <div className="pgo-content">
            <MessageBox
              visible={!!errorMessage || linkingComplete}
              isError={!!errorMessage}
              errorMessage={errorMessage}
            />
            <div className="label-section">
              <div className="label">{t("POGO_HEADER")}</div>
              <div className="sub-label">{t("POGO_SUB_HEADER")}</div>
            </div>
            <div className="stage-container" data-testid="stage_container">
              <Stages
                linkingComplete={linkingComplete}
                stages={stages}
                nianticUsername={nianticUsername}
                setConfirmView={setConfirmView}
              />
            </div>
            <div className="more-info-section">
              <div className="more-info-btn" onClick={handleMoreInfoClick}>
                {t("MORE_INFORMATION_LABEL")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="pgo-legal-footer">
        <Footer />
      </div>
    </>
  );
}

export default PGo;
