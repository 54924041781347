import React, { useContext, useEffect, useMemo } from "react";
/* Okay, we need a ton of app-wide use-effects. 
This is where they live. This is always the inner-most context*/
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";
import { EventsContext } from "contexts/EventsContext";
// import { FiltersContext } from "contexts/FiltersContext";
// import { LocalizationContext } from "contexts/LocalizationContext";
import { UrlContext } from "contexts/UrlContext";
import { SettingsContext } from "contexts/SettingsContext";
import { useLocation } from "react-router-dom";

// Aaaand the helpers
import { register } from "utils/registrationHelpers";

const ReactiveContext = React.createContext();

// This provider SHOULD NOT have a state. App-state happens in SettingsContext. This is only for reactive use-effects.
function ReactiveProvider({ children }) {
  const environment = useContext(EnvironmentContext);
  const { state: eventsState } = useContext(EventsContext);
  const { state: settingsState, ...settings } = useContext(SettingsContext);
  const { replaceToPage } = useContext(UrlContext);

  const location = useLocation();
  const [page] = location.pathname.split("/").slice(2);

  // When a user initializes
  useEffect(() => {
    if (settingsState.registrationStatus === "STARTED") {
      register({
        cart: settingsState.cart,
        skusById: eventsState.skusById,
        environment,
      }).then((registrations) => {
        settings.finishRegistration(registrations);
      });
    } // eslint-disable-next-line
  }, [settingsState.registrationStatus]);

  useEffect(() => {
    if (
      page === "locations" &&
      environment.state.application !== Applications.RetailLocator
    ) {
      environment.setApplication(Applications.RetailLocator);
    } else if (
      ["events", "sessions", "premiers", "tournaments"].includes(page) &&
      environment.state.application !== Applications.EventLocator
    ) {
      environment.setApplication(Applications.EventLocator);
    } else if (!page) {
      const fallbackPage =
        environment.state.application === Applications.RetailLocator
          ? "locations"
          : "events";
      replaceToPage(fallbackPage);
    } // eslint-disable-next-line
  }, [page]);

  const contextValue = useMemo(() => ({}), []);

  return (
    <ReactiveContext.Provider value={contextValue}>
      {children}
    </ReactiveContext.Provider>
  );
}

export { ReactiveContext, ReactiveProvider };
