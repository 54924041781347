import React, {
  useContext,
  useEffect,
  useRef,
  Children,
  useState,
} from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LocalizationContext } from "contexts/LocalizationContext";
import { EnvironmentContext, Environments } from "contexts/EnvironmentContext";
import queryString from "query-string";
import { EventsContext } from "contexts/EventsContext";
import { UrlContext } from "contexts/UrlContext";
import { SettingsContext } from "contexts/SettingsContext";
import { FiltersContext } from "contexts/FiltersContext";
import { useHistory, withRouter } from "react-router-dom";
import "compiled/css/searchInput.css";
/* Helpers*/
import { isCookieConsented, mockUserConsent } from "utils/cookieHelpers";
import { convertDistance } from "contexts/helpers/converters";
import { useTranslation } from "react-i18next";

function SearchInput() {
  const history = useHistory();
  const {
    state: { eventIds },
    receiveEvents,
    setLocation,
  } = useContext(EventsContext);
  const {
    getCurrentDistanceUnits,
    state: { google },
  } = useContext(LocalizationContext);
  const { unit } = getCurrentDistanceUnits();
  const { t } = useTranslation();
  const {
    state: { cookiesAccepted },
    blockGeo,
    acceptCookies,
    tabBlock,
  } = useContext(SettingsContext);
  const {
    state: { sortAttr, maxDistance },
  } = useContext(FiltersContext);
  const {
    state: { environment },
  } = useContext(EnvironmentContext);

  const tabIndex = tabBlock ? -1 : 0;

  const { replaceTo } = useContext(UrlContext);

  const searchInput = useRef(null);
  const [address, setAddress] = useState("");

  const queries = queryString.parse(history.location.search);

  /*
  The OneTrust sdk doesn't work on localhost. Setting a mock OneTrust cookie
  when running app for development. 
  */
  useEffect(() => {
    if (environment === Environments.DEV) {
      mockUserConsent();
    }
  }, [environment]);

  useEffect(() => {
    // Since cookies and geolocation requests can cause the cookie footer to be hidden on phones, we don't want to ask for location until we have cookie consent.
    const intervalId = setInterval(() => {
      if (isCookieConsented()) {
        acceptCookies();
        clearInterval(intervalId);
      }
    }, 200);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!cookiesAccepted) {
      return;
    }
    if (navigator.geolocation && !queries.near) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const location = { lat: coords.latitude, lng: coords.longitude };
          setLocation({ location, sortAttr });
        },
        () => {
          blockGeo();
        }
      );
    } // eslint-disable-next-line
  }, [cookiesAccepted]);

  useEffect(() => {
    if (queries && queries.near && eventIds.length === 0) {
      handleSelect(queries.near);
    } // eslint-disable-next-line
  }, [queries.near, google]);

  async function handleSelect(value) {
    if (!google) {
      return null;
    }
    const results = await geocodeByAddress(value).catch((error) => {
      receiveEvents({ error });
    });
    if ((results || []).length > 0) {
      const [bestGuess] = results;
      const latLng = await getLatLng(bestGuess);
      setAddress(value);
      const location = { ...latLng, ...bestGuess };
      setLocation({
        location,
        sortAttr,
        distance: convertDistance(maxDistance, unit),
      });
    }
  }

  if (!google) {
    return <div data-testid="places-placeholder" />;
  }

  const getUrlCorrect = async (value) => {
    if (!google) {
      return null;
    }
    const results = await geocodeByAddress(value);
    const result = results;
    const { formatted_address } = result;
    replaceTo({ search: { near: formatted_address } });
    handleSelect(value);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={getUrlCorrect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="search-holder" data-testid="search-holder">
          <input
            tabIndex={tabIndex}
            ref={searchInput}
            data-testid="location-search"
            className="location-search"
            {...getInputProps({
              placeholder: t("LOCATION_SEARCH_PLACEHOLDER"),
              className: "location-search",
            })}
          />
          <div
            className="location-icon"
            tabIndex={tabIndex}
            onClick={() => {
              getUrlCorrect(searchInput.current.value || "");
            }}
            onKeyPress={() => {
              getUrlCorrect(searchInput.current.value || "");
            }}
          >
            <img src="/icons/search.svg" alt="search-icon" />
          </div>

          <div className="autocomplete-dropdown-container">
            {Boolean(loading) && null}
            {Children.toArray(
              suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item active"
                  : "suggestion-item";
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default withRouter(SearchInput);
