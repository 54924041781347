import React, { useContext } from "react";
/* Components*/
import StageCard from "./StageCard";
/* Helpers*/
import { ConfirmViews } from "./utils/const";
import { generateNianticLinkUrl, generateELUrl } from "./utils/pGoHelpers";
/* Context*/
import { SettingsContext, Modals } from "contexts/SettingsContext";
import { EnvironmentContext, Environments } from "contexts/EnvironmentContext";
import { LocalizationContext } from "contexts/LocalizationContext";
/* Props*/
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { useTranslation } from "react-i18next";

function DeLink({ nianticUsername, handleDelinkClick }) {
  const { t } = useTranslation();
  const {
    state: { environment },
  } = useContext(EnvironmentContext);
  return (
    <div className="delink-container">
      <span className="user-name">{nianticUsername}</span> |{" "}
      {environment !== Environments.PROD && (
        <span className="delink" onClick={handleDelinkClick}>
          {t("DELINK_LABEL")}
        </span>
      )}
    </div>
  );
}

function Stages({ stages, linkingComplete, setConfirmView, nianticUsername }) {
  const {
    setModal,
    state: { user },
  } = useContext(SettingsContext);
  const {
    state: { environment },
  } = useContext(EnvironmentContext);
  const {
    state: { currentLanguageCode },
  } = useContext(LocalizationContext);

  const toggleDelinkModalModal = () => {
    setConfirmView(ConfirmViews.DELINK);
    setModal(Modals.Confirmation);
  };
  const handleDelinkClick = () => toggleDelinkModalModal();

  const stageOneComplete = stages[1]?.complete;
  const stageTwoComplete = stages[2]?.complete;

  const linkStages = [
    {
      imageSrc: "/images/pgo/step-1.png",
      details: "POGO_STAGE_1_DETAILS",
      buttonLabel: "POGO_STAGE_1_BUTTON_LABEL",
      isComplete: stageOneComplete || linkingComplete,
      custom: {
        component: <></>,
        props: { type: "text", logoutText: "POGO_DONE_BUTTON_LABEL" },
      },
    },
    {
      disabled: !stageOneComplete || linkingComplete,
      imageSrc: "/images/pgo/step-2.png",
      buttonLabel: "POGO_STAGE_2_BUTTON_LABEL",
      details: "POGO_STAGE_2_DETAILS",
      isComplete: stageTwoComplete || linkingComplete,
      handleStageClick: () => {
        setConfirmView(null);
        setModal(Modals.OptIn);
      },
    },
    {
      disabled: user?.is_age_of_consent
        ? (stageOneComplete && !stageTwoComplete) ||
          (!stageOneComplete && !stageTwoComplete) ||
          linkingComplete
        : true,
      imageSrc: "/images/pgo/step-3.png",
      details: "POGO_STAGE_3_DETAILS",
      buttonLabel: "POGO_STAGE_3_BUTTON_LABEL",
      isComplete: linkingComplete,
      delinkComponent: (
        <DeLink
          nianticUsername={nianticUsername}
          handleDelinkClick={handleDelinkClick}
        />
      ),
      elUrl: generateELUrl({ currentLanguageCode, environment }),
      url: generateNianticLinkUrl({ currentLanguageCode, environment }),
    },
  ];

  return (
    <>
      {linkStages.map((stage, idx) => (
        <StageCard key={stage} stage={stage} cardStep={idx + 1} />
      ))}
    </>
  );
}

Stages.propTypes = exact({
  linkingComplete: PropTypes.bool.isRequired,
  stages: PropTypes.shape({
    [PropTypes.number]: PropTypes.exact({
      complete: PropTypes.bool.isRequired,
    }),
  }),
  nianticUsername: PropTypes.string,
  setConfirmView: PropTypes.func,
});

export default Stages;
