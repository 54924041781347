import "compiled/css/eventCard.css";
import {
  LocalizationContext,
  MapLanguageByLocale,
} from "contexts/LocalizationContext";

import React, { useContext } from "react";
import exact from "prop-types-exact";
import { EVENT } from "../../utils/propTypes";
import { dateTimeFormat } from "components/constants";
import { DateService } from "utils/date-service";
import { useTranslation } from "react-i18next";

function RegistrationTime({ label, start, end }) {
  return (
    <li className="data-row">
      <div className="data-label">{label}</div>

      <div className="data-value">
        <div className="line">{start}</div>
        <div>{" - "}</div>
        <div className="line">{end}</div>
      </div>
    </li>
  );
}

function RegistrationTimes({ event }) {
  const { t } = useTranslation();

  const { division_details } = event;
  const {
    state: { currentLocale },
  } = useContext(LocalizationContext);
  const locale = MapLanguageByLocale[currentLocale];
  DateService.locale(locale);

  const allDivRegTime = event?.on_site_registration_datetime;

  const startTime = new DateService().setDate({
    date: allDivRegTime,
    format: "utc",
  });
  const allDivStartTime = startTime.toFormat(dateTimeFormat);

  const allDivEndTime = startTime
    .addTime({ duration: event.on_site_registration_duration, unit: "minute" })
    .toFormat(dateTimeFormat);

  const divisions = ["junior", "senior", "master"];
  const otherDivTimes = divisions.reduce((acc, div) => {
    const regTime = division_details?.[div]?.on_site_registration_datetime;
    const duration = division_details?.[div]?.on_site_registration_duration;
    const startTime = new DateService().setDate({
      format: "utc",
      date: regTime,
    });
    const endTime = new DateService()
      .setDate({
        format: "utc",
        date: startTime.getDate(),
      })
      .addTime({ duration, unit: "minute" })
      .toFormat(dateTimeFormat);
    return {
      ...acc,
      [div]: {
        startTime: startTime.toFormat(dateTimeFormat),
        endTime,
        duration,
        isActive: Boolean(regTime),
      },
    };
  }, {});

  if (allDivRegTime) {
    return (
      <div className="registration-times">
        <div className="section-header emphasized">{t("REGISTRATION")}</div>
        <div className="blurb">
          <ul className="registration-time">
            <RegistrationTime
              start={allDivStartTime}
              end={allDivEndTime}
              label={t("ALL_DIVISIONS")}
            />
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="registration-times">
      <div className="section-header emphasized">{t("REGISTRATION")}</div>
      <div className="blurb">
        <ul className="registration-time">
          {otherDivTimes.junior.isActive ? (
            <RegistrationTime
              start={otherDivTimes.junior.startTime}
              end={otherDivTimes.junior.endTime}
              label={t("JUNIOR")}
            />
          ) : null}
          {otherDivTimes.senior.isActive ? (
            <RegistrationTime
              start={otherDivTimes.senior.startTime}
              end={otherDivTimes.senior.endTime}
              label={t("SENIOR")}
            />
          ) : null}
          {otherDivTimes.master.isActive ? (
            <RegistrationTime
              start={otherDivTimes.master.startTime}
              end={otherDivTimes.master.endTime}
              label={t("MASTERS")}
            />
          ) : null}
        </ul>
      </div>
    </div>
  );
}
function OnsiteCost({ event }) {
  const { t } = useTranslation();

  const { division_details } = event;
  const juniorCost = division_details?.junior?.on_site_admission;
  const seniorCost = division_details?.senior?.on_site_admission;
  const masterCost = division_details?.master?.on_site_admission;
  const allCost = juniorCost === seniorCost && seniorCost === masterCost;

  if (!juniorCost && !seniorCost && !masterCost) {
    return <></>;
  }
  return (
    <div className="entry-fees">
      <div className="section-header emphasized">{t("ONSITE_ADMISSION")}</div>
      {allCost ? (
        <div className="blurb">
          <ul className="admissions">
            <li className="data-row">
              <div className="data-label">{t("ALL_DIVISIONS")}</div>
              <div className="data-value">{juniorCost}</div>
            </li>
          </ul>
        </div>
      ) : null}
      {!allCost && (
        <div className="blurb">
          <ul className="admissions">
            {juniorCost ? (
              <li className="data-row">
                <div className="data-label">{t("JUNIOR")}</div>
                <div className="data-value">{juniorCost}</div>
              </li>
            ) : null}
            {seniorCost ? (
              <li className="data-row">
                <div className="data-label">{t("SENIOR")}</div>
                <div className="data-value">{seniorCost}</div>
              </li>
            ) : null}
            {masterCost ? (
              <li className="data-row">
                <div className="data-label">{t("MASTERS")}</div>
                <div className="data-value">{masterCost}</div>
              </li>
            ) : null}
          </ul>
        </div>
      )}
    </div>
  );
}

function OnsiteAdmission({ event }) {
  const { division_details } = event;
  const divisions = ["junior", "senior", "master"];
  const fields = ["on_site_admission", "on_site_registration_datetime"];
  const somethingToShow = divisions.some((division) =>
    fields.some((field) => ((division_details || {})[division] || {})[field])
  );
  if (!somethingToShow) return <></>;
  return (
    <>
      <RegistrationTimes event={event} />
      <OnsiteCost event={event} />
    </>
  );
}

OnsiteAdmission.propTypes = exact({
  event: EVENT.isRequired,
});

export default OnsiteAdmission;
