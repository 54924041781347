import "compiled/css/sortControl.css";
import { Applications, EnvironmentContext } from "contexts/EnvironmentContext";
import { FiltersContext } from "contexts/FiltersContext";

import { SettingsContext } from "contexts/SettingsContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

function SortToggle({ application }) {
  if (application !== Applications.EventLocator) {
    return null;
  }
  return (
    <div className="sort">
      <SortFormatter />
    </div>
  );
}

function SortFormatter() {
  const {
    state: { modal },
  } = useContext(SettingsContext);
  const tabIndex = modal ? -1 : 0;
  const { toggleSort } = useContext(FiltersContext);

  const { t } = useTranslation();
  const [sortByText, setSortByText] = useState(t("DISTANCE"));
  const handleSortByClick = () => {
    setSortByText(sortByText === t("DISTANCE") ? t("DATE") : t("DISTANCE"));
    toggleSort();
  };

  return (
    <div className="sort-type-container">
      <span
        className="sort-type"
        onClick={handleSortByClick}
        onKeyPress={handleSortByClick}
        tabIndex={tabIndex}
      >
        {t("SORT_ORDER")}
        <span>{` ${sortByText} ▾`}</span>
      </span>
    </div>
  );
}

function SortControl() {
  const {
    state: { filteredIds },
  } = useContext(FiltersContext);
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const { t } = useTranslation();

  const numActivities = filteredIds.length;
  const countText =
    application === Applications.EventLocator
      ? t("EVENT_COUNT", { count: numActivities })
      : t("RETAIL_COUNT", { count: numActivities });

  return (
    <div id="sort-control">
      <div className="count">{`${countText}`}</div>
      <SortToggle application={application} />
    </div>
  );
}

export default SortControl;
