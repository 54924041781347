import { FiltersContext } from "contexts/FiltersContext";
import { useContext, useRef, useEffect } from "react";
import EventCard from "../EventCard";

export function Row({ style, index, setRowHeight }) {
  const {
    state: { filteredIds },
  } = useContext(FiltersContext);
  const rowRef = useRef({});

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef]);

  return (
    <div style={style}>
      <EventCard eventId={filteredIds[index]} ref={rowRef} />
    </div>
  );
}
