import "compiled/css/detailView.css";
import Spinner from "components/Controls/Spinner";
import Address from "components/EventDetail/Address";
import OnsiteAdmission from "components/EventDetail/OnsiteAdmission";
import DetailsBox from "components/EventDetail/DetailsBox";
import EventDescription from "components/EventDetail/EventDescription";
import Owner from "components/EventDetail/Owner";
import TeamChallengeBanner from "components/EventDetail/TeamChallengeBanner";
import EventCard from "components/EventList/EventCard";
import Footer from "components/Footer";
import { Applications, EnvironmentContext } from "contexts/EnvironmentContext";
import { EventsContext } from "contexts/EventsContext";

import { SettingsContext } from "contexts/SettingsContext";
import { UrlContext } from "contexts/UrlContext";
import React, { useContext, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import exact from "prop-types-exact";
import { REACT_ROUTER } from "utils/propTypes";
import EventPlaytimes from "./EventPlaytimes";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function DetailView({ history, testData }) {
  const { t } = useTranslation();
  const {
    state: { searchableEventsById, childEventsById, searchableChildEventsById },
    completeEvent,
    completeActivityGroup,
  } = useContext(EventsContext);

  const {
    getSelectedEvent,
    replaceToPage,
    state: { listViewSeen },
  } = useContext(UrlContext);
  const {
    state: { hideFooter },
  } = useContext(SettingsContext);
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const focusedEventId = getSelectedEvent();
  const event =
    testData ??
    (searchableEventsById[focusedEventId] ||
      searchableChildEventsById[focusedEventId]);

  useEffect(() => {
    if (!!focusedEventId && !event) {
      goBack();
      return;
    } else if (
      !!focusedEventId &&
      !!event &&
      !event.complete &&
      application === Applications.EventLocator
    ) {
      if (event.isLargeEvent) {
        completeActivityGroup(event);
      } else {
        completeEvent(event);
      }
    } // eslint-disable-next-line
  }, [focusedEventId]);

  let childEvents = [];
  if (event?.isLargeEvent) {
    childEvents = childEventsById[event.large_event_guid].children;
  }
  const isChildEvent =
    focusedEventId && !!searchableChildEventsById[focusedEventId];

  function goBack({ key } = {}) {
    if (key && key !== "Enter") {
      return;
    }
    if (listViewSeen) {
      history.goBack();
    } else {
      const page =
        application === Applications.EventLocator ? "events" : "locations";
      replaceToPage(page);
    }
  }

  function ThirdPartyUrl({ regURL }) {
    const {
      state: { modal },
      intendToLeave,
    } = useContext(SettingsContext);

    const tabIndex = modal ? -1 : 0;
    return (
      <div className="third-party-url">
        <div className="blurb">
          <ul>
            <li className="data-row split">
              <div
                tabIndex={tabIndex}
                className="exit-link"
                onKeyPress={() => {
                  intendToLeave(regURL);
                }}
                title={regURL}
                onClick={() => {
                  intendToLeave(regURL);
                }}
              >
                {t("CLICK_HERE")} &gt;
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  function BackButton() {
    const {
      state: { modal },
    } = useContext(SettingsContext);
    const tabIndex = modal ? -1 : 0;
    const backText = isChildEvent ? t("BACK_TO_EVENT") : t("BACK_TO_RESULTS");

    return (
      // eslint-disable-next-line
      <a
        className="back-button"
        onClick={goBack}
        onKeyDown={goBack}
        tabIndex={tabIndex}
      >
        <div className="arrow">{`◀`}</div>
        <div className="back-text">{backText}</div>
      </a>
    );
  }

  function AddressAndOwner() {
    return (
      <div className="address-and-owner">
        <Owner event={event} />
        <Address event={event} />
      </div>
    );
  }

  return useMemo(() => {
    const thirdPartyRegURL = event?.third_party_registration_website;
    const divisionDetails = event?.division_details;
    if (!event) {
      return (
        <div id="league-detail-view" className="empty">
          <Spinner />
        </div>
      );
    }

    return (
      <div id="league-detail-view">
        <BackButton />
        <EventCard eventId={event.id} mini />
        <div className="league-link-group">
          {divisionDetails ? <OnsiteAdmission event={event} /> : null}
          {thirdPartyRegURL ? (
            <ThirdPartyUrl regURL={thirdPartyRegURL} />
          ) : null}
          {event.league_guid ? (
            <EventPlaytimes eventId={event.league_guid} />
          ) : null}
        </div>
        <div className="league-body">
          <AddressAndOwner />
          {event.details ? <DetailsBox event={event} /> : null}
          <EventDescription event={event} />
          {event.isLargeEvent ? (
            <div>
              <div>Tournaments In This Event</div>
              <div className="child-event-cards">
                {childEvents.length
                  ? React.Children.toArray(
                      childEvents.map((ce) => <EventCard childEvent={ce} />)
                    )
                  : null}
              </div>
            </div>
          ) : null}
          {event.tags.includes("team-challenge") && <TeamChallengeBanner />}
        </div>
        <div className="spacer" />
        {!hideFooter && <Footer />}
      </div>
    );
    /* eslint-disable-next-line */
  }, [event, hideFooter]);
}

DetailView.propTypes = exact({
  ...REACT_ROUTER,
  testData: PropTypes.object,
});

export default withRouter(DetailView);
