// node modules
import React from "react";
import { PageByType } from "contexts/UrlContext";
import { Marker } from "google-maps-react";

const getPins = ({
  events,
  google,
  currFocusedEvent,
  focusedEventId,
  childEventsById,
  goToEventDetail,
  mapPinImage,
}) => {
  let eventsArr = events;
  if (
    focusedEventId === currFocusedEvent?.id &&
    currFocusedEvent?.large_event_guid &&
    !currFocusedEvent.isLargeEvent &&
    currFocusedEvent.activity_type !== "league" &&
    Object.keys(childEventsById ?? {}).length !== 0
  ) {
    eventsArr = childEventsById[currFocusedEvent.large_event_guid]?.children;
  }

  return eventsArr?.map(({ lat, lng, id, activity_type }) => {
    const page = `${PageByType[activity_type] || "retail"}/${id}`;
    const pinIcon = {
      url: mapPinImage,
      scaledSize: new google.maps.Size(48, 48),
    };

    if (!!focusedEventId && focusedEventId !== id.toString()) {
      return null;
    } else if (!focusedEventId) {
      return (
        <Marker
          key={id}
          icon={pinIcon}
          position={{ lat, lng }}
          onClick={() => goToEventDetail({ page })}
        />
      );
    }
    pinIcon.scaledSize = new google.maps.Size(64, 64);
    return <Marker key={id} icon={pinIcon} position={{ lat, lng }} />;
  });
};

const WORLD_BOUNDS = {
  north: -34.36,
  south: -47.35,
  west: 166.28,
  east: -175.81,
};

const mapSettings = ({ map, google }) => ({
  ref: map,
  google,
  zoom: 3,
  minZoom: 3,
  restriction: { latLngBounds: WORLD_BOUNDS },
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  style: {
    width: "100%",
    height: "100%",
  },
});

export { getPins, mapSettings };
