export const unitOptions = {
  mi: [5, 10, 50, 100, 250],
  km: [10, 20, 100, 200, 400],
};

export const unitMap = {
  mi: {
    5: 5,
    10: 10,
    50: 50,
    100: 100,
    250: 250,
  },
  km: {
    10: 6.21371,
    20: 12.4274,
    100: 62.1371,
    200: 124.274,
    400: 248.548,
  },
};

export const miToKmMap = {
  6.21371: 10,
  12.4274: 20,
  62.1371: 100,
  124.274: 200,
  248.548: 400,
};
