// node modules
import React, { useEffect, useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import exact from "prop-types-exact";

// styles
import "compiled/css/dropdownMenu.css";

function DropdownMenu({
  children,
  buttonLabel = "",
  closeOnChangeVal,
  testId,
  theme,
}) {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutsideClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (closeOnChangeVal) setIsOpen(false);
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideClick);
    };
  }, [closeOnChangeVal]);

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <div className="dropdown-menu-container" ref={containerRef}>
      <div
        onClick={handleClick}
        onKeyPress={handleClick}
        className="dropdown-menu-bar"
        data-testid={testId || "dropdown_menu_btn"}
        id="dropdown-menu-bar-btn"
        tabIndex="0"
      >
        <div className={`left-content ${theme}`}>{buttonLabel}</div>
        <span className={`carat-down ${theme}`}>▾</span>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames="dropdown"
      >
        <div className="dropdown-menu" data-testid="dropdown_menu">
          {children}
        </div>
      </CSSTransition>
    </div>
  );
}

DropdownMenu.propTypes = exact({
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
  testId: PropTypes.string.isRequired,
  theme: PropTypes.string,
  closeOnChangeVal: PropTypes.number,
});

export default DropdownMenu;
