// node modules
import React, { useContext, useState } from "react";

// external imports
import { SvgByTag } from "components/constants/icon";
import { hyphenate } from "hyphen/de";
// styles
import "compiled/css/productDropdown.css";

// contexts
import { FiltersContext, FiltersConstants } from "contexts/FiltersContext";
import { LocalizationContext } from "contexts/LocalizationContext";
import { useTranslation } from "react-i18next";

function ProductButton({ tag }) {
  const {
    toggleFilter,
    state: { activeFilters },
  } = useContext(FiltersContext);
  const {
    state: { currentLocale },
  } = useContext(LocalizationContext);
  const { t } = useTranslation();

  const [hyphenatedText, setHyphenatedText] = useState(
    t(FiltersConstants.Filters[tag].label)
  );

  let filterClass = `product-dropdown-button ${tag}`;
  const active = activeFilters[tag];
  if (active) {
    filterClass += " active";
  }

  const svg = SvgByTag[tag];
  let iconSrc = `/icons/${svg}-${active ? "on" : "off"}.svg`;

  const handleFilterClick = () => {
    toggleFilter(tag);
  };

  const testId = `${tag}_product_dropdown_button`;
  // added to address Opera windows incompatibility with CSS hyphenation
  async function hyphenate_de() {
    try {
      const transformedText = await hyphenate(hyphenatedText);
      setHyphenatedText(transformedText);
    } catch (error) {
      console.error(error);
    }
  }

  if (currentLocale === "DE") {
    hyphenate_de();
  }

  let disableWhenLeagueFilterActive = handleFilterClick;

  if (activeFilters.league && !activeFilters.tournament) {
    disableWhenLeagueFilterActive = null;
    filterClass = `product-dropdown-button ${tag}`;
    iconSrc = `/icons/${svg}-off.svg`;
  }

  return (
    <div
      className={filterClass}
      onClick={disableWhenLeagueFilterActive}
      onKeyPress={disableWhenLeagueFilterActive}
      data-testid={testId}
      tabIndex={0}
    >
      <img className="icon" src={iconSrc} alt={hyphenatedText} />
      <span className="label" lang={currentLocale.toLowerCase()}>
        {hyphenatedText}
      </span>
    </div>
  );
}

function ProductFilters() {
  const { t } = useTranslation();

  const renderMenuItems = FiltersConstants.FilterGroups.products.tags.map(
    (tag) => <ProductButton key={tag} tag={tag} />
  );

  return (
    <div className="product-filters-container" data-testid="product_filters">
      <div className="product-filters-label">{t("PRODUCT_TYPE")}</div>
      <div
        className="product-filter-items"
        data-testid="product_filter_buttons"
      >
        {renderMenuItems}
      </div>
    </div>
  );
}

export default ProductFilters;
