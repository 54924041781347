import React, { useState } from "react";
import "compiled/css/detailsBox.css";
import exact from "prop-types-exact";
import { EVENT } from "../../utils/propTypes";
import { useTranslation } from "react-i18next";

function DetailsBox({ event }) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const boxClass = `league-details${collapsed ? " collapsed" : ""}`;

  return (
    <div
      className={boxClass}
      tabIndex="0"
      onClick={toggleCollapse}
      onKeyPress={toggleCollapse}
    >
      <div className="section-header">
        <div>{t("FROM_THE_ORGANIZER")}</div>
        <div className="collapser">▸</div>
      </div>
      <div className="blurb">{event.details}</div>
    </div>
  );
}

DetailsBox.propTypes = exact({
  event: EVENT.isRequired,
});

export default DetailsBox;
