import React, { useContext } from "react";

// styles
import "compiled/css/eventTypeFilters.css";

// contexts
import { FiltersContext, FiltersConstants } from "contexts/FiltersContext";

// external imports
import { SvgByTag, ImgByTag } from "components/constants/icon";
import { EventTypes, SubEventTypes } from "./constants";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { useTranslation } from "react-i18next";

const btnClassName = `event-filter-button`;
function EventTypeButton({ tag }) {
  const {
    toggleFilter,
    state: { activeFilters },
  } = useContext(FiltersContext);
  const { t } = useTranslation();

  const handleClick = () => {
    toggleFilter(tag);
  };

  const label = t(FiltersConstants.Filters[tag].label);
  const svg = SvgByTag[tag];
  const active = activeFilters[tag];

  let btnClass = btnClassName;
  if (active) btnClass += ` active ${tag}`;
  const iconSrc = `/icons/${svg}-${active ? "on" : "off"}.svg`;
  const testId = `${tag}_event_type_filter`;

  return (
    <div
      className={btnClass}
      onClick={handleClick}
      onKeyPress={handleClick}
      data-testid={testId}
      tabIndex={0}
    >
      <img className="icon" src={iconSrc} alt={label} />
      <div className="label">{label}</div>
    </div>
  );
}

function EventSubTypeFilter({ tag, disabled }) {
  const {
    toggleFilter,
    state: { activeFilters },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const label = t(FiltersConstants.Filters[tag].label);
  const handleClick = () => {
    if (disabled) return;
    toggleFilter(tag);
  };
  const active = activeFilters[tag];

  const img = ImgByTag[tag];
  const filterImg = `/images/${img}.png`;
  let btnClass = btnClassName;
  if (active) btnClass += ` active`;

  const testId = `${tag}_sub_type_filter`;

  return (
    <div
      className={btnClass}
      onClick={handleClick}
      onKeyPress={handleClick}
      data-testid={testId}
      tabIndex={0}
    >
      <img className="sub-type-icon" src={filterImg} alt={label} />
      <div className="label">{label}</div>
    </div>
  );
}

function EventSubTypeRow({ event, idx }) {
  const {
    state: { activeFilters },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const vgActive = activeFilters.vg;
  const tcgActive = activeFilters.tcg;

  const rowDisabled = (eventType) =>
    (vgActive && !tcgActive && eventType === "tcg") ||
    (tcgActive && !vgActive && eventType === "vg");
  const disabled = rowDisabled(event.type);

  let rowClass = "event-sub-type-row";
  if (disabled) rowClass += " disabled";

  const label = t(FiltersConstants.Filters[event.type].label);
  const testId = `${event.type}_sub_type_row`;

  return (
    <div key={idx} className={rowClass} data-tested={testId}>
      <div className="event-sub-type-label">{label}</div>
      <div className="event-sub-type-group">
        {event.tags.map((tag) => (
          <EventSubTypeFilter
            disabled={disabled}
            tag={tag}
            key={tag}
            activeFilters={activeFilters}
          />
        ))}
      </div>
    </div>
  );
}

function EventTypeFilters({ isExpanded, moreFiltersOpen, pesFilterActive }) {
  const {
    state: { activeFilters },
  } = useContext(FiltersContext);

  const { t } = useTranslation();
  const renderEventTypes = EventTypes.map((tag) => (
    <EventTypeButton tag={tag} key={tag} disabled={pesFilterActive} />
  ));

  const renderEventSubTypes = SubEventTypes.map((event, idx) => (
    <EventSubTypeRow key={event.label} event={event} idx={idx} />
  ));

  let containerClass = "event-type-filters-container";
  if (!isExpanded && moreFiltersOpen) containerClass += " scroll-height";

  const leagueTextWarning = activeFilters.league
    ? t("LEAGUE_FILTER_WARNING")
    : null;

  return (
    <div className={containerClass} data-testid="event_type_filters">
      <div className="event-type-filters-label">{t("EVENT_TYPES")}</div>
      <div
        className={`event-types-container ${pesFilterActive ? "disabled" : ""}`}
        data-testid="event_type_row"
      >
        {renderEventTypes}
        <div>{leagueTextWarning}</div>
      </div>
      {isExpanded || moreFiltersOpen ? (
        <div
          className="event-sub-types-container"
          data-testid="pes_filters_container"
        >
          <div className="pes-label">{t("PREMIER_EVENT_SERIES")}</div>
          {renderEventSubTypes}
        </div>
      ) : null}
    </div>
  );
}

EventTypeFilters.propTypes = exact({
  isExpanded: PropTypes.bool.isRequired,
  moreFiltersOpen: PropTypes.bool,
  pesFilterActive: PropTypes.bool,
});

export default EventTypeFilters;
