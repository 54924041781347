import { useEffect } from "react";
import { fetchNianticUser, linkAccount } from "apis/pgo";

const useCheckLink = ({
  endpoints,
  setComplete,
  accessToken,
  setNianticUsername,
}) => {
  useEffect(() => {
    // used to help cleanup redundant fetch requests between mountings of the component
    const controller = new AbortController();
    const { signal } = controller;
    if (
      endpoints &&
      accessToken &&
      typeof endpoints.LINK_NIANTIC !== "undefined"
    ) {
      fetchNianticUser({ endpoints, signal })
        .then((res) => {
          if (res.name) {
            setNianticUsername(res.name);
            setComplete(true);
          }
        })
        .catch((err) => {
          if (err.name !== "AbortError") {
            console.error(err);
          }
        });
    }

    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
    // eslint-disable-next-line
  }, [endpoints, accessToken]);
};

const useCheckStageOneAndTwo = ({
  user,
  setStage,
  setError,
  optInErrorText,
  setComplete,
}) => {
  useEffect(() => {
    if (!user) {
      setStage((stage) => ({
        ...stage,
        1: { complete: false },
        2: { complete: false },
      }));
      setComplete(false);
    }

    if (user?.guid) {
      setStage((stage) => ({
        ...stage,
        1: { complete: true },
      }));

      const { legacy_aliases, opt_ins } = user;
      const playerId = legacy_aliases?.length && legacy_aliases[0];
      const optedIn = opt_ins?.op && opt_ins?.rankings;

      if (playerId && optedIn) {
        setStage((stage) => ({
          ...stage,
          1: { complete: true },
          2: { complete: true },
        }));
      } else {
        setError(optInErrorText);
      }
    }
    // eslint-disable-next-line
  }, [user]);
};

const useCheckStageThree = ({
  token,
  endpoints,
  nianticLinkEnabled,
  setStage,
  setComplete,
  setNianticUsername,
}) => {
  useEffect(() => {
    if (token && endpoints && nianticLinkEnabled) {
      if (Object.keys(endpoints).length > 0 && token) {
        if (token) {
          linkAccount({
            token,
            endpoints,
            setStage,
            setComplete,
            setNianticUsername,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [token, endpoints, nianticLinkEnabled]);
};

export { useCheckLink, useCheckStageOneAndTwo, useCheckStageThree };
