export const getCookie = (cName) => {
  const name = `${cName}=`;
  const cDecoded = decodeURIComponent(document.cookie); // to be careful
  const cArr = cDecoded.split("; ");
  let res = "";
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const setCookie = (cName, cValue, expDays = 365) => {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cName}=${cValue};${expires};path=/`;
};

export const isCookieConsented = () => {
  const cookieSubValueMap = {};
  // grab cookie provided by 3rd party app 'OneTrust'
  const consentCookie = getCookie("OptanonConsent");
  /* mapping the sub values of the cookie so as to not
      assume that the desired sub value 'groups=' is always in the same
      location in the string
      */
  if (consentCookie === "") return false;

  consentCookie.split("&").forEach((subValue) => {
    const split = subValue.split("=");
    const [firstValue, secondValue] = split;
    cookieSubValueMap[firstValue] = secondValue;
  });

  if (!("groups" in cookieSubValueMap)) return false;

  const consentChecks = cookieSubValueMap.groups.split(",");
  for (const check of consentChecks) {
    if (!check.includes(":1")) return false;
  }

  return true;
};

export const mockUserConsent = () => {
  const mockConsentCookie =
    "consentId=c20d706f-45ec-4ce6-9c74-897e34470ea7&datestamp=Tue+Feb+01+2022+10%3A24%3A17+GMT-0800+(Pacific+Standard+Time)&version=6.12.0&interactionCount=1&isIABGlobal=false&hosts=&landingPath=NotLandingPage&groups=C0002%3A1%2CC0001%3A1%2CC0004%3A1%2CC0003%3A1";
  // Create cookie that dismisses consent banner
  setCookie("OptanonAlertBoxClosed", new Date().toISOString());
  // Create the target cookie we search for to validate consent
  setCookie("OptanonConsent", mockConsentCookie);
};
