import React, { useEffect, useContext, useMemo } from "react";
import { SettingsContext } from "contexts/SettingsContext";
import { UrlContext } from "contexts/UrlContext";
import jquery from "jquery";

function Gus() {
  const {
    state: { modal },
  } = useContext(SettingsContext);
  const { getUrlLocale } = useContext(UrlContext);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (jquery(".pokemon-gus-container").GlobalUtilityStrip && intervalId) {
        jquery(".pokemon-gus-container").GlobalUtilityStrip();
        clearInterval(intervalId);
      }
    }, 200);
    window.jQuery = jquery;
    const styles = document.createElement("link");
    styles.type = "text/css";
    styles.href = "//assets.pokemon.com/static2/_ui/css/gus.css";
    styles.rel = "stylesheet";
    const script = document.createElement("script");
    script.src = "//assets.pokemon.com/static2/_ui/js/gus.js";
    script.async = true;
    document.body.appendChild(script);
    document.body.appendChild(styles);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(styles);
    };
  }, []);
  const hideGus = !!modal;
  const urlLocale = getUrlLocale();

  return useMemo(() => {
    const gusStyle = hideGus
      ? { maxHeight: 0, minHeight: 0, overflow: "hidden" }
      : { minHeight: 50, flexShrink: 0 };
    const gusClass = `pokemon-gus-container${hideGus ? " hidden" : ""}`;

    return (
      <div
        style={gusStyle}
        className={gusClass}
        data-site="eventLocator"
        data-testid="gus-holder"
        data-locale={urlLocale}
        data-api="https://www.pokemon.com/api/gus/"
      />
    );
  }, [hideGus, urlLocale]);
}
export default Gus;
