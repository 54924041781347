// node modules
import React, { useContext } from "react";

// internal imports
import { ImgByTag } from "../constants";
import { PremierChampionships } from "./constants";

// styles
import "compiled/css/championshipFilters.css";

// contexts
import { FiltersContext, FiltersConstants } from "contexts/FiltersContext";
import { LocalizationContext } from "contexts/LocalizationContext";
import { useTranslation } from "react-i18next";

function FilterButton({ tag }) {
  const {
    state: { activeFilters },
    toggleFilter,
  } = useContext(FiltersContext);
  const {
    state: { currentLocale },
  } = useContext(LocalizationContext);
  const { t } = useTranslation();
  const label = t(FiltersConstants.Filters[tag].label);
  const handleClick = () => {
    toggleFilter(tag);
  };
  const active = activeFilters[tag];

  const img = ImgByTag[tag];

  const filterImg = `/images/${img}.png`;
  let btnClass = `championship-filter`;
  if (active) btnClass += ` active`;

  const testId = `${tag}_championship_filter`;

  return (
    <div
      className={btnClass}
      onClick={handleClick}
      onKeyPress={handleClick}
      data-testid={testId}
      tabIndex={0}
    >
      <img className="sub-type-icon" src={filterImg} alt={label} />
      <div className="label" lang={currentLocale.toLowerCase()}>
        {label}
      </div>
    </div>
  );
}

function ChampionshipFilters() {
  const renderChampionshipFilters = () => (
    <div>
      <div
        className="championship-group"
        data-testid="championship_filter_buttons"
      >
        {PremierChampionships.tags.map((tag) => (
          <FilterButton tag={tag} key={tag} />
        ))}
      </div>
    </div>
  );

  return (
    <div
      className="championship-filters-container"
      data-testid="championship_filters"
    >
      <div className="championship-filters-label">
        {PremierChampionships.label}
      </div>
      <div className="championships-container">
        {renderChampionshipFilters()}
      </div>
    </div>
  );
}

export default ChampionshipFilters;
