import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { SettingsContext } from "contexts/SettingsContext";
import { EnvironmentContext, Applications } from "contexts/EnvironmentContext";
import "compiled/css/infoNotifier.css";
import { SkinContext } from "contexts/SkinContext";
import { useTranslation } from "react-i18next";

function LocationNotifier() {
  const { t } = useTranslation();
  const {
    state: { geoBlocked },
  } = useContext(SettingsContext);
  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const {
    state: { welcomeImage, welcomeImageAlt },
  } = useContext(SkinContext);

  const header =
    application === Applications.EventLocator
      ? t("LOCATION_REQUEST_HEADER")
      : t("FIND_POKEMON_PRODUCTS");

  let prompt;
  if (application === Applications.RetailLocator) {
    prompt = t("FIND_RETAIL_BLURB");
  } else if (geoBlocked) {
    prompt = t("LOCATION_SEARCH_PROMPT");
  } else {
    prompt = t("LOCATION_REQUEST_PROMPT");
  }

  return (
    <div id="info-notifier" data-testid="info-notifier">
      <img
        className="icon"
        src="/icons/event-location.svg"
        alt="event location"
      />
      <div className="title-and-body">
        <div className="title">
          <div className="text">{header}</div>
        </div>

        <div className="body">
          <div className="content">{prompt}</div>
          <div className="art-row">
            <div className="art-holder">
              <img src={welcomeImage} alt={welcomeImageAlt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(LocationNotifier);
