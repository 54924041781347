import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultI18NextOptions } from "@tpci/i18next-ext";

import translationsEN from "./locales/en-us/translation.json";
import translationsEN_GB from "./locales/en-gb/translation.json";
import translationsFR from "./locales/fr/translation.json";
import translationsES_ES from "./locales/es-es/translation.json";
import translationsES_MX from "./locales/es-mx/translation.json";
import translationsIT from "./locales/it/translation.json";
import translationsDE from "./locales/de/translation.json";
import translationsPT from "./locales/pt/translation.json";
import translationsDev from "./locales/dev/translation.json";

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    ...defaultI18NextOptions,
    fallbackLng(code) {
      const supportedLanguages = [];
      if (process.env.NODE_ENV === "development") {
        return ["dev"];
      }
      switch (code) {
        case "en-CA":
        case "en-IN":
        case "en-NZ":
        case "en-ZA":
        case "en-US":
          supportedLanguages.push("en");
          break;
        case code?.match(/(en).*/)?.input:
          supportedLanguages.push("en-gb");
          break;
        case code?.match(/(fr).*/)?.input:
          supportedLanguages.push("fr");
          break;
        case code?.match(/(it).*/)?.input:
          supportedLanguages.push("it");
          break;
        case code?.match(/(de).*/)?.input:
          supportedLanguages.push("de");
          break;
        case "es-ES":
          supportedLanguages.push("es-es");
          break;
        case code?.match(/(es).*/)?.input:
          supportedLanguages.push("es-mx");
          break;
        case "pt-BR":
          supportedLanguages.push("pt");
          break;
        default:
          supportedLanguages.push("en");
          break;
      }
      supportedLanguages.push("dev");
      return supportedLanguages;
    },
    detection: {
      // order and from where user language should be detected
      order: ["path", "querystring", "sessionStorage", "navigator"],
      // keys or params to lookup language from
      lookupSessionStorage: "lng",
    },
    interpolation: {
      escapeValue: false,
    },
    /* Temp: comment this back in to make sure it works*/
    // lng: "fr",
    supportedLngs: [
      "en",
      "en-gb",
      "es",
      "es-es",
      "es-mx",
      "fr",
      "it",
      "de",
      "pt",
      "dev",
    ],
    resources: {
      dev: {
        translation: translationsDev,
      },
      en: {
        translation: translationsEN,
      },
      "en-gb": {
        translation: translationsEN_GB,
      },
      fr: {
        translation: translationsFR,
      },
      it: {
        translation: translationsIT,
      },
      de: {
        translation: translationsDE,
      },
      "es-ES": {
        translation: translationsES_ES,
      },
      "es-MX": {
        translation: translationsES_MX,
      },
      pt: {
        translation: translationsPT,
      },
    },
    ns: ["translation"],
    defaultNS: "translation",
    load: "currentOnly",
  });

export default i18n;
