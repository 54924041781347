import React, { useMemo } from "react";
import { UrlProvider } from "contexts/UrlContext";
import { EventsProvider } from "contexts/EventsContext";
import { FiltersProvider } from "contexts/FiltersContext";
import { EnvironmentProvider } from "contexts/EnvironmentContext";
import { LocalizationProvider } from "contexts/LocalizationContext";
import { ReactiveProvider } from "contexts/ReactiveContext";
import { SettingsProvider } from "contexts/SettingsContext";
import { SkinProvider } from "contexts/SkinContext";

/**
 * Any Provider or Router that needs to provide context to the entire app goes here.*
 * Then use just use <ProviderProvider/> where you'd usually use <div id="App"/>.*
 */

function ProviderProvider({ children }) {
  const Content = () => useMemo(() => <div id="App">{children}</div>, []);
  return (
    <EnvironmentProvider>
      <UrlProvider>
        <SettingsProvider>
          <LocalizationProvider>
            <EventsProvider>
              <FiltersProvider>
                <SkinProvider>
                  <ReactiveProvider>
                    <Content />
                  </ReactiveProvider>
                </SkinProvider>
              </FiltersProvider>
            </EventsProvider>
          </LocalizationProvider>
        </SettingsProvider>
      </UrlProvider>
    </EnvironmentProvider>
  );
}

export default ProviderProvider;
