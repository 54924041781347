import React, { useContext, useEffect } from "react";
import {
  EnvironmentContext,
  Applications,
  Environments,
} from "contexts/EnvironmentContext";
import { SettingsContext } from "contexts/SettingsContext";
import { Helmet } from "react-helmet";
import HeaderBar from "components/HeaderBar/Bar";
import PageHolder from "components/PageHolder";
import Gus from "components/Gus";
import "./compiled/css/EventLocator.css";
import { Switch, Route, Redirect } from "react-router-dom";
import PGo from "components/PGo/PGo";
import { MonitoringService } from "utils/monitoring-service";
import { useTranslation } from "react-i18next";

/**
 * An App for helping fans find nearby events, leagues and tournaments.*
 * If you're just seeing how this all works, check out PageHolder.*
 */

function Header() {
  const { t } = useTranslation();

  const {
    state: { application },
  } = useContext(EnvironmentContext);
  const title =
    application === Applications.RetailLocator
      ? t("RETAIL_TITLE")
      : t("EVENT_LOCATOR_TITLE");

  // Let's hide the title if it's still loading
  const finalTitle = title.includes(":") ? "Pokémon" : title;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{finalTitle}</title>
    </Helmet>
  );
}

function Routes() {
  const {
    state: { environment },
  } = useContext(EnvironmentContext);

  const renderPGo =
    environment === Environments.DEV ||
    environment === Environments.SKULL ||
    environment === Environments.STAGING ||
    environment === Environments.LOCAL;

  return (
    <Switch>
      <Route path="/:locale/nianticlink">
        {renderPGo ? <PGo /> : <Redirect to="/" />}
      </Route>
      <Route path="/">
        <HeaderBar />
        <PageHolder />
      </Route>
    </Switch>
  );
}

function EventLocator() {
  const {
    state: { hideHeader },
  } = useContext(SettingsContext);

  useEffect(() => {
    MonitoringService.init();
    MonitoringService.startSession();
  }, []);

  return (
    <>
      {!hideHeader && (
        <>
          <Header />
          <Gus />
        </>
      )}

      <Routes />
    </>
  );
}

export default EventLocator;
