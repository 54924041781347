import React, { useReducer, useEffect, useContext, useMemo } from "react";
import { useSearchParams } from "hooks";
import { EnvironmentContext } from "contexts/EnvironmentContext";
import exact from "prop-types-exact";
import PropTypes from "prop-types";
import { USER } from "utils/propTypes";
const SettingsContext = React.createContext();

const AppVersion = {
  version: "1.1.0",
  deployDate: "12/21/2021",
};

const Actions = {
  ToggleMap: "TOGGLE_MAP",
  ToggleMenu: "TOGGLE_MENU",
  SetModal: "SET_MODAL",
  SetLoginToken: "SET_LOGIN_TOKEN",
  StartLogin: "START_LOGIN",
  EndLogin: "END_LOGIN",
  BlockGeo: "BLOCK_GEO",
  LogOut: "LOG_OUT",
  SetUser: "SET_USER",
  SetAccessTokens: "SET_ACCESS_TOKENS",
  SetCart: "SET_CART",
  ShowMap: "SHOW_MAP",
  SetPaymentIntent: "SET_PAYMENT_INTENT",
  SetRegistrationStatus: "SET_REGISTRATION_STATUS",
  AcceptCookies: "ACCEPT_COOKIES",
  HideHeader: "HIDE_HEADER",
  HideFooter: "HIDE_FOOTER",
};
const Modals = {
  Login: "LOGIN",
  Registration: "REGISTRATION",
  LeavingSite: "LEAVING_SITE",
  ChildBlock: "CHILD_BLOCK",
  Filters: "FILTERS",
  OptIn: "OPTIN",
  Confirmation: "CONFIRMATION",
};

const initialSettingsState = {
  showMap: true,
  showMenu: false,
  modal: null,
  geoBlocked: false,
  loginStarted: false,
  access_token: null,
  intendedExitUrl: null,
  refresh_token: null,
  cookiesAccepted: false,
  registrationStatus: null,
  cart: [],
  registrations: [],
  paymentIntentBySku: {},
  user: null,
  hideFooter: false,
  hideHeader: false,
};

const SettingsReducer = (state, { type, value }) => {
  switch (type) {
    case Actions.ToggleMap:
      return {
        ...state,
        showMap: !state.showMap,
      };
    case Actions.ShowMap:
      return {
        ...state,
        showMap: true,
      };
    case Actions.ToggleMenu:
      return {
        ...state,
        showMenu: value,
      };
    case Actions.SetModal:
      return {
        ...state,
        modal: value,
      };
    case Actions.LogOut:
      return {
        ...state,
        user: null,
        access_token: null,
        loginStarted: false,
        modal: null,
      };
    case Actions.IntendToLeave:
      return {
        ...state,
        modal: Modals.LeavingSite,
        intendedExitUrl: value,
      };
    case Actions.BlockGeo:
      return {
        ...state,
        geoBlocked: true,
      };
    case Actions.SetLoginToken:
      return {
        ...state,
        loginToken: value,
      };
    case Actions.StartLogin:
      return {
        ...state,
        loginStarted: true,
      };
    case Actions.EndLogin:
      return {
        ...state,
        loginStarted: false,
      };
    case Actions.SetPaymentIntent:
      return {
        ...state,
        paymentIntentBySku: {
          ...state.paymentIntentBySku,
          [value.sku]: value.intent,
        },
      };
    case Actions.SetAccessTokens:
      return {
        ...state,
        refresh_token: value.refresh_token,
        access_token: value.access_token,
      };
    case Actions.SetUser:
      return {
        ...state,
        user: value.user,
        loginStarted: false,
        modal: null,
      };
    case Actions.SetCart:
      return {
        ...state,
        cart: value,
      };
    case Actions.SetRegistrationStatus:
      return {
        ...state,
        registrationStatus: value.status,
        registrations: value.registrations,
      };
    case Actions.AcceptCookies:
      return {
        ...state,
        cookiesAccepted: true,
      };
    case Actions.HideFooter:
      return {
        ...state,
        hideFooter: true,
      };
    case Actions.HideHeader:
      return {
        ...state,
        hideHeader: true,
      };
    default:
      return { ...state };
  }
};

function SettingsProvider({ children }) {
  const [state, dispatch] = useReducer(SettingsReducer, initialSettingsState);
  const environment = useContext(EnvironmentContext);
  const { location } = window;
  const searchParams = useSearchParams(location?.search);
  const hideHeader = searchParams.get("hideHeader");
  const hideFooter = searchParams.get("hideFooter");

  const toggleMap = () => {
    dispatch({ type: Actions.ToggleMap });
  };

  const showMap = () => {
    dispatch({ type: Actions.ShowMap });
  };

  const togglePaymentIntent = ({ sku, intent }) => {
    dispatch({ type: Actions.SetPaymentIntent, value: { sku, intent } });
  };

  const setModal = (modal) => {
    dispatch({ type: Actions.SetModal, value: modal });
  };
  const toggleMenu = (showMenu) => {
    dispatch({ type: Actions.ToggleMenu, value: showMenu });
  };
  const startLogin = () => {
    dispatch({ type: Actions.StartLogin });
  };
  const endLogin = () => {
    dispatch({ type: Actions.EndLogin });
  };
  const setLoginToken = () => {
    dispatch({ type: Actions.SetLoginToken });
  };

  const blockGeo = () => {
    dispatch({ type: Actions.BlockGeo });
  };

  const onLogin = (user, access_token) => {
    dispatch({
      type: Actions.SetUser,
      value: {
        user,
      },
    });
    dispatch({
      type: Actions.SetAccessTokens,
      value: { access_token },
    });
  };

  const logOut = () => {
    dispatch({ type: Actions.LogOut });
  };

  const intendToLeave = (url) => {
    const isInternal = url.includes("pokemon.com");
    if (isInternal) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      dispatch({ type: Actions.IntendToLeave, value: url });
    }
  };

  const clearCart = () => {
    dispatch({
      type: Actions.SetCart,
      value: [],
    });
  };

  const acceptCookies = () => {
    dispatch({
      type: Actions.AcceptCookies,
    });
  };

  const startRegistration = () => {
    dispatch({
      type: Actions.SetRegistrationStatus,
      value: { status: "STARTED", registrations: [] },
    });
  };

  const finishRegistration = () => {
    dispatch({
      type: Actions.SetRegistrationStatus,
      value: { status: "FINISHED", registrations: [] },
    });
  };

  const getUser = async () => {
    const access_token = window.localStorage.getItem("accessToken");
    if (!access_token) {
      // No access token, user not logged in.
      endLogin();
      return;
    }
    environment
      .request({
        endpoint: environment.state.endpoints.GET_USER,
        args: {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
      })
      .then((user) => {
        onLogin(user, access_token);
      })
      .catch(() => {
        window.localStorage.removeItem("accessToken");
        endLogin();
      });
  };

  useEffect(() => {
    window.envDebug = () => {
      const consoleLogs = [
        `Version: ${AppVersion.version}`,
        `Deployed on ${AppVersion.deployDate}`,
        `Environment File: ${environment.state.environment}`,
        `Process Env REACT_APP_ENV: ${process.env.REACT_APP_ENV}`,
      ];
      console.info(consoleLogs.join(`\n`));
      return "Environment Debug above";
    }; // eslint-disable-next-line
  }, [environment]);

  // gets user from access token (helps with rehydrating login after a page refresh, for ex)
  useEffect(() => {
    if (environment.state.endpoints.GET_USER && !state.user) {
      startLogin();
    } // eslint-disable-next-line
  }, [state.access_token, environment.state.endpoints.GET_USER]);

  useEffect(() => {
    if (state.loginStarted) {
      getUser();
    }
    /* eslint-disable-next-line */
  }, [state.loginStarted]);

  useEffect(() => {
    if (hideHeader?.toLowerCase() === "true") {
      dispatch({ type: Actions.HideHeader });
    }
    if (hideFooter?.toLowerCase() === "true") {
      dispatch({ type: Actions.HideFooter });
    }
  }, [hideHeader, hideFooter]);

  const contextValue = useMemo(() => {
    const toggleFromCart = (item) => {
      const newCart = state.cart.filter(
        (x) => x.sku !== item.sku || x.user !== item.user
      );
      const isAdd = newCart.length === state.cart.length;
      if (isAdd) {
        dispatch({
          type: Actions.SetCart,
          value: [...state.cart, item],
        });
      } else {
        dispatch({
          type: Actions.SetCart,
          value: newCart,
        });
      }
    };

    const onLogout = () => {
      window.localStorage.removeItem("accessToken");
      logOut();
    };

    return {
      state,
      tabBlock: !!state.modal,
      dispatch,
      toggleMap,
      showMap,
      toggleMenu,
      setLoginToken,
      onLogout,
      clearCart,
      blockGeo,
      onLogin,
      startLogin,
      toggleFromCart,
      setModal,
      togglePaymentIntent,
      intendToLeave,
      startRegistration,
      finishRegistration,
      acceptCookies,
    };
  }, [state]);
  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
}

SettingsContext.Provider.propTypes = exact({
  value: PropTypes.exact({
    state: PropTypes.exact({
      showMap: PropTypes.bool.isRequired,
      showMenu: PropTypes.bool.isRequired,
      modal: PropTypes.string,
      geoBlocked: PropTypes.bool.isRequired,
      loginStarted: PropTypes.bool.isRequired,
      access_token: PropTypes.string,
      intendedExitUrl: PropTypes.string,
      refresh_token: PropTypes.string,
      cookiesAccepted: PropTypes.bool.isRequired,
      registrationStatus: PropTypes.oneOf(["STARTED", "FINISHED"]),
      cart: PropTypes.arrayOf(PropTypes.object).isRequired,
      registrations: PropTypes.array.isRequired,
      paymentIntentBySku: PropTypes.object.isRequired,
      user: USER,
      hideHeader: PropTypes.bool,
      hideFooter: PropTypes.bool,
    }),
    tabBlock: PropTypes.bool,
    dispatch: PropTypes.func,
    toggleMap: PropTypes.func,
    showMap: PropTypes.func,
    toggleMenu: PropTypes.func,
    setLoginToken: PropTypes.func,
    onLogout: PropTypes.func,
    clearCart: PropTypes.func,
    blockGeo: PropTypes.func,
    onLogin: PropTypes.func,
    startLogin: PropTypes.func,
    toggleFromCart: PropTypes.func,
    setModal: PropTypes.func,
    togglePaymentIntent: PropTypes.func,
    intendToLeave: PropTypes.func,
    startRegistration: PropTypes.func,
    finishRegistration: PropTypes.func,
    acceptCookies: PropTypes.func,
  }),
  children: PropTypes.node,
});

export { SettingsContext, SettingsProvider, Modals };
