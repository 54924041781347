import React, { useReducer, useContext, useMemo } from "react";
import { fetchEvents, fetchEvent } from "apis/events";
import { EnvironmentContext, Environments } from "contexts/EnvironmentContext";
import { addMockEvents } from "tests/constants/filters";
import PropTypes from "prop-types";
import exact from "prop-types-exact";

const EventsContext = React.createContext();

const EventsConstants = {
  Actions: {
    FetchEvents: "FETCH_EVENTS",
    UnfetchEvents: "UNFETCH_EVENTS",
    ReceiveEvents: "RECEIVE_EVENTS",
    ReceiveEvent: "RECEIVE_EVENT",
    FailEventLoad: "FAIL_EVENT_LOAD",
  },
  FetchStatuses: {
    NotStarted: "NOT_STARTED",
    InProgress: "IN_PROGRESS",
    Complete: "COMPLETE",
    Failed: "FAILED",
  },
};
const MarkByCurrency = {
  USD: "$",
};

const { Actions, FetchStatuses } = EventsConstants;

const initialEventsState = {
  eventsFetchStatus: FetchStatuses.NotStarted,
  eventIds: [],
  skusById: {},
  eventSearchLocation: null,
  searchableEventsById: {},
  childEventsById: {},
  searchableChildEventsById: {},
};

const EventsReducer = (state, { type, value }) => {
  switch (type) {
    case Actions.FetchEvents:
      return {
        ...state,
        eventsFetchStatus: FetchStatuses.InProgress,
        eventSearchLocation: value.location,
      };
    case Actions.UnfetchEvents:
      return { ...initialEventsState };
    case Actions.ReceiveEvents:
      return {
        ...state,
        eventsFetchStatus: FetchStatuses.Complete,
        eventIds: value.eventIds,
        searchableEventsById: value.searchableEventsById,
        childEventsById: value.childEventsById,
        searchableChildEventsById: value.searchableChildEventsById,
      };
    case Actions.FailEventLoad:
      return {
        ...state,
        eventsFetchStatus: FetchStatuses.Failed,
      };
    case Actions.ReceiveEvent:
      return {
        ...state,
        searchableEventsById: {
          ...state.searchableEventsById,
          [value.event.id]: value.event,
        },
        skusById: { ...state.skusById, ...value.skusById },
      };
    default:
      return { ...state };
  }
};

function EventsProvider({ children, defaultValues }) {
  const defaultState = defaultValues || {};
  const [state, dispatch] = useReducer(EventsReducer, {
    ...initialEventsState,
    ...defaultState,
  });
  const environment = useContext(EnvironmentContext);

  const clearEvents = () => {
    dispatch({ type: Actions.UnfetchEvents });
  };

  const completeActivityGroup = (event) => {
    dispatch({
      type: Actions.ReceiveEvent,
      value: {
        event: {
          ...event,
          address: {
            ...event.address,
          },
          fetchFailed: false,
          complete: true,
        },
      },
    });
  };

  const addEventGeoData = (event) => {
    event.lat = Number(event.address.latitude);
    event.lng = Number(event.address.longitude);
    const { distance } = event;
    event.distance =
      distance < 10 ? Math.floor(distance * 10) / 10 : Math.floor(distance);
    return event;
  };

  const contextValue = useMemo(() => {
    const receiveEvents = ({ events, activityGroups, error }) => {
      if (error) {
        console.error({ error });
        dispatch({ type: Actions.FailEventLoad });
        return;
      }

      const childEventsById = {};
      const eventIds = [];
      const searchableEventsById = {};
      const searchableChildEventsById = {};
      events.forEach((event) => {
        addEventGeoData(event);
        if (
          event.large_event_guid &&
          !event.league_guid &&
          event.activity_type !== "league"
        ) {
          const { large_event_guid } = event;
          if (!searchableEventsById[large_event_guid]) {
            // creating placeholder event for large event to render in events list
            searchableEventsById[large_event_guid] = {
              ...event,
              id: large_event_guid,
              name: activityGroups[large_event_guid]?.name || event.name,
              isLargeEvent: true,
            };
          }

          if (!searchableChildEventsById[event.guid]) {
            searchableChildEventsById[event.guid] = {
              ...event,
            };
          }

          if (!childEventsById[large_event_guid]) {
            eventIds.push(large_event_guid);
            childEventsById[large_event_guid] = {
              ...activityGroups[large_event_guid],
              children: [event],
            };
          } else {
            childEventsById[large_event_guid].children.push(event);
          }
        } else {
          searchableEventsById[event.id] = event;
          eventIds.push(event.id);
        }
      });

      dispatch({
        type: Actions.ReceiveEvents,
        value: {
          searchableEventsById,
          eventIds,
          childEventsById,
          searchableChildEventsById,
        },
      });
    };

    const setLocation = ({ location, sortAttr, distance }) => {
      dispatch({ type: Actions.FetchEvents, value: { location } });
      const { lat, lng } = location;
      const roundDist = Math.round(distance);

      fetchEvents({ environment, lat, lng, sortAttr, distance: roundDist })
        .then(({ events, activityGroups }) => {
          const isDevEnv = environment.state.environment === Environments.DEV;

          receiveEvents({
            /*
              Adding ability to add mock events so one can address minor visual issues quickly
              as edge case events don't always exist within our mock db
              */
            events: isDevEnv ? addMockEvents(events) : events,
            activityGroups,
          });
        })
        .catch((error) => {
          receiveEvents({ error });
        });
    };

    const getEventsByTags = ({ tags }) => {
      dispatch({ type: Actions.FetchEvents, value: { location: null } });
      fetchEvents({ environment, tags })
        .then(({ events, activityGroups }) => {
          receiveEvents({ events, activityGroups });
        })
        .catch((error) => {
          receiveEvents({ error });
        });
    };

    const completeEvent = (event) => {
      fetchEvent({ environment, event })
        .then((response) => {
          const skusById = {};
          (response.registration_options || []).forEach((x) => {
            x.mark = MarkByCurrency[x.price_currency] || x.price_currency;
            skusById[x.guid] = x;
          });
          dispatch({
            type: Actions.ReceiveEvent,
            value: {
              event: {
                ...response,
                ...event,
                address: {
                  ...event.address,
                  ...response.address,
                },
                fetchFailed: false,
                complete: true,
              },
              skusById,
            },
          });
        })
        .catch(() => {
          dispatch({
            type: Actions.ReceiveEvent,
            value: { event: { ...event, fetchFailed: true }, skusById: {} },
          });
        });
    };

    return {
      state,
      dispatch,
      clearEvents,
      completeEvent,
      receiveEvents,
      setLocation,
      getEventsByTags,
      completeActivityGroup,
    };
  }, [state, dispatch, environment]);

  return (
    <EventsContext.Provider value={contextValue}>
      {children}
    </EventsContext.Provider>
  );
}

// Wrapped in IIFE to address reference error for the imported variables
(async () => {
  const { EVENT } = await import("utils/propTypes");

  EventsContext.Provider.propTypes = exact({
    value: PropTypes.exact({
      clearEvents: PropTypes.func,
      completeActivityGroup: PropTypes.func,
      completeEvent: PropTypes.func,
      dispatch: PropTypes.func,
      getEventsByTags: PropTypes.func,
      receiveEvents: PropTypes.func,
      setLocation: PropTypes.func,
      state: PropTypes.exact({
        childEventsById: PropTypes.shape({
          [PropTypes.string]: EVENT,
        }),
        eventIds: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        eventSearchLocation: PropTypes.exact({
          lat: PropTypes.number,
          lng: PropTypes.number,
          address_components: PropTypes.arrayOf(
            PropTypes.exact({
              long_name: PropTypes.string,
              short_name: PropTypes.string,
              types: PropTypes.arrayOf(PropTypes.string),
            })
          ),
          formatted_address: PropTypes.string,
          geometry: PropTypes.exact({
            bounds: PropTypes.object,
            location: PropTypes.exact({
              lat: PropTypes.func,
              lng: PropTypes.func,
            }),
            location_type: PropTypes.string,
            viewport: PropTypes.object,
          }),
          place_id: PropTypes.string,
          plus_code: PropTypes.string,
          types: PropTypes.arrayOf(PropTypes.string),
        }),
        eventsFetchStatus: PropTypes.oneOf(Object.values(FetchStatuses)),
        searchableChildEventsById: PropTypes.shape({
          [PropTypes.string]: EVENT,
        }),
        searchableEventsById: PropTypes.shape({
          [PropTypes.string]: EVENT,
        }),
        skusById: PropTypes.shape({
          [PropTypes.string]: PropTypes.array,
        }),
      }),
    }),
    children: PropTypes.node,
  });
})();

export { EventsContext, EventsProvider, EventsConstants };
