import React, { useContext, useEffect } from "react";
import "compiled/css/controls/checkbox.css";

// contexts
import { FiltersConstants, FiltersContext } from "contexts/FiltersContext";
import { EventsContext } from "contexts/EventsContext";
import { useTranslation } from "react-i18next";

function ChampionshipToggle() {
  const { t } = useTranslation();
  const {
    state: { isChampionshipView },
    toggleChampionshipView,
    resetActiveFilters,
  } = useContext(FiltersContext);
  const { getEventsByTags, clearEvents } = useContext(EventsContext);

  useEffect(() => {
    if (isChampionshipView) {
      getEventsByTags({
        tags: FiltersConstants.ChampionshipTags,
      });
    }
    // eslint-disable-next-line
  }, [isChampionshipView]);

  const toggleChampionships = () => {
    if (!isChampionshipView) {
      getEventsByTags({
        tags: FiltersConstants.ChampionshipTags,
      });
      toggleChampionshipView(true);
    } else {
      clearEvents();
      resetActiveFilters();
      toggleChampionshipView(false);
    }
  };

  return (
    <div className="checkbox-container">
      <div className="checkbox">
        <input
          type="checkbox"
          data-testid="champ_toggle"
          onChange={toggleChampionships}
          onKeyPress={toggleChampionships}
          checked={Boolean(isChampionshipView) || false}
          id="Champ_Checkbox"
        />
        <label htmlFor="Champ_Checkbox">{t("CHAMPIONSHIPS_TOGGLE")}</label>
      </div>
    </div>
  );
}

export default ChampionshipToggle;
