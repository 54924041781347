import React, { useReducer, useEffect, useMemo } from "react";
import { flatify } from "utils/badBrowserHelpers";
import PropTypes from "prop-types";
import exact from "prop-types-exact";

const EnvironmentContext = React.createContext();

const Environments = {
  PROD: "prod",
  STAGING: "staging",
  LOCAL: "local",
  DEV: "dev",
  SKULL: "skull",
};

const Applications = {
  EventLocator: "EVENT_LOCATOR",
  RetailLocator: "RETAIL_LOCATOR",
  PokestopLocator: "POKESTOP_LOCATOR",
};

const Actions = {
  SetEnvironment: "SET_ENVIRONMENT",
  SetLoginInfo: "SET_LOGIN_INFO",
  SetLoginCode: "SET_LOGIN_CODE",
  SetApplication: "SET_APPLICATION",
};

const initialEnvironmentState = {
  environment: process.env.REACT_APP_ENV || Environments.LOCAL,
  codeVerifier: null,
  application: Applications.EventLocator,
  loginUrl: null,
  loginCode: null,
  loginEnabled: false,
  endpoints: {},
};

const request = ({ endpoint, args }) =>
  new Promise((resolve, reject) => {
    fetch(buildEndpoint(endpoint, args), {
      method: args?.method || "GET",
      headers: (args || {}).headers,
      body: (args || {}).body,
      // used to help abort the request (useful for useEffect cleanup)
      signal: args?.signal,
    })
      .then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        response.json().then((result) => {
          resolve(result);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

function buildEndpoint(endpoint, args) {
  return flatify([endpoint])
    .map((segment) => translate({ segment, args }))
    .join("");
}

const go = (endpoint, args) => {
  const final = buildEndpoint(endpoint, args);
  window.open(final, "_blank");
};

function translate({ segment, args }) {
  if (!segment) {
    return "";
  }
  const { arg, base } = segment;
  if (arg) {
    return args[arg] || base;
  }
  return segment;
}

const EnvironmentReducer = (state, { type, value }) => {
  switch (type) {
    case Actions.SetEnvironment:
      return {
        ...state,
        ...value,
      };
    case Actions.SetLoginInfo:
      return {
        ...state,
        loginUrl: value.loginUrl,
        codeVerifier: value.codeVerifier,
      };
    case Actions.SetLoginCode:
      return {
        ...state,
        loginCode: value,
      };
    case Actions.SetApplication:
      return {
        ...state,
        application: value,
      };
    default:
      return { ...state };
  }
};

function EnvironmentProvider({ children, defaultValues }) {
  const defaultProps = defaultValues || {};
  const [state, dispatch] = useReducer(EnvironmentReducer, {
    ...initialEnvironmentState,
    ...defaultProps,
  });
  const { environment } = state;

  const receiveStorage = () => {
    const code = window.localStorage.getItem("loginCode");
    dispatch({ type: Actions.SetLoginCode, value: code });
  };

  useEffect(() => {
    const newEnv = require(`environments/${environment}.json`);
    dispatch({ type: Actions.SetEnvironment, value: newEnv });
  }, [environment]);

  useEffect(() => {
    window.addEventListener("storage", receiveStorage);
    return () => {
      window.removeEventListener("storage", receiveStorage);
    };
  }, []);

  const contextValue = useMemo(() => {
    const setApplication = (mode) => {
      dispatch({
        type: Actions.SetApplication,
        value: mode,
      });
    };
    return {
      state,
      dispatch,
      request,
      go,
      buildEndpoint,
      setApplication,
    };
  }, [state, dispatch]);

  return (
    <EnvironmentContext.Provider value={contextValue}>
      {children}
    </EnvironmentContext.Provider>
  );
}

EnvironmentContext.Provider.propTypes = exact({
  value: PropTypes.exact({
    buildEndpoint: PropTypes.func,
    dispatch: PropTypes.func,
    go: PropTypes.func,
    request: PropTypes.func,
    setApplication: PropTypes.func,
    state: PropTypes.exact({
      application: PropTypes.string,
      codeVerifier: PropTypes.string,
      // Testing prop
      endpoint: PropTypes.object,
      endpoints: PropTypes.object,
      environment: PropTypes.oneOf(Object.values(Environments)),
      loginCode: PropTypes.string,
      loginEnabled: PropTypes.bool.isRequired,
      loginUrl: PropTypes.string,
      mapsKey: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
});

export {
  EnvironmentContext,
  EnvironmentProvider,
  Environments,
  Applications,
  request,
};
