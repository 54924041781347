import "compiled/css/infoNotifier.css";

import { SkinContext } from "contexts/SkinContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

function FetchFailedNotifier() {
  const { t } = useTranslation();
  const {
    state: { errorImage, errorImageAlt },
  } = useContext(SkinContext);

  return (
    <div id="info-notifier" data-testid="info-notifier">
      <img
        className="icon"
        src="/icons/event-location.svg"
        alt="event-location-icon"
      />
      <div className="title-and-body">
        <div className="title">{t("FETCH_ERROR_HEADER")}</div>

        <div className="body">
          <div className="content">{t("FETCH_ERROR_EXPLANATION")}</div>
          <img src={errorImage} alt={errorImageAlt} />
        </div>
      </div>
    </div>
  );
}

export default FetchFailedNotifier;
