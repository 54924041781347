import { LocaleKeyByCode } from "contexts/LocalizationContext";
import React, { useEffect, useMemo, useReducer } from "react";
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import { DistanceUnitByCode } from "./LocalizationContext";
import exact from "prop-types-exact";
import PropTypes from "prop-types";
import { flatify } from "utils/badBrowserHelpers";
import { convertDistance } from "./helpers/converters";

const UrlContext = React.createContext();
const KnownPages = [
  "locations",
  "events",
  "retail",
  "tournaments",
  "sessions",
  "premiers",
  "leagues",
  "loggedIn",
  "nianticlink",
  "group",
];
const DetailPages = [
  "tournaments",
  "sessions",
  "premiers",
  "leagues",
  "retail",
  "group",
];

const PageByType = {
  league: "sessions",
  tournament: "tournaments",
  premier: "premiers",
};

const initialUrlState = {
  listViewSeen: false,
};

const UrlReducer = (state, { type }) => {
  switch (type) {
    case "SEE_LIST_VIEW":
      return {
        ...state,
        listViewSeen: true,
      };
    default:
      return { ...state };
  }
};

function Provider({ children }) {
  const [state, dispatch] = useReducer(UrlReducer, initialUrlState);
  const location = useLocation();
  const history = useHistory();
  const [locale, page, ...others] = location.pathname.split("/").slice(1);

  useEffect(() => {
    const localeKey = LocaleKeyByCode[locale];
    const pageKnown = KnownPages.includes(page);
    if (!localeKey || !pageKnown) {
      const newLocale = localeKey ? locale : "en-us";
      const knownPage = pageKnown ? page : "";
      const unit = DistanceUnitByCode[locale];

      const distance = location.search
        .match(/maxDistance.*/g)?.[0]
        .split("=")[1];

      const convertedDistance = convertDistance(distance, unit);
      const modifySearchDistance = location.search.replace(
        /maxDistance.*/g,
        `maxDistance=${convertedDistance}`
      );
      history.replace({
        pathname: `/${newLocale}/${knownPage}`,
        search: modifySearchDistance,
      });
    } // eslint-disable-next-line
  }, [page]);

  const contextValue = useMemo(() => {
    const seeListView = () => {
      dispatch({ type: "SEE_LIST_VIEW" });
    };

    const getSelectedEvent = () => {
      if (DetailPages.includes(page)) {
        return others[0];
      }
    };

    const replaceToPage = (page) => {
      try {
        history.replace({ pathname: `/${locale}/${page}` });
      } catch (err) {
        // Literally wrapping it is good enough to get default behavior.
      }
    };

    const getUrlLocale = () => locale || "en-us";

    const replaceTo = ({ page: providedPage, search }) => {
      const updatedPage = providedPage || page; // Use a new variable instead of reassigning 'providedPage'
      // need the query params to persist for login to work
      if (updatedPage === "loggedIn") {
        return;
      }

      const realSearch = Object.keys(search || {})
        .map((key) => `${key}=${flatify([search[key]]).join(",")}`)
        .join("&");

      try {
        history.replace({
          pathname: `/${locale}/${updatedPage}`,
          search: realSearch,
        });
      } catch (err) {
        // Literally wrapping it is good enough to get default behavior.
      }
    };

    const pushTo = ({ page, search }) => {
      history.push({
        pathname: `/${locale}/${page}`,
        search: search ? `${search}` : null,
      });
    };

    return {
      state,
      dispatch,
      seeListView,
      getSelectedEvent,
      replaceToPage,
      getUrlLocale,
      replaceTo,
      page,
      pushTo,
    };
  }, [state, dispatch, page, history, locale, others]);

  return (
    <UrlContext.Provider value={contextValue}>{children}</UrlContext.Provider>
  );
}

function UrlProvider({ test, children }) {
  if (test) {
    return <Provider>{children}</Provider>;
  }
  return (
    <Router>
      <Provider>{children}</Provider>
    </Router>
  );
}

UrlContext.Provider.propTypes = exact({
  value: PropTypes.exact({
    state: PropTypes.exact({
      listViewSeen: PropTypes.bool.isRequired,
    }),
    dispatch: PropTypes.func,
    seeListView: PropTypes.func,
    getSelectedEvent: PropTypes.func,
    replaceToPage: PropTypes.func,
    getUrlLocale: PropTypes.func,
    replaceTo: PropTypes.func,
    page: PropTypes.string,
    pushTo: PropTypes.func,
  }),
  children: PropTypes.node,
});

export { UrlContext, UrlProvider, PageByType };
