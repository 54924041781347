export const ImgByTag = {
  regionals: "regionals",
  international_championship: "international",
  worlds: "worlds",
  league_challenge: "league-challenge",
  league_cup: "league-cup",
  midseason_showdown: "midseason-showdown",
  prerelease: "prerelease",
  special_event: "play-logo",
};

export const SvgByTag = {
  tcg: "cards",
  vg: "vg",
  tournament: "tournament",
  league: "league",
};

export const LabelByTag = {
  tcg: "CARDS_LABEL",
  vg: "GAMES_LABEL",
  tournament: "TOURNAMENT_LABEL",
  league: "LEAGUE_LABEL",
  pre_release: "PRE_RELEASE_LABEL",
  league_challenge: "LEAGUE_CHALLENGE_LABEL",
  league_cup: "LEAGUE_CUP_LABEL",
  premier_challenge: "PREMIER_CHALLENGE_LABEL",
  midseason_showdown: "MIDSEASON_SHOWDOWN_LABEL",
  regionals: "REGIONALS_LABEL",
  international_championship: "INTERNATIONAL_CHAMPIONSHIP_LABEL",
  worlds: "WORLDS_LABEL",
};
