import React, { useContext } from "react";
import "compiled/css/account.css";
import { SettingsContext } from "contexts/SettingsContext";
import { useTranslation } from "react-i18next";

function AccountButton() {
  const {
    state: { showMap },
    toggleMap,
  } = useContext(SettingsContext);
  const { t } = useTranslation();

  const mapFilterClass = showMap ? "toggle active" : "toggle";
  return (
    <div onClick={toggleMap} className={mapFilterClass}>
      {t("MAP")}
    </div>
  );
}

export default AccountButton;
