import { Applications } from "contexts/EnvironmentContext";
import { DateService } from "utils/date-service";

const getResourceParameter = (event) => {
  if (event.activity_type === "league") {
    return `activity_group/league/${event.league_guid}`;
  } else if (event.activity_type === "tournament") {
    return `activity/tournament/${event.guid}`;
  } else if (event.activity_type === "premier") {
    return `activity/premier/${event.guid}`;
  }
};

const UrlPathFromFormat = {
  league: "sessions",
  tournament: "tournaments",
  premier: "premiers",
};

const FormatFromUrlPath = {
  sessions: "league",
  tournaments: "tournament",
  premiers: "premier",
};

const DIVISION_ORDER = ["all", "masters, seniors", "juniors"];

const fetchEvents = ({ environment, lat, lng, distance, priority, tags }) => {
  const isRetail = environment.state.application === Applications.RetailLocator;
  const searchType = isRetail ? "retail-search" : "search";

  let endpoint;
  if (tags && tags.length) {
    endpoint = environment.state.endpoints.EVENT_SEARCH_BY_TAGS;
  } else if (isRetail) {
    endpoint = environment.state.endpoints.LOCATION_SEARCH;
  } else {
    endpoint = environment.state.endpoints.EVENT_SEARCH;
  }

  return new Promise((resolve, reject) => {
    environment
      .request({
        endpoint,
        args: { lat, lng, distance, searchType, tags },
      })
      .then((res) => {
        let result = [];

        if (isRetail) {
          result = res;
        } else {
          result = res.activities.filter((x) => !!x);
        }

        result.forEach((x) => {
          if (x) {
            const moment = new DateService().setDate({
              format: "utc",
              date: x.when,
            });
            x.moment = moment;
            x.end = moment.addTime({ duration: 2, unit: "hours" });

            x.id =
              x.guid || x.local_id || `${Math.floor(Math.random() * 999999)}`;
            x.filter_type = x.guid ? "tournament" : "league";
            x.resource_parameter = getResourceParameter(x);
          }
        });
        if (isRetail) {
          const latLngMap = {};
          result = result.filter((x) => {
            const { latitude, longitude } = x.address;
            const key = `${latitude}-${longitude}`;
            if (latLngMap[key]) {
              return false;
            }
            latLngMap[key] = true;
            return true;
          });
        }

        result.sort((a, b) => a[priority] - b[priority]);

        const eventsResObj = {
          events: result,
        };
        if (res.activity_groups) {
          eventsResObj.activityGroups = res.activity_groups;
        }

        resolve(eventsResObj);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchEvent = ({ environment, event: { resource_parameter } }) =>
  new Promise((resolve, reject) => {
    environment
      .request({
        endpoint: environment.state.endpoints.EVENT_DETAIL,
        args: { resource_parameter },
      })
      .then((result) => {
        if (result.registration_options) {
          result.registration_options = result.registration_options.sort((x) =>
            DIVISION_ORDER.indexOf(x.division)
          );
        }
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });

export { fetchEvents, fetchEvent, UrlPathFromFormat, FormatFromUrlPath };
