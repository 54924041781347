import React, { useContext, useState, useEffect } from "react";

// components
import DateControl from "components/Controls/DateControl";
import EventTypeFilters from "./event-type/EventTypeFilters";
import ProductFilters from "./products/ProductFilters";
import DistanceDropdown from "./distance-dropdown/DistanceDropdown";
import ChampionshipToggle from "../ChampionshipToggle";
import ChampionshipFilters from "../championship-filters/ChampionshipFilters";

// context
import { LocalizationContext } from "contexts/LocalizationContext";
import { FiltersContext, FiltersConstants } from "contexts/FiltersContext";

// style
import "compiled/css/filtersDropdown.css";

import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { useTranslation } from "react-i18next";

function Filters({ isExpanded }) {
  const { t } = useTranslation();
  const { getCurrentDistanceUnits } = useContext(LocalizationContext);
  const { unit } = getCurrentDistanceUnits();
  const [moreFiltersOpen, setMoreFiltersOpen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const {
    state: { activeFilters, isChampionshipView },
    resetActiveFilters,
  } = useContext(FiltersContext);
  const { SubEventFilters, PesFilterTags } = FiltersConstants;

  const footerBtnLabel = moreFiltersOpen
    ? t("LESS_OPTIONS_LABEL")
    : t("MORE_OPTIONS_LABEL");
  const toggleMoreOptions = () => setMoreFiltersOpen((value) => !value);
  const handleResetClick = () => resetActiveFilters(unit);

  let filtersContentClass = "filters-content";
  const filtersContainerClass = "filters-container";
  // modify height based when window height reaches certain threshold
  if (moreFiltersOpen) filtersContentClass += " expanded";
  if (windowHeight < 625 && !moreFiltersOpen) {
    filtersContentClass += " variable-height";
  }
  if (windowHeight < 830 && moreFiltersOpen) {
    filtersContentClass += " variable-height-more";
  }

  const activePesFilters = Object.keys(activeFilters).filter(
    (x) => activeFilters[x] && PesFilterTags.includes(x)
  );
  const pesFilterActive = !!activePesFilters.length;

  useEffect(() => {
    const filterKeys = Object.keys(activeFilters);
    if (filterKeys.length) {
      const activeEventFilters = filterKeys.filter((key) => activeFilters[key]);
      const hasSubEvent = activeEventFilters.find(
        (event) => SubEventFilters[event]
      );
      if (hasSubEvent && !isExpanded) {
        setMoreFiltersOpen(true);
      }
    }
    /* eslint-disable-next-line */
  }, [activeFilters]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      setWindowHeight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div className={filtersContainerClass} data-testid="filters_container">
      <div className={filtersContentClass}>
        <div
          className="filters-reset-button"
          onClick={handleResetClick}
          data-testid="reset-button"
        >
          {t("RESET_FILTERS")}
        </div>
        {isExpanded ? <ChampionshipToggle /> : null}
        {isExpanded && isChampionshipView ? (
          <ChampionshipFilters />
        ) : (
          <>
            <div
              className="flex-row"
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              <DistanceDropdown />
              <DateControl />
            </div>
            <ProductFilters />
            <EventTypeFilters
              isExpanded={isExpanded || moreFiltersOpen}
              pesFilterActive={pesFilterActive}
            />
          </>
        )}
      </div>
      {!isExpanded && (
        <>
          <div
            className="footer-button"
            data-testid="more_button"
            tabIndex={0}
            onKeyPress={toggleMoreOptions}
            onClick={toggleMoreOptions}
          >
            {footerBtnLabel}
          </div>
        </>
      )}
    </div>
  );
}

Filters.propTypes = exact({
  isExpanded: PropTypes.bool,
});

export default Filters;
