const register = async ({ cart, environment }) => {
  const payload = cart.map((guid) => ({
    registration_option_guid: guid,
  }));

  try {
    const response = await environment.request({
      endpoint: environment.state.endpoints.REGISTRATION,
      body: payload,
    });
    return response;
  } catch (error) {
    console.error({ error });
  }
};

const LabelByDivision = {
  masters: `MASTER`,
  seniors: `SENIOR`,
  juniors: `JUNIOR`,
  non_op: `NON_OP`,
};

const getEffectiveDivision = ({ birth_year, event, has_agreed_to_terms }) => {
  if (!has_agreed_to_terms) {
    return "non_op";
  }
  const effectiveAge =
    (event || { division_year: new Date().getFullYear() }).division_year -
    birth_year;
  const potentialDivisions = ["masters", "seniors", "juniors"];
  return potentialDivisions.find((division) => {
    const divisionAge = ((event || {}).division_info || {
      masters: 15,
      seniors: 11,
      juniors: 0,
    })[division];
    return effectiveAge >= divisionAge;
  });
};

const getCartCost = ({ cart, skusById, paymentIntentBySku }) => {
  const skus = cart.map((item) => skusById[item.sku]);
  const unprocessedPrice = skus.reduce((total, sku) => {
    const intent = paymentIntentBySku[sku.guid] || "online";
    const effectivePrice = intent === "online" ? Number(sku.price || 0) : 0;
    return total + effectivePrice;
  }, 0);
  let returnedTotal = (Math.floor(unprocessedPrice * 100) / 100).toString();
  if (returnedTotal[returnedTotal.length - 2] === ".") {
    returnedTotal += "0";
  }
  return returnedTotal;
};

export { register, getCartCost, getEffectiveDivision, LabelByDivision };
